import * as React from 'react';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Stack } from '@mui/material';
import { DataDisplay } from '../elements/DataDisplay';

interface Sellers {
  integrator: string,
  countcontratado: number,
  countrecusado: number,
  id: number,
  integratorId: number,
  name: string,
  projects: number,
}

interface Integrator {
  name: string,
  id: number
  sellers: [Sellers]
}

function createData(
  name: string,
  sellers: [Sellers],
  length: number,
  id?: number,
) {
  return {
    name,
    id,
    sellers,
    length,
  };
}

function Row(props: { row: ReturnType<typeof createData> }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const sellerLabel = (sellers: [Sellers]) => {
    if (sellers.length <= 1) return 'Vendedor';

    return 'Vendedores';
  };

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row">
          <Stack
            direction="row"
            alignItems="center"
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <Typography
              marginLeft={2}
              fontFamily="Poppins"
              fontSize="16px"
              fontWeight="400"
            >
              {row.name}
            </Typography>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow sx={{ bgcolor: 'whitesmoke' }}>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow sx={{ bgcolor: '#FFD8C6', borderRadius: '50px' }}>
                  <TableCell
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: '700',
                    }}
                  >
                    {sellerLabel(row.sellers)}
                  </TableCell>
                  <TableCell align="right" />
                  <TableCell align="right" />
                  <TableCell align="right" />
                </TableRow>
              </TableHead>
              {row.sellers.length > 0 && (
              <TableBody>
                {row.sellers.map((seller) => (
                  <TableRow key={seller.id} sx={{ bgcolor: '#F6F8FF' }}>
                    <TableCell>
                      <DataDisplay title="Nome" data={seller.name} />
                    </TableCell>
                    <TableCell align="left">
                      <DataDisplay title="Projetos" data={seller.projects} />
                    </TableCell>
                    <TableCell align="left">
                      <DataDisplay title="Projetos contratados" data={seller.countcontratado} />
                    </TableCell>
                    <TableCell align="left">
                      <DataDisplay title="Projetos recusados" data={seller.countrecusado} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              )}
              {(!row.sellers || row.sellers.length < 1) && (
                <TableBody>
                  <TableRow sx={{ bgcolor: '#F6F8FF' }}>
                    <TableCell align="center" colSpan={6}>
                      Sem projetos
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

interface Props {
  integrator: Integrator[]
}

export default function CollapsibleTable({ integrator }: Props) {
  const createRows = (integratorList: Integrator[]) => {
    const rows = [];
    for (let i = 0; integratorList.length > i; i++) {
      rows.push(
        createData(
          integratorList[i].name,
          integratorList[i].sellers,
          i,
          integratorList[i].id,
        ),
      );
    }

    return rows;
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell sx={{
              bgcolor: '#FFB08A',
              fontFamily: 'Poppins',
              fontSize: '18px',
              fontWeight: '700',
            }}
            >
              Integradoras
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {createRows(integrator).map((row) => (
            <Row key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
