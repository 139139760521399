import {
  Box, Button,
  FormControlLabel, Radio, RadioGroup,
  Stack,
  TextField,
  ThemeProvider, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { parseCookies } from 'nookies';
import Grid from '@mui/material/Unstable_Grid2';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { api } from '../../services/api';
import {
  Address,
  City,
  Integrator,
  IntegratorEvaluation,
  State,
  User,
} from '../../contexts/@types';
import {
  defaultAddress,
  defaultCity,
  defaultIntegrator, defaultIntegratorEvaluation, defaultState,
  defaultUser,
} from '../../contexts/@defaultValues';
import { SectionTitle } from '../../components/elements/SectionTitle';
import { DataDisplay } from '../../components/elements/DataDisplay';
import { timeStampToDateOnly } from '../../utils/util';
import { DocumentDisplay } from '../../components/elements/DocumentDisplay';
import { primaryButton, secondaryButton } from '../../themes/buttonTheme';
import style from './detalhecadastro.module.scss';
import { DialogComponent } from '../../components/DialogComponent';

export function DetalheCadastro() {
  const history = useHistory();
  const cardStyle = {
    backgroundColor: '#fff',
    boxShadow: '0px 2px 2px 0px #0000001A',
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: '1rem',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };
  const documentsStyle = {
    maxWidth: {
      xs: '50%', md: '35%', lg: '45%', xl: '35%',
    },
  };
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const getIntegratorEvalId = () => {
    const { 'nextauth.p': integratorEvalId } = parseCookies();
    return parseInt(integratorEvalId, 10);
  };
  const { 'nextauth.tag': userId } = parseCookies();
  const [integrator, setIntegrator] = useState<Integrator>(defaultIntegrator);
  const [user, setUser] = useState<User>(defaultUser);
  const [address, setAddress] = useState<Address>(defaultAddress);
  const [city, setCity] = useState<City>(defaultCity);
  const [state, setState] = useState<State>(defaultState);
  const [
    integratorEvaluation, setIntegratorEvaluation,
  ] = useState<IntegratorEvaluation>(defaultIntegratorEvaluation);

  const [isOpen, setIsOpen] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [useEffectRefresh, setUseEffectRefresh] = useState(false);

  useEffect(() => {
    setLoadingText('Carregando...');
    setIsOpen(true);
    const getIntegrator = async () => {
      api.post('integrator/get', {
        id: getIntegratorEvalId(),
      }).then((resolved) => {
        const { data } = resolved;
        setIntegrator({
          ...integrator,
          ...data,
        });
        setUser({
          ...user,
          ...data.user,
        });
        setAddress({
          ...address,
          ...data.address,
        });
        setCity({
          ...city,
          ...data.address.city,
        });
        setState({
          ...state,
          ...data.address.city.state,
        });
        setIsOpen(false);
      });
    };

    const getIntegratorEvaluation = async () => {
      api.post('integrator/eval/get', {
        id: getIntegratorEvalId(),
      }).then((resolved) => {
        const { data } = resolved;
        setIntegratorEvaluation({
          ...integratorEvaluation,
          ...data,
        });
      });
    };

    getIntegrator();
    getIntegratorEvaluation();
    // If Use-effect is changed run without the disabled eslint to check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useEffectRefresh]);

  const handleChangeIntegratorEval = (key:
    keyof IntegratorEvaluation) => (e:
    ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let input : string | boolean = '';
    input = value;
    if (key === 'homologaContrato'
      || key === 'homologaCrea'
      || key === 'homologaBalancete'
      || key === 'homologaCat'
      || key === 'homologaCrp') {
      input = value === 'true';
    }
    return setIntegratorEvaluation({
      ...integratorEvaluation,
      [key]: input,
    });
  };

  const isJustificativa = () => integratorEvaluation.homologaContrato
      && integratorEvaluation.homologaCrea
      && integratorEvaluation.homologaBalancete
      && integratorEvaluation.homologaCat
      && integratorEvaluation.homologaCrp;

  const isAtualizar = () => {
    if (integrator.status === 'Ativo'
    || integrator.status === 'Recusado') {
      return false;
    }

    if (!isJustificativa()) {
      return integratorEvaluation.justificativa.length > 0;
    }

    return true;
  };

  const disableFields = () => integrator.status === 'Ativo' || integrator.status === 'Recusado';

  const handleVoltarClick = () => {
    history.goBack();
  };

  const handleAtualizar = async () => {
    setLoadingText('Atualizando integradora...');
    setIsOpen(true);

    const urlv = window.location.href.split('/');
    const urlc = `${urlv[0]}//${urlv[2]}`;

    console.log(urlv, urlc);
    toast.info('Cadastro enviado para atualização! Por favor, aguarde...');
    api.post('integrator/eval/put', {
      data: {
        id: getIntegratorEvalId(),
        homologaBalancete: integratorEvaluation.homologaBalancete,
        homologaContrato: integratorEvaluation.homologaContrato,
        homologaCrp: integratorEvaluation.homologaCrp,
        homologaCrea: integratorEvaluation.homologaCrea,
        homologaCat: integratorEvaluation.homologaCat,
        justificativa: integratorEvaluation.justificativa,
        userId,
        returnUrl: urlc,
      },
    }).then(async (resp) => {
      const { data } = resp;
      if (data === 'CREATED_OK') {
        toast.success('Status do cadastro atualizado com sucesso.');
        await api.post('user/integrator/put', {
          data: {
            integratorId: integrator?.id,
          },
        });
        setIsOpen(false);
        setUseEffectRefresh(!useEffectRefresh);
      } else {
        setIsOpen(false);
        toast.error(
          `Não foi possível atualizar o status do cadastro.${data}`,
        );
      }
    });
  };

  const homologarTexto = () => (
    <Typography
      sx={{
        fontSize: { xs: '12px', lg: '16px' },
      }}
    >
      { isXs ? 'Sim' : 'Homologar' }
    </Typography>
  );

  const naoHomologarTexto = () => (
    <Typography
      sx={{
        fontSize: { xs: '12px', lg: '16px' },
      }}
    >
      { isXs ? 'Não' : 'Não homologar' }
    </Typography>
  );

  return (
    <>
      <Box sx={cardStyle}>
        <Grid
          container
          spacing={3}
        >
          {/* Primeiro item-grid */}
          <Grid xs={12} lg={5}>
            <Box>
              <SectionTitle title={`Dados da Integradora #${getIntegratorEvalId()}`} status={integrator.status} />
              <Stack
                direction={isXs ? 'column' : 'row'}
                justifyContent="space-between"
              >
                <DataDisplay title="Razão Social" data={integrator.name || '-----'} />
                <DataDisplay title="CNPJ" data={integrator.cnpj || '-----'} />
              </Stack>
              <Stack
                direction={isXs ? 'column' : 'row'}
                justifyContent="space-between"
              >
                <DataDisplay title="Potência instalada" data={integrator.potencyW || '-----'} />
                <DataDisplay
                  title="Quantidade de funcionários"
                  data={integrator.numWorkers || '-----'}
                />
              </Stack>
              <Stack
                direction={isXs ? 'column' : 'row'}
                justifyContent="space-between"
              >
                <DataDisplay
                  title="Data de Criação"
                  data={integrator.dataFundacao
                    ? timeStampToDateOnly(integrator.dataFundacao.toString())
                    : '-----'}
                />
                {!isXs && (<DataDisplay title="" data="" />)}
              </Stack>
            </Box>
            <Box>
              <SectionTitle title="Endereço" />
              <Stack
                direction={isXs ? 'column' : 'row'}
                justifyContent="space-between"
              >
                <DataDisplay title="Logradouro" data={address.street1 || '-----'} />
                <DataDisplay title="Nº" data={address.number || '-----'} />
              </Stack>
              <Stack
                direction={isXs ? 'column' : 'row'}
                justifyContent="space-between"
              >
                <DataDisplay title="Complemento" data={address.street2 || '-----'} />
                <DataDisplay title="CEP" data={address.zip || '-----'} />
              </Stack>
              <Stack
                direction={isXs ? 'column' : 'row'}
                justifyContent="space-between"
              >
                <DataDisplay title="Estado" data={state.name || '-----'} />
                <DataDisplay title="Cidade" data={city.name || '-----'} />
              </Stack>
            </Box>
            <Box>
              <SectionTitle title="Contato" />
              <Stack
                direction={isXs ? 'column' : 'row'}
                justifyContent="space-between"
              >
                <DataDisplay title="Telefone" data={integrator.phoneNumber || '-----'} />
                <DataDisplay title="E-mail" data={user.email || '-----'} />
              </Stack>
            </Box>
          </Grid>
          {/* Segundo item-grid */}
          <Grid
            xs={12}
            lg={7}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <SectionTitle title="Documentação" />
              <Stack>
                <RadioGroup
                  value={integratorEvaluation.homologaContrato}
                  onChange={handleChangeIntegratorEval('homologaContrato')}
                >
                  <Stack
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <DocumentDisplay title="Contrato social" file={integrator.docContrato} sx={documentsStyle} />
                    <Stack
                      flexDirection="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <FormControlLabel
                        sx={{ fontSize: '5px' }}
                        value
                        control={<Radio />}
                        label={homologarTexto()}
                        disabled={disableFields()}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={naoHomologarTexto()}
                        disabled={disableFields()}
                      />
                    </Stack>
                  </Stack>
                </RadioGroup>
                <RadioGroup
                  value={integratorEvaluation.homologaCrea}
                  onChange={handleChangeIntegratorEval('homologaCrea')}
                >
                  <Stack
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <DocumentDisplay title="Registro no CREA" file={integrator.docCrea} sx={documentsStyle} />
                    <Stack
                      flexDirection="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <FormControlLabel
                        value
                        control={<Radio />}
                        label={homologarTexto()}
                        disabled={disableFields()}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={naoHomologarTexto()}
                        disabled={disableFields()}
                      />
                    </Stack>
                  </Stack>
                </RadioGroup>
                <RadioGroup
                  value={integratorEvaluation.homologaBalancete}
                  onChange={handleChangeIntegratorEval('homologaBalancete')}
                >
                  <Stack
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <DocumentDisplay title="Balanço/Balancete" file={integrator.docBalanco} sx={documentsStyle} />
                    <Stack
                      flexDirection="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <FormControlLabel
                        value
                        control={<Radio />}
                        label={homologarTexto()}
                        disabled={disableFields()}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={naoHomologarTexto()}
                        disabled={disableFields()}
                      />
                    </Stack>
                  </Stack>
                </RadioGroup>
                <RadioGroup
                  value={integratorEvaluation.homologaCat}
                  onChange={handleChangeIntegratorEval('homologaCat')}
                >
                  <Stack
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <DocumentDisplay title="CAT da Empresa" file={integrator.docCat} sx={documentsStyle} />
                    <Stack
                      flexDirection="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <FormControlLabel
                        value
                        control={<Radio />}
                        label={homologarTexto()}
                        disabled={disableFields()}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={naoHomologarTexto()}
                        disabled={disableFields()}
                      />
                    </Stack>
                  </Stack>
                </RadioGroup>
                <RadioGroup
                  value={integratorEvaluation.homologaCrp}
                  onChange={handleChangeIntegratorEval('homologaCrp')}
                >
                  <Stack
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <DocumentDisplay title="Certidão de Reg. Profissional" file={integrator.docCrp} sx={documentsStyle} />
                    <Stack
                      flexDirection="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <FormControlLabel
                        value
                        control={<Radio />}
                        label={homologarTexto()}
                        disabled={disableFields()}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={naoHomologarTexto()}
                        disabled={disableFields()}
                      />
                    </Stack>
                  </Stack>
                </RadioGroup>
              </Stack>
            </Box>
            {!isJustificativa() && (
            <Box>
              <SectionTitle title="Justificativa" />
              <Typography
                sx={{
                  mb: 1,
                }}
              >
                Utilize a caixa de texto abaixo para justiificar
                a alteração do status. Em seguida, pressione “Atualizar”.
              </Typography>
              <Stack>
                <TextField
                  label="Justificativa"
                  placeholder="Adicione a sua justificativa"
                  required
                  disabled={disableFields()}
                  InputLabelProps={{ shrink: true }}
                  multiline
                  rows={10}
                  value={integratorEvaluation.justificativa}
                  onChange={handleChangeIntegratorEval('justificativa')}
                />
              </Stack>
            </Box>
            )}
          </Grid>
        </Grid>
        <div>
          <hr className={style.divisor} />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="end"
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="end"
              gap="1rem"
              sx={{
                width: {
                  xs: '50vw',
                  md: '30vw',
                },
              }}
            >
              <ThemeProvider theme={secondaryButton}>
                <Button
                  onClick={handleVoltarClick}
                >
                  Voltar
                </Button>
              </ThemeProvider>
              <ThemeProvider theme={primaryButton}>
                <Button
                  disabled={!isAtualizar()}
                  onClick={handleAtualizar}
                >
                  Atualizar
                </Button>
              </ThemeProvider>
            </Box>
          </Box>
        </div>
      </Box>
      <DialogComponent isOpen={isOpen} labelText={loadingText} />
    </>
  );
}
