import React, {
  ChangeEvent, SyntheticEvent, useEffect, useRef, useState,
} from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { parseCookies, setCookie } from 'nookies';
import { response } from 'express';
import { Simulate } from 'react-dom/test-utils';
import pdfIcon from '../../assets/images/downloadPDF.png';
import style from './detalhechamado.module.scss';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { Navigator } from '../../components/Navigator';
import { FormPage } from '../../components/FormPage';
import { api } from '../../services/api';
import { Baloon } from '../../components/Baloon';
import { LoadingIcon } from '../../components/LoadingIcon';
import { nonMonetaryDecimalValue, onlyLetters } from '../../utils/util';
import { DialogComponent } from '../../components/DialogComponent';
import { StepperProject } from '../../components/StepperProject';
import { RequiredFieldMark } from '../../components/RequiredFieldMark';
import uploadIcon from '../../assets/images/upload.png';
import { Anchor } from '../../components/Anchor';
import { getFile } from '../../lib/download';
import { ChamadoEditor } from '../../components/ChamadoEditor';
import { ChamadoAvaliarEditor } from '../../components/ChamadoAvaliarEditor';

interface GetState {
  id: number;
}
interface Estado {
  id: number;
  name: string;
  acronym: string;
}
interface Cidade {
  id: number;
  name: string;
}
interface ViaCepErro {
  erro: boolean;
}
interface FieldToEdit{
  nome_cliente: boolean,
  cpf_cliente: boolean,
  nome_projeto: boolean,
  potencia_projeto: boolean,
  valor_projeto: boolean,
  entrada_projeto: boolean,
  carencia_projeto: boolean,
  rg_cliente: boolean,
  data_nascimento: boolean,
  nome_mae: boolean,
  telefone_cliente: boolean,
  renda_cliente: boolean,
  email_cliente: boolean,
  media_conta: boolean,
  cep_cliente: boolean,
  logradouro_cliente: boolean,
  numero_logradouro: boolean,
  complemento_logradouro: boolean,
  estado_logradouro: boolean,
  cidade_cliente: boolean,
  notaFiscalEquipamentos: boolean,
  contaLuzRecente: boolean,
  documentoIdentificacao: boolean,
  iptu: boolean,
  comprovanteRenda: boolean,

}
interface UserData {
  nome: string,
  cpf: string,
  rg: string,
  dataNascimento: Date,
  nomeMae: string,
  telefone: string,
  faturamentoMensal: number,
  email: string,
  mediaContaLuz: number,
  potenciaInstalada: number,
  contaLuzRecente: string,
  documentoIdentificacao: string,
  iptu: string,
  comprovanteRenda: string,
  notaFiscalEquipamentos: string,
  enderecoInstalacao: {
    city : {
      name : string,
      state : {
        name: string
      }
    },
    number: number,
    street1: string,
    street2: string,
    zip: string
  },
  status: string
}
interface ProjectData{
  id: number
  nome: string;
  chosen_analysisId: number
  cliente: UserData,
}
interface ModificationData {
  id: number;
  name: string;
  newValue: string;
  issueId: number;
  formFieldId: number;
  codAttribute: string;
}
interface IssueData {
  id: number;
  assunto: string;
  descricao: string;
  status: string;
  projeto: ProjectData,
  resolvido: boolean,
  ownerIssues: UserData[],
  modification: ModificationData[],
  contaLuzRecente: '',
  documentoIdentificacao: '',
  notaFiscalEquipamentos: '',
  iptu: '',
  comprovanteRenda: '',
}
interface EstadoCivil {
  name: string;
  key: number;
}
async function loadPdfToCanvas(canvasRef: any, file: String, pageNumber : number) {
  console.log(file);

  const pdf = await pdfjs.getDocument(file).promise;

  // Prepare canvas using PDF page dimensions.
  const canvas = canvasRef.current;
  const canvasContext = canvas.getContext('2d');

  const page = await pdf.getPage(pageNumber);
  const viewport = await page.getViewport({ scale: 2.0 });

  canvas.height = viewport.height;
  canvas.width = viewport.width;

  // Render PDF page into canvas context.
  const renderContext = { canvasContext, viewport };
  page.render(renderContext);

  return pdf.numPages;
}

export function AvaliarChamado() {
  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  const dateMask = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;
  const dateMaskAlt = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

  const canvasRef = useRef(null);

  enum TipoDocumento {
    Contrato,
    Crea,
    Balancete,
    Crp,
    Cat,
  }
  const estadosCivil: EstadoCivil[] = [
    {
      name: 'Não selecionado',
      key: 0,
    },
    {
      name: 'Casado',
      key: 1,
    },
    {
      name: 'Solteiro',
      key: 2,
    },
    {
      name: 'Viúvo',
      key: 3,
    },
    {
      name: 'União Estável',
      key: 4,
    },
    {
      name: 'Separado',
      key: 5,
    },
  ];
  const [loadedFiles, setLoadedFiles] = useState({
    equipamento: null,
    contaLuz: null,
    identidade: null,
    iptu: null,
    comprovanteRenda: null,
  });
  const [documentUrl, setDocumentUrl] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isCepLoading, setIsCepLoading] = useState<boolean>(false);
  const [numPages, setNumPages] = useState<number>(0);
  const [issue, setIssue] = useState<IssueData>();
  const [status, setStatus] = useState<string>('');
  const [radioStatus, setRadioStatus] = useState('Aberto');
  const [resolvido, setResolvido] = useState<boolean>(false);

  const [estados, setEstados] = useState<Estado[]>();
  const [cidades, setCidades] = useState<Cidade[]>();
  const history = useHistory();
  const sstate: GetState = history.location.state as GetState;
  const getProjectId = () => {
    const { 'nextauth.p': cprojId } = parseCookies();
    return parseInt(cprojId, 10);
  };
  const [isOpen, setIsOpen] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  // endereço
  const [rua, setRua] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [cep, setCep] = useState('');
  const [cidade, setCidade] = useState(1);
  const [estado, setEstado] = useState(1);
  const [nomeMae, setNomeMae] = useState('');
  const [telefone, setTelefone] = useState('');
  const [renda, setRenda] = useState(0);
  // outras informações
  const [email, setEmail] = useState('');
  const [mediaLuz, setMediaLuz] = useState(0);
  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');
  const [rg, setRg] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [profissao, setProfissao] = useState('');
  const [estadoCivil, setEstadoCivil] = useState('');
  const [tempoProfissao, setTempoProfissao] = useState('');
  const [valorPatrimonio, setValorPatrimonio] = useState(0);
  const [potenciaInstalada, setPotenciaInstalada] = useState('');
  const [nomeProjeto, setNomeProjeto] = useState('');
  const [valorTotal, setValorTotal] = useState(0);
  const [valorEntrada, setValorEntrada] = useState(0);
  const [valorCarencia, setValorCarencia] = useState(1);
  // documentos
  const [equipamento, setEquipamento] = useState<any>();
  const [contaLuz, setContaLuz] = useState<any>();
  const [identidade, setIdentidade] = useState<any>();
  const [iptu, setIptu] = useState<any>();
  const [comprovanteRenda, setComprovanteRenda] = useState<any>();
  // endereço
  const [ruaEnable, setRuaEnable] = useState(true);
  const [numeroEnable, setNumeroEnable] = useState(true);
  const [complementoEnable, setComplementoEnable] = useState(true);
  const [cepEnable, setCepEnable] = useState(true);
  const [cidadeEnable, setCidadeEnable] = useState(true);
  const [estadoEnable, setEstadoEnable] = useState(true);
  const [nomeMaeEnable, setNomeMaeEnable] = useState(true);
  const [telefoneEnable, setTelefoneEnable] = useState(true);
  const [rendaEnable, setRendaEnable] = useState(true);
  // outras informações
  const [emailEnable, setEmailEnable] = useState(true);
  const [estadoCivilEnable, setEstadoCivilEnable] = useState(true);
  const [profissaoEnable, setProfissaoEnable] = useState(true);
  const [tempoProfissaoEnable, setTempoProfissaoEnable] = useState(true);
  const [valorPatrimonioEnable, setValorPatrimonioEnable] = useState(true);
  const [mediaLuzEnable, setMediaLuzEnable] = useState(true);
  const [nomeEnable, setNomeEnable] = useState(true);
  const [cpfEnable, setCpfEnable] = useState(true);
  const [rgEnable, setRgEnable] = useState(true);
  const [dataNascimentoEnable, setDataNascimentoEnable] = useState(true);
  const [nomeProjetoEnable, setNomeProjetoEnable] = useState(true);
  const [valorTotalEnable, setValorTotalEnable] = useState(true);
  const [valorEntradaEnable, setValorEntradaEnable] = useState(true);
  const [valorCarenciaEnable, setValorCarenciaEnable] = useState(true);
  const [potenciaInstaladaEnable, setPotenciaInstaladaEnable] = useState(true);

  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  // documentos
  // const [equipamentoEnable, setEquipamentoEnable] = useState<any>();
  // const [contaLuzEnable, setContaLuzEnable] = useState<any>();
  // const [identidadeEnable, setIdentidadeEnable] = useState<any>();
  // const [iptuEnable, setIptuEnable] = useState<any>();
  // // const [comprovanteRendaEnable, setComprovanteRendaEnable] = useState<any>();
  const setAttribute = (id: string, attrib: string, value: any) => {
    document.getElementById(id)?.setAttribute(attrib, value);
    // document.getElementById(id)?.setAttribute('disabled', 'disabled');
  };
  const ref = useRef<LoadingBarRef>(null);
  const { 'nextauth.tag': tag } = parseCookies();

  // const state: GetState = history.location.state as GetState;
  const applyCurrencyMask = (n: string) => {
    const value : string = n.toString().split('').reverse().join('');

    let newValue = '';
    for (let x = 0; x < value.length; x++) {
      newValue += value[x];
      if (x === 1) {
        if (x < value.length - 1) { newValue += ','; }
      }

      if (x === 4 || x === 7) {
        if (x < value.length - 1) { newValue += '.'; }
      }
    }

    newValue = newValue.split('').reverse().join('');
    newValue = `R$ ${newValue}`;
    return newValue;
  };
  const getCidades = async (state: number) => {
    const respons = await api.post('/address/city/get', { stateId: state });
    setCidades(respons.data);
  };
  useEffect(() => {
    const getStates = async () => {
      setLoadingText('Buscando dados do chamado...');
      setIsOpen(true);
      const respo = await api.post('/address/state/get', {}, {});
      setEstados(respo.data);
    };
    const getIssue = async () => {
      const issueId = { issueId: sstate.id };
      const respo = await api.post('/issue/single/get', issueId, {});
      const { data } = respo;
      setIssue(respo.data);
      const dataString = respo.data.projeto.cliente.dataNascimento
        .replace('Z', '')
        .split('T')[0]
        .replace(/-/, '/')
        .replace(/-/, '/')
        .split('/')
        .reverse()
        .join('/');
      // setAttribute('submit_btn', 'disabled', 'disabled');
      // @ts-ignore

      if (respo.data) {
        setAttribute('f_nome', 'value', respo.data.projeto.cliente.nome);
        setNome(respo.data.projeto.cliente.nome);
        setAttribute('f_email', 'value', respo.data.projeto.cliente.email);
        setEmail(respo.data.projeto.cliente.email);
        setAttribute('f_cpf', 'value', respo.data.projeto.cliente.cpf);
        setCpf(respo.data.projeto.cliente.cpf);
        setAttribute('f_rg', 'value', respo.data.projeto.cliente.rg);
        setRg(respo.data.projeto.cliente.rg);
        setAttribute('f_nomemae', 'value', respo.data.projeto.cliente.nomeMae);
        setNomeMae(respo.data.projeto.cliente.nomeMae);
        setAttribute('f_telefone', 'value', respo.data.projeto.cliente.telefone);
        setTelefone(respo.data.projeto.cliente.telefone);
        setAttribute('f_profissao', 'value', data.projeto.cliente.profissao);
        setAttribute('f_estadoCivil', 'value', data.projeto.cliente.estadoCivil);
        setEstadoCivil(data.estadoCivil);
        setAttribute('f_tempoProfissao', 'value', data.projeto.cliente.tempoProfissao);
        let patrimonio = '';
        patrimonio = applyCurrencyMask(data.projeto.cliente.valorPatrimonio);
        setAttribute('f_patrimonio', 'value', patrimonio);
        setAttribute('f_nasc', 'value', dataString);
        setDataNascimento(dataString);
        let valueRenda = '';
        valueRenda = applyCurrencyMask(respo.data.projeto.cliente.faturamentoMensal);
        setAttribute('f_renda', 'value', valueRenda);
        setRenda(respo.data.projeto.cliente.faturamentoMensal);
        let valueFLuz = '';
        valueFLuz = applyCurrencyMask(respo.data.projeto.cliente.mediaContaLuz);
        setAttribute('f_luz', 'value', respo.data.projeto.cliente.mediaContaLuz);
        setMediaLuz(respo.data.projeto.cliente.mediaContaLuz);
        setAttribute('f_potencia', 'value', respo.data.projeto.projetoPotencia);
        setPotenciaInstalada(respo.data.projeto.projetoPotencia);
        setAttribute('f_cep', 'value', respo.data.projeto.cliente.enderecoInstalacao.zip);
        setCep(respo.data.projeto.cliente.enderecoInstalacao.zip);
        setAttribute('ruaField', 'value', respo.data.projeto.cliente.enderecoInstalacao.street1);
        setRua(respo.data.projeto.cliente.enderecoInstalacao.street1);
        setAttribute('f_numero', 'value', respo.data.projeto.cliente.enderecoInstalacao.number);
        setNumero(respo.data.projeto.cliente.enderecoInstalacao.number);
        setAttribute('f_complemento', 'value', respo.data.projeto.cliente.enderecoInstalacao.street2);
        setComplemento(respo.data.projeto.cliente.enderecoInstalacao.street2);

        setEstado(respo.data.projeto.cliente.enderecoInstalacao.city.state.id);
        setAttribute('estadoField', 'value', respo.data.projeto.cliente.enderecoInstalacao.city.state.name);

        getCidades(respo.data.projeto.cliente.enderecoInstalacao.city.state.id); //!
        setCidade(respo.data.projeto.cliente.enderecoInstalacao.city.id);
        setAttribute('cidadeField', 'value', respo.data.projeto.cliente.enderecoInstalacao.city.name);

        setAttribute('upload_equipamento', 'disabled', 'disabled');
        setAttribute('upload_contaluz', 'disabled', 'disabled');
        setAttribute('upload_identidade', 'disabled', 'disabled');
        setAttribute('upload_comprovanteRenda', 'disabled', 'disabled');
        setAttribute('upload_iptu', 'disabled', 'disabled');

        setAttribute('nome', 'value', respo.data.projeto.nome);
        setNomeProjeto(respo.data.projeto.nome);

        setAttribute('carencia', 'value', respo.data.projeto.carencia);
        setValorCarencia(parseInt(respo.data.projeto.carencia, 10));
        setAttribute('potencia', 'value', respo.data.projeto.potencia);
        setPotenciaInstalada(respo.data.projeto.potencia);

        setValorEntrada(parseInt(respo.data.projeto.valorEntrada, 10));
        let valueEntrada = '';
        valueEntrada = applyCurrencyMask(respo.data.projeto.valorEntrada);
        setAttribute('valorEntrada', 'value', valueEntrada);

        setValorTotal(parseInt(respo.data.projeto.alorTotal, 10));
        let valueTotal = '';
        valueTotal = applyCurrencyMask(respo.data.projeto.valorTotal);
        setAttribute('valorTotal', 'value', valueTotal);
        setValorTotal(respo.data.projeto.valorTotal);
        setStatus(respo.data.status);
        setResolvido(respo.data.resolvido);
        setLoadedFiles({
          contaLuz: data.contaLuzRecente,
          identidade: data.documentoIdentificacao,
          equipamento: data.notaFiscalEquipamentos,
          iptu: data.iptu,
          comprovanteRenda: data.comprovanteRenda,
        });
        respo.data.modification.forEach((modificationItem: ModificationData) => {
          if (modificationItem.codAttribute === 'nome_cliente') {
            setNomeEnable(false);
          } else if (modificationItem.codAttribute === 'cpf_cliente') {
            setCpfEnable(false);
          } else if (modificationItem.codAttribute === 'nome_projeto') {
            setNomeProjetoEnable(false);
          } else if (modificationItem.codAttribute === 'potencia_projeto') {
            setPotenciaInstaladaEnable(false);
          } else if (modificationItem.codAttribute === 'valor_projeto') {
            setValorTotalEnable(false);
          } else if (modificationItem.codAttribute === 'entrada_projeto') {
            setValorEntradaEnable(false);
          } else if (modificationItem.codAttribute === 'carencia_projeto') {
            setValorCarenciaEnable(false);
          } else if (modificationItem.codAttribute === 'rg_cliente') {
            setRgEnable(false);
          } else if (modificationItem.codAttribute === 'data_nascimento') {
            setDataNascimentoEnable(false);
          } else if (modificationItem.codAttribute === 'nome_mae') {
            setNomeMaeEnable(false);
          } else if (modificationItem.codAttribute === 'telefone_cliente') {
            setTelefoneEnable(false);
          } else if (modificationItem.codAttribute === 'renda_cliente') {
            setRendaEnable(false);
          } else if (modificationItem.codAttribute === 'email_cliente') {
            setEmailEnable(false);
          } else if (modificationItem.codAttribute === 'media_conta') {
            setMediaLuzEnable(false);
          } else if (modificationItem.codAttribute === 'numero_residencia') {
            setNumeroEnable(false);
          } else if (modificationItem.codAttribute === 'cep_cliente') {
            setCepEnable(false);
            // eslint-disable-next-line no-empty
          } else {

          }
        });
      }
      setIsOpen(false);
    };
    const verificaEdicao = () => {
      issue?.modification.forEach((modificationItem) => {
        if (modificationItem.codAttribute === 'nome_cliente') {
          setNomeEnable(false);
        } else if (modificationItem.codAttribute === 'cpf_cliente') {
          setCpfEnable(false);
        } else if (modificationItem.codAttribute === 'nome_projeto') {
          setNomeProjetoEnable(false);
        } else if (modificationItem.codAttribute === 'potencia_projeto') {
          setPotenciaInstaladaEnable(false);
        } else if (modificationItem.codAttribute === 'valor_projeto') {
          setValorTotalEnable(false);
        } else if (modificationItem.codAttribute === 'entrada_projeto') {
          setValorEntradaEnable(false);
        } else if (modificationItem.codAttribute === 'carencia_projeto') {
          setValorCarenciaEnable(false);
        } else if (modificationItem.codAttribute === 'rg_cliente') {
          setRgEnable(false);
        } else if (modificationItem.codAttribute === 'data_nascimento') {
          setDataNascimentoEnable(false);
        } else if (modificationItem.codAttribute === 'nome_mae') {
          setNomeMaeEnable(false);
        } else if (modificationItem.codAttribute === 'telefone_cliente') {
          setTelefoneEnable(false);
        } else if (modificationItem.codAttribute === 'renda_cliente') {
          setRendaEnable(false);
        } else if (modificationItem.codAttribute === 'email_cliente') {
          setEmailEnable(false);
        } else if (modificationItem.codAttribute === 'media_conta') {
          setMediaLuzEnable(false);
        } else if (modificationItem.codAttribute === 'estado_civil') {
          setEstadoCivilEnable(false);
        } else if (modificationItem.codAttribute === 'profissao') {
          setProfissaoEnable(false);
        } else if (modificationItem.codAttribute === 'tempo_profissao') {
          setTempoProfissaoEnable(false);
        } else if (modificationItem.codAttribute === 'valor_patrimonio') {
          setValorPatrimonioEnable(false);
          // eslint-disable-next-line no-empty
        } else {

        }
        // else if (modificationItem.codAttribute === 'email_cliente') {
        //   setEmailEnable(false);
        // }
        // } else if (modificationItem.codAttribute === 'logradouro_cliente') {
        //   setCpfEnable(false);
        // } else if (modificationItem.codAttribute === 'numero_logradouro') {
        //   setCpfEnable(false);
        // } else if (modificationItem.codAttribute === 'complemento_logradouro') {
        //   setCpfEnable(false);
        // } else if (modificationItem.codAttribute === 'estado_logradouro') {
        //   setCpfEnable(false);
        // } else if (modificationItem.codAttribute === 'cidade_cliente') {
        //   setCpfEnable(false);

        // }
      });
    };
    // const getIntegrator = async () => {
    //   const resp: Promise<AxiosResponse<any, any>> = api.post('integrator/get', {
    //     id: getProjectId(),
    //   });
    //
    //   resp.then((resolved) => {
    //     const item: IntegratorDetalheData = resolved.data;
    //     setIntegrator(item);
    //     return item;
    //   });
    // };

    // const getIntegratorEvaluation = async () => {
    //   const resp: Promise<AxiosResponse<any, any>> = api.post('integrator/eval/get', {
    //     id: getProjectId(),
    //   });
    //
    //   resp.then((resolved) => {
    //     const item: IntegratorEval = resolved.data;
    //
    //     const {
    //       homologaContrato,
    //       homologaCrea,
    //       homologaBalancete,
    //       homologaCrp,
    //       homologaCat,
    //       justificativa,
    //     } = item;
    //
    //     setHomoContrato(homologaContrato);
    //     setHomoCrea(homologaCrea);
    //     setHomoBalancete(homologaBalancete);
    //     setHomoCrp(homologaCrp);
    //     setHomoCat(homologaCat);
    //     setHomoJustificativa(justificativa);
    //     setAttribute('f_justify', 'value', justificativa);
    //   });
    // };
    //
    // getIntegrator();
    // getIntegratorEvaluation();
    getStates();
    getIssue();
    // If Use-effect is changed run without the disabled eslint to check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleDownloadIptu = async () => {
    if (loadedFiles.iptu) {
      const file = await getFile(loadedFiles.iptu);
      window.open(file);
    }
  };

  const handleDownloadComprovanteRenda = async () => {
    if (loadedFiles.comprovanteRenda) {
      const file = await getFile(loadedFiles.comprovanteRenda);
      window.open(file);
    }
  };

  const handleDownloadEquipamento = async () => {
    if (loadedFiles.equipamento) {
      const file = await getFile(loadedFiles.equipamento);
      window.open(file);
    }
  };
  const sendFile = async (label: string, file: any) => {
    const body = new FormData();
    body.append('filefield', file, label);

    const resp = await api.post('upload', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return resp.data;
  };
  const handleSubEquipamento = async () => {
    // if (validateForm()) {
    // eslint-disable-next-line no-alert
    if (window.confirm('Tem certeza que deseja realizar a troca deste do documento da nota fiscal do equipamento?')) {
      setLoadingText('Substituindo documento...');
      setIsOpen(true);
      const fequipamento = issue?.notaFiscalEquipamentos;
      const projectId = issue?.projeto.id;
      const docType = 'Equipamento';
      const request = {
        headers: { 'Content-Type': 'multipart/form-data' },
        data: {
          tag,
          projectId,
          doc: fequipamento,
          docType,
        },
      };

      const res = await api.post('project/costumer/putDoc', request);

      switch (res.data) {
        case 'DOC_UPDATED':
          toast.success('Nota fiscal alterada!');
          setIsOpen(false);
          break;
        default:
          setIsOpen(false);
          toast.error(`Falha ao trocar documento. ${res.data}`);
      }
    }
  };
  const handleSubContaLuz = async () => {
    // if (validateForm()) {
    // eslint-disable-next-line no-alert
    if (window.confirm('Tem certeza que deseja realizar a troca da última conta de luz?')) {
      setLoadingText('Substituindo documento...');
      setIsOpen(true);
      const fcontaluz = issue?.contaLuzRecente;
      const projectId = issue?.projeto.id;
      const docType = 'Conta de Luz';
      const request = {
        headers: { 'Content-Type': 'multipart/form-data' },
        data: {
          tag,
          projectId,
          doc: fcontaluz,
          docType,
        },
      };

      const res = await api.post('project/costumer/putDoc', request);

      switch (res.data) {
        case 'DOC_UPDATED':
          toast.success('Conta de luz alterada!');
          setIsOpen(false);
          break;
        default:
          setIsOpen(false);
          toast.error(`Falha ao trocar documento. ${res.data}`);
      }
    }
  };
  const handleSubIdentidade = async () => {
    // if (validateForm()) {
    // eslint-disable-next-line no-alert
    if (window.confirm('Tem certeza que deseja realizar a troca do documento de identidade?')) {
      setLoadingText('Substituindo documento...');
      setIsOpen(true);
      const fidentidade = issue?.documentoIdentificacao;
      const projectId = issue?.projeto.id;
      const docType = 'Identidade';
      const request = {
        headers: { 'Content-Type': 'multipart/form-data' },
        data: {
          tag,
          projectId,
          doc: fidentidade,
          docType,
        },
      };

      const res = await api.post('project/costumer/putDoc', request);

      switch (res.data) {
        case 'DOC_UPDATED':
          toast.success('Identidade alterada!');
          setIsOpen(false);
          break;
        default:
          setIsOpen(false);
          toast.error(`Falha ao trocar documento. ${res.data}`);
      }
    }
  };
  const handleSubComprovanteRenda = async () => {
    // if (validateForm()) {
    // eslint-disable-next-line no-alert
    if (window.confirm('Tem certeza que deseja realizar a troca do comprovante de renda?')) {
      setLoadingText('Substituindo documento...');
      setIsOpen(true);
      const fcomprovanteRenda = issue?.comprovanteRenda;
      const projectId = issue?.projeto.id;
      const docType = 'Comprovante de Renda';
      const request = {
        headers: { 'Content-Type': 'multipart/form-data' },
        data: {
          tag,
          projectId,
          doc: fcomprovanteRenda,
          docType,
        },
      };

      const res = await api.post('project/costumer/putDoc', request);

      switch (res.data) {
        case 'DOC_UPDATED':
          toast.success('Identidade alterada!');
          setIsOpen(false);
          break;
        default:
          setIsOpen(false);
          toast.error(`Falha ao trocar documento. ${res.data}`);
      }
    }
  };
  const handleSubIPTU = async () => {
    // if (validateForm()) {
    // eslint-disable-next-line no-alert
    if (window.confirm('Tem certeza que deseja realizar a troca do documento de IPTU?')) {
      setLoadingText('Substituindo documento...');
      setIsOpen(true);
      const fiptu = issue?.comprovanteRenda;
      const projectId = issue?.projeto.id;
      const docType = 'IPTU';
      const request = {
        headers: { 'Content-Type': 'multipart/form-data' },
        data: {
          tag,
          projectId,
          doc: fiptu,
          docType,
        },
      };

      const res = await api.post('project/costumer/putDoc', request);

      switch (res.data) {
        case 'DOC_UPDATED':
          // toast.success('Dados do
          // cliente cadastrados com sucesso. Aguardar a liberação
          // do painel de opções de crédito.');
          toast.success('Identidade alterada!');
          setIsOpen(false);
          // ref?.current?.complete();
          // window.location.assign('/AnaliseCredito');
          break;
        default:
          setIsOpen(false);
          toast.error(`Falha ao trocar documento. ${res.data}`);
        // ref?.current?.complete();
      }
    }
  };

  const handleDownloadIdentidade = async () => {
    if (loadedFiles.identidade) {
      const file = await getFile(loadedFiles.identidade);
      window.open(file);
    }
  };

  const handleDownloadContaLuz = async () => {
    if (loadedFiles.contaLuz) {
      const file = await getFile(loadedFiles.contaLuz);
      window.open(file);
    }
  };

  const handleVoltarClick = () => {
    history.goBack();
  };
  // const handleJustificativa = (e: ChangeEvent<HTMLInputElement>) => {
  //   setHomoJustificativa(e.target.value);
  // };
  // const handleAtualizarClick = async () => {
  //   setLoadingText('Atualizando integrator...');
  //   setIsOpen(true);
  //
  //   const urlv = window.location.href.split('/');
  //   const urlc = `${urlv[0]}//${urlv[2]}`;
  //
  //   toast.info('Cadastro enviado para atualização! Por favor, aguarde...');
  //   const respo = await api.post('integrator/eval/put', {
  //     data: {
  //       id: getProjectId(),
  //       homologaBalancete: homoBalancete,
  //       homologaContrato: homoContrato,
  //       homologaCrp: homoCrp,
  //       homologaCrea: homoCrea,
  //       homologaCat: homoCat,
  //       justificativa: homojustificativa,
  //       user: accessToken,
  //       returnUrl: urlc,
  //     },
  //   });
  //
  //   if (respo.data === 'CREATED_OK') {
  //     toast.success('Status do cadastro atualizado com sucesso.');
  //     setIsOpen(false);
  //     await api.post('user/integrator/put', {
  //       data: {
  //         integratorId: integrator?.id,
  //       },
  //     });
  //     ref?.current?.complete();
  //     history.push({
  //       pathname: '/AceiteCadastros',
  //       // search: `?id=${data.id}`,
  //     });
  //   } else {
  //     setIsOpen(false);
  //     toast.error(
  //       `Não foi possível atualizar o status do cadastro.${respo.data}`,
  //
  //     );
  //     ref?.current?.complete();
  //   }
  //   // ref?.current?.complete();
  // };

  const handleStateChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const estadoId: number = parseInt(e.target.value, 10);
    setEstado(estadoId);
    getCidades(estadoId);
  };
  const handleNextPageClick = async () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
      loadPdfToCanvas(canvasRef, documentUrl, pageNumber + 1);
    }
  };

  const handlePreviousPageClick = async () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
      loadPdfToCanvas(canvasRef, documentUrl, pageNumber - 1);
    }
  };
  const handleNomeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNome(e.target.value);
  };
  const removeNonNumeric = (n: string) => {
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    let value = '';
    for (let i = 0; i < n.length; ++i) {
      if (mask.includes(n[i])) {
        value += n[i];
      }
    }
    return (value.length === 0) ? '0' : parseInt(value, 10).toString();
  };
  const handleValorEntradaChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = removeNonNumeric(e.target.value);
    setValorEntrada(parseInt(value, 10));
    e.target.value = applyCurrencyMask(value);
  };

  const handleValorTotalChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = removeNonNumeric(e.target.value);
    setValorTotal(parseInt(value, 10));
    e.target.value = applyCurrencyMask(value);
  };

  const handlePotenciaInstaladaChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = nonMonetaryDecimalValue(e);
    setPotenciaInstalada(e.target.value);
  };
  const handleCarencia = (e: ChangeEvent<HTMLSelectElement>) => {
    setValorCarencia(parseInt(e.target.value, 10));
  };
  const handleTempoProfissaoChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    for (let i = 0; i < e.target.value.length; ++i) {
      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }
    if (Number(value) >= 50) {
      value = '50';
    }
    e.target.value = value;
    setTempoProfissao(e.target.value);
  };
  const handleValorPatrimonioChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = removeNonNumeric(e.target.value);
    setValorPatrimonio(parseInt(value, 10));
    e.target.value = applyCurrencyMask(value);
  };
  const handleEstadoCivilChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const estadoName: string = e.target.value;
    setEstadoCivil(estadoName);
  };
  const handleProfissaoChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = onlyLetters(e);
    setProfissao(e.target.value);
  };
  // const handleHomoJustificativa = (e: ChangeEvent<HTMLInputElement>) => {
  //   setHomoJustificativa(e.target.value);
  // };

  // const handlePdfButtonClick = async (t: TipoDocumento) => {
  //   ref?.current?.continuousStart(0, 500);
  //
  //   if (integrator) {
  //     let docUrl: String;
  //
  //     switch (t) {
  //       case TipoDocumento.Contrato:
  //         docUrl = integrator.docContrato;
  //         break;
  //       case TipoDocumento.Cat:
  //         docUrl = integrator.docCat;
  //         break;
  //       case TipoDocumento.Balancete:
  //         docUrl = integrator.docBalanco;
  //         break;
  //       case TipoDocumento.Crea:
  //         docUrl = integrator.docCrea;
  //         break;
  //       case TipoDocumento.Crp:
  //         docUrl = integrator.docCrp;
  //         break;
  //       default:
  //         docUrl = '';
  //         break;
  //     }
  //
  //     const promise = getFile(docUrl);
  //
  //     promise.then((resolved) => {
  //       setPageNumber(1);
  //       setDocumentUrl(resolved as string);
  //       const nPages = loadPdfToCanvas(canvasRef, resolved, pageNumber);
  //
  //       nPages.then((num) => {
  //         setNumPages(num);
  //       });
  //
  //       const element = document.getElementById('canvasPdf');
  //       element?.scrollIntoView({
  //         behavior: 'smooth',
  //         block: 'center',
  //         inline: 'center',
  //       });
  //       ref?.current?.complete();
  //     });
  //   }
  // };
  const handleDataNascimentoChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';
    const date = new Date();

    // remove unwanted characters (including recently pressed ones)
    for (let i = 0; i < e.target.value.length; ++i) {
      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    // apply the "__/__/____" mask
    let newValue = '';

    for (let i = 0; i < value.length; ++i) {
      if (i === 2 || i === 4) {
        newValue += '/';
      }
      newValue += value[i];
    }

    if (newValue.length >= 10) {
      newValue = newValue.slice(0, 10);
    }

    // forcefully limit character value
    if (newValue.slice(0, 2) > '31') {
      newValue = '31';
    }

    if (newValue.slice(3, 5) > '12') {
      newValue = newValue.slice(0, 3);
      newValue += '12';
    }

    if (newValue.slice(6, 10) > date.getFullYear().toString()) {
      newValue = newValue.slice(0, 6);
      newValue += date.getFullYear().toString();
    }

    e.target.value = newValue;
    setDataNascimento(e.target.value);
  };

  const handleTelefoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    for (let i = 0; i < e.target.value.length; ++i) {
      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    // apply the "(__)_____-____" mask
    let newValue = '';

    for (let i = 0; i < value.length; ++i) {
      if (i === 0) {
        newValue += '(';
      }
      if (i === 2) {
        newValue += ') ';
      }
      if (i === 7) {
        newValue += '-';
      }

      newValue += value[i];
    }

    // forcefully limit characters count
    if (newValue.length > 15) {
      newValue = newValue.substring(0, 15);
    }

    e.target.value = newValue;
    setTelefone(newValue);
  };
  const handleRgChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRg(e.target.value);
  };

  const handlePotencia = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';
    for (let i = 0; i < e.target.value.length; ++i) {
      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }
    setPotenciaInstalada(e.target.value);
  };
  const handleCpfChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    for (let i = 0; i < e.target.value.length; ++i) {
      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    // apply the "___.___.___-__" mask
    let newValue = '';

    for (let i = 0; i < value.length; ++i) {
      newValue += value[i];
      if (i === 2 || i === 5) {
        newValue += '.';
      }
      if (i === 8) {
        newValue += '-';
      }
    }

    // forcefully limit characters count
    if (newValue.length > 14) {
      newValue = newValue.substring(0, 14);
    }

    e.target.value = newValue;
    setCpf(newValue);
  };
  const handleRendaChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = removeNonNumeric(e.target.value);
    setRenda(parseInt(value, 10));
    e.target.value = applyCurrencyMask(value);
  };

  const handleNomeMaeChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = onlyLetters(e);
    setNomeMae(e.target.value);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleMediaLuzChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = removeNonNumeric(e.target.value);
    setMediaLuz(parseInt(value, 10));
    e.target.value = applyCurrencyMask(value);
  };
  const handleNumeroChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    for (let i = 0; i < e.target.value.length; ++i) {
      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    e.target.value = value;
    setNumero(e.target.value);
  };

  const handleComplementoChange = (e: ChangeEvent<HTMLInputElement>) => {
    setComplemento(e.target.value);
  };
  const buscaCep = async (cepVal: string) => {
    // remove hyphenation
    const value = cepVal.replace('-', '');
    const request = {
      data: {
        ref: value,
      },
    };

    const respon = await api.post('/address/cep/get', request);

    const error = respon.data as ViaCepErro;
    if (error.erro !== true) {
      // update logradouro field
      const ruaElem = document.getElementById('ruaField');
      setRua(respon.data.rua);
      ruaElem?.setAttribute('value', respon.data.rua);

      // update state field
      const estadoEle = document.getElementById('estadoField');
      setEstado(respon.data.estadoId);
      estadoEle?.setAttribute('value', respon.data.estadoId);

      const cidadeEle = document.getElementById('cidadeField');

      getCidades(respon.data.estadoId);
      setCidade(respon.data.cidadeId);

      cidadeEle?.setAttribute('value', respon.data.cidadeId);
    }
    setIsCepLoading(false);
  };
  const handleCepChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    for (let i = 0; i < e.target.value.length; ++i) {
      // prevent field to go over 9 characters
      if (i === 9) break;

      if (i === 5) {
        value += '-';
      }

      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    e.target.value = value;
    setCep(value);

    if (value.length === 9) {
      setIsCepLoading(true);
      buscaCep(value);
    }
  };
  const handleCityChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setCidade(parseInt(e.target.value, 10));
  };

  const handleRuaChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRua(e.target.value);
  };
  const getToday = (m: string) => {
    const date = new Date();
    const day = date.getDate().toString();
    let month = ((date.getMonth() + 1).toString());
    if (month.length === 1) month = `0${month}`;
    const year = date.getFullYear().toString();

    // check if date is after today
    return !(m.slice(6, 10) > year
      || (m.slice(6, 10) >= year
        && m.slice(3, 5) > month)
      || (m.slice(0, 2) > day
        && m.slice(3, 5) >= month
        && m.slice(6, 10) >= year));
  };
  const validateNome = (m: string) => m.length >= 5;
  const validateCpf = (m: string) => m.length >= 11;
  const validateRg = (m: string) => m.length > 5;
  const validateDataNascimento = (m: string) => {
    const leapDay = m.slice(0, 2);
    const leapMonth = m.slice(3, 5);
    const leapYear = m.slice(6, m.length);

    if (getToday(m) && dateMaskAlt.test(m)) {
      return true;
    } return (parseInt(leapYear, 10) % 4 === 0) && leapMonth === '02' && leapDay === '29';
  };

  const validateNomeMae = (m: string) => m.length >= 5;
  const validateTelefone = (m: string) => m.length > 10;
  const validateRenda = (m: number) => m > 0;
  const validateEmail = (m: string) => m.length > 5;
  const validateMediaLuz = (m: number) => m > 0;

  const validateRua = (m: string) => m.length > 0;
  const validateNumero = (m: string) => m.length > 0;
  const validateComplemento = (m: string) => true;
  const validateCep = (m: string) => m.length >= 5;
  const validateCidade = (m: number) => true;
  const validateEstado = (m: number) => true;

  const validatePotencia = (m: string) => m.length > 0;

  const validateContaLuz = (m: string) => contaLuz;
  const validateEquipamento = (m: string) => equipamento;
  const validateIdentidade = (m: string) => identidade;
  const validateIptu = (m: string) => iptu;

  const validateForm = () => {
    const messages: any[] = [];
    // eslint-disable-next-line max-len
    // if (!equipamento) { messages.push('O campo "Nota Fiscal do Equipamento" não foi preenchido.'); }
    // if (!iptu) { messages.push('O campo "Nota Fiscal do Equipamento" não foi preenchido.'); }
    // eslint-disable-next-line max-len
    // if (!comprovanteRenda) { messages.push('O campo "Comprovante de Renda" não foi preenchido.'); }
    // if (!contaLuz) { messages.push('O campo "Conta de Luz Atual" não foi preenchido.'); }
    // if (!identidade) { messages.push('O campo "Documento de Identidade" não foi preenchido.'); }

    if (!validateNome(nome)) {
      messages.push('O campo "Nome" está formatado incorretamente');
    }
    if (!validateCpf(cpf)) {
      messages.push('O campo "Cpf" está formatado incorretamente');
    }
    if (!validateRg(rg)) {
      messages.push('O campo "RG" está formatado incorretamente');
    }
    if (!validateDataNascimento(dataNascimento)) {
      messages.push('O campo "Data de Nascimento" está formatado incorretamente');
    }

    if (!validateNomeMae(nomeMae)) {
      messages.push('O campo "Nome da Mãe" está formatado incorretamente');
    }
    if (!validateTelefone(telefone)) {
      messages.push('O campo "Telefone" está formatado incorretamente');
    }
    if (!validateRenda(renda)) {
      messages.push('O campo "Renda" está formatado incorretamente');
    }
    if (!validateEmail(email)) {
      messages.push('O campo "Email" está formatado incorretamente');
    }
    if (!validateMediaLuz(mediaLuz)) {
      messages.push('O campo "Média da Conta de Luz" está formatado incorretamente');
    }

    if (!validateRua(rua)) {
      messages.push('O campo "Rua" está formatado incorretamente');
    }
    if (!validateNumero(numero.toString())) {
      messages.push('O campo "Número" está formatado incorretamente');
    }
    // if (!validateComplemento(complemento)) {
    //   messages.push('O campo "Complemento" está formatado incorretamente');
    // }
    if (!validateCep(cep)) {
      messages.push('O campo "CEP" está formatado incorretamente');
    }
    if (!validateCidade(cidade)) {
      messages.push('O campo "Cidade" está formatado incorretamente');
    }
    if (!validateEstado(estado)) {
      messages.push('O campo "Estado" está formatado incorretamente');
    }
    // if (!validatePotencia(potencia)) {
    //   messages.push('O campo "Potência Instalada" está formatado incorretamente');
    // }
    // if (!validateEquipamento(equipamento)) {
    //   messages.push('O campo "Nota Fiscal do Equipamento" está formatado incorretamente');
    // }
    // if (!validateContaLuz(contaLuz)) {
    //   messages.push('O campo "Conta de Luz Atual" está formatado incorretamente');
    // }
    // if (!validateIdentidade(identidade)) {
    //   messages.push('O campo "Documento de Identificação" está formatado incorretamente');
    // }
    // if (!validateIdentidade(comprovanteRenda)) {
    //   messages.push('O campo "Comprovante de Renda" está formatado incorretamente');
    // }
    // if (!validateIptu(iptu)) {
    //   messages.push('O campo "IPTU" está formatado incorretamente');
    // }

    setErrorMessage(messages);
    return messages.length === 0;
  };

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    // eslint-disable-next-line no-alert
    if (window.confirm('Tem certeza que deseja salvar os dados do formulário?')) {
      if (validateForm()) {
        setLoadingText('Salvando Dados...');
        setIsOpen(true);
        const projectId = issue?.projeto.id;
        const request = {
          headers: { 'Content-Type': 'application/json' },
          data: {
            issueId: issue?.id,
            tag,
            projectId,

            nome,
            cpf,
            rg,
            dataNascimento,

            nomeMae,
            telefone,
            renda: renda.toString(),
            email,
            mediaLuz: mediaLuz.toString(),

            valorPatrimonio,
            tempoProfissao,

            rua,
            numero,
            complemento,
            cep,
            cidade,

            potenciaInstalada,
            nomeProjeto,
            valorCarencia,
            valorEntrada,
            valorTotal,
          },
        };

        const res = await api.post('/project/costumer/alter', request);

        switch (res.data) {
          case 'NEW_CLIENT_INSERTED':
          case 'COSTUMER_UPDATED':
            // toast.success('Dados do
            // cliente cadastrados com sucesso. Aguardar a liberação
            // do painel de opções de crédito.');
            toast.success('Dados Salvos!');
            setIsOpen(false);
            break;
          default:
            setIsOpen(false);
            toast.error(`Falha ao inserir cotação. ${res.data}`);
          // ref?.current?.complete();
        }
      } else {
        setIsOpen(false);
        toast.warn('Alguns campos não estão preenchidos corretamente.');
      }
    }
    // ref?.current?.complete();
  };
  const handleSubmitIssue = async () => {
    setLoadingText('Atualizando chamado...');
    setIsOpen(true);
    const request = {
      headers: { 'Content-Type': 'application/json' },
      data: {
        issueId: issue?.id,
        tag,
        status,
      },
    };

    const responseStatus = await api.post('/issue/status/put', request);

    switch (responseStatus.data) {
      case 'ISSUE_UPDATED':
        toast.success('Status do chamado alterado com sucesso!');
        setIsOpen(false);
        history.goBack();
        // window.location.reload();
        break;
      default:
        setIsOpen(false);
        toast.error(`Falha ao alterar o status do chamado.${responseStatus.data}`);
        break;
    }
  };
  const handleInputChangeRadioStatus = async (statusR: any) => {
    switch (statusR) {
      case 1:
        setStatus('Aberto');
        setRadioStatus(statusR);
        break;
      case 2:
        setStatus('Fechado');
        setRadioStatus(statusR);
        break;
      default:
        // setRadioStatus(statusR);
    }
  };
  // @ts-ignore
  // @ts-ignore
  return (
    <div>
      <LoadingBar color="#f11946" ref={ref} />
      <FormPage>
        <br />
        <form>
          <h1>
            Chamado aberto para o projeto #
            {issue && (
              issue.projeto.id
            )}
          </h1>
          <div>
            <label>
              Assunto do Chamado
              <RequiredFieldMark />
              <input
                type="text"
                id="f_assunto"
                disabled
                className={style.f_long_field}
                value={issue?.assunto}
                placeholder="Assunto"
                onChange={handleNomeChange}
              />
            </label>
            <br />
            <br />
            <label>
              Descrição do Chamado
              <RequiredFieldMark />
              <textarea
                disabled
                // disabled={integrator?.status !== 'Pendente'}
                value={issue?.descricao}
                className={style.detalheCadastroJustificativa}
                placeholder="Descreva quais são os dados a serem alterados."
                // @ts-ignore
                // onChange={handleDescricaoChange}
              />
            </label>
          </div>
          <div>
            <form style={{ display: 'block' }}>
              <h1>Mudanças de campos solicitadas</h1>
              {/* &nbsp; */}
              {/* <button */}
              {/*   type="button" */}
              {/*   // onClick={handleSalvarEntradas} */}
              {/* > */}
              {/*   Enviar Cotações */}
              {/* </button> */}
              <br />
              <br />
              {/* {( */}
              {/*   // @ts-ignore */}
              {/*   (issue.modification !== null && issue.modification.length > 0) */}
              {/*     // @ts-ignore */}
              {/*     ? (issue.modification.map((x) => ( */}
              {/*       <ChamadoAvaliarEditor */}
              {/*         idx={issue.modification.indexOf(x)} */}
              {/*         key={issue.modification.indexOf(x)} */}
              {/*         data={x} */}
              {/*       /> */}
              {/*     ))) */}
              {/*     : ( */}
              {/*       <i> */}
              {/*         Nenhuma alteração de campo adicionada */}
              {/*       </i> */}
              {/*     ))} */}
              {(issue?.modification.map((x) => (
                <ChamadoAvaliarEditor
                  idx={issue.modification.indexOf(x)}
                  key={issue.modification.indexOf(x)}
                  data={x}
                />
              )))}
              <br />
              <br />
            </form>
          </div>
          <br />
          <br />
          {/* <Baloon mode="clean">{instrucoesAlerta}</Baloon> */}
          <br />
          <br />
          <h1>Cadastro do Cliente</h1>
          {errorMessage.length > 0 && (
            <Baloon mode="warning">
              <ul>
                {errorMessage.map((m) => (
                  <li key={m}>{m}</li>
                ))}
              </ul>
            </Baloon>
          )}
          <div>
            <label>
              Nome Completo
              <RequiredFieldMark />
              <input
                disabled={nomeEnable}
                type="text"
                id="f_nome"
                className={style.f_long_field}
                onChange={handleNomeChange}
                placeholder="Fulano Nome Completo"
              />
            </label>
            <br />
            <label>
              CPF
              <RequiredFieldMark />
              <input
                disabled={cpfEnable}
                type="text"
                id="f_cpf"
                placeholder="000.000.000-00"
                maxLength={14}
                style={{ width: '50px' }}
                onChange={handleCpfChange}
              />
            </label>
            <label>
              RG
              <RequiredFieldMark />
              <input
                disabled={rgEnable}
                type="text"
                id="f_rg"
                style={{ width: '50px' }}
                placeholder="ABC1234ABC-98"
                onChange={handleRgChange}
              />
            </label>
            <label>
              Data de Nascimento
              <RequiredFieldMark />
              <input
                disabled={dataNascimentoEnable}
                type="text"
                style={{ width: '50px' }}
                id="f_nasc"
                placeholder="00/00/0000"
                onChange={handleDataNascimentoChange}
              />
            </label>
            <br />
            <label>
              Nome da Mãe
              <RequiredFieldMark />
              <input
                disabled={nomeMaeEnable}
                type="text"
                id="f_nomemae"
                className={style.f_long_field}
                placeholder="Nome da Mãe"
                onChange={handleNomeMaeChange}
              />
            </label>
            <label>
              Telefone
              <RequiredFieldMark />
              <input
                disabled={telefoneEnable}
                type="text"
                id="f_telefone"
                style={{ width: '50px' }}
                placeholder="(00) 98765-4321"
                onChange={handleTelefoneChange}
              />
            </label>
            <br />
            <label>
              Renda/Faturamento Mensal (Líquida)
              <RequiredFieldMark />
              <input
                disabled={rendaEnable}
                type="text"
                style={{ width: '50px' }}
                id="f_renda"
                placeholder="R$ 3.000,00"
                onChange={handleRendaChange}
              />
            </label>
            <br />
            <label>
              Estado civil
              <RequiredFieldMark />
              <select
                disabled={estadoCivilEnable}
                value={estadoCivil}
                onChange={handleEstadoCivilChange}
                id="f_estadoCivil"
              >
                {estadosCivil?.map((x) => (
                  <option key={x.key} value={x.name}>
                    {x.name}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Profissão
              <RequiredFieldMark />
              <input
                disabled={profissaoEnable}
                type="text"
                id="f_profissao"
                placeholder="Profissão"
                onChange={handleProfissaoChange}
              />
            </label>
            <label>
              Tempo de profissão (máx 50 anos)
              <RequiredFieldMark />
              <input
                disabled={tempoProfissaoEnable}
                type="text"
                onChange={handleTempoProfissaoChange}
                placeholder="Anos"
                id="f_tempoProfissao"
                style={{ width: '50px' }}
              />
            </label>
            <label>
              Valor do Patrimônio
              <RequiredFieldMark />
              <input
                disabled={valorPatrimonioEnable}
                type="text"
                id="f_patrimonio"
                onChange={handleValorPatrimonioChange}
                placeholder="R$ 123,55"
              />
            </label>
            <label>
              E-mail do Cliente
              <RequiredFieldMark />
              <input
                disabled={emailEnable}
                onChange={handleEmailChange}
                type="text"
                id="f_email"
                placeholder="cliente@domínio.com"
              />
            </label>
            <label>
              Média da Conta de Luz (últimos 3 meses)
              <RequiredFieldMark />
              <input
                disabled={mediaLuzEnable}
                type="text"
                id="f_luz"
                onChange={handleMediaLuzChange}
                placeholder="R$ 123,55"
              />
            </label>
            {/* <label>
              Área de Atuação
              <RequiredFieldMark />
              <select id="f_area">
                <option value="1">-- selecione --</option>
                <option value="2">item 1</option>
                <option value="3">item 2</option>
              </select>
            </label>
            <label>
              Produção
              <RequiredFieldMark />
              <select id="f_producao">
                <option value="1">-- selecione --</option>
                <option value="2">item 1</option>
                <option value="3">item 2</option>
              </select>
            </label> */}
          </div>
          <h1>Endereço da Instalação</h1>
          <div>
            <label>
              CEP
              <RequiredFieldMark />
              {/* <LoadingIcon active={isCepLoading} /> */}
              <input
                disabled={cepEnable}
                style={{ width: '50px' }}
                type="text"
                onChange={handleCepChange}
                placeholder="00000-000"
                id="f_cep"
              />
            </label>
            <label>
              Logradouro
              <RequiredFieldMark />
              <input
                disabled={ruaEnable}
                type="text"
                className={style.f_long_field}
                onChange={handleRuaChange}
                placeholder="Rua de Exemplo"
                id="ruaField"
              />
            </label>
            <label>
              Número
              <RequiredFieldMark />
              <input
                disabled={numeroEnable}
                type="text"
                onChange={handleNumeroChange}
                placeholder="0000"
                id="f_numero"
                style={{ width: '50px' }}
              />
            </label>
            <label>
              Complemento
              <input
                disabled={complementoEnable}
                type="text"
                id="f_complemento"
                onChange={handleComplementoChange}
                placeholder="Casa 0000"
                style={{ width: '50px' }}
              />
            </label>
            <br />

            <label>
              Estado
              <RequiredFieldMark />
              <select
                disabled={estadoEnable}
                defaultValue={1}
                value={estado}
                onChange={handleStateChange}
                id="estadoField"
              >
                {estados?.map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.name}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Cidade
              <RequiredFieldMark />
              <select
                disabled={cidadeEnable}
                defaultValue={1}
                value={cidade}
                onChange={handleCityChange}
                id="cidadeField"
              >
                {cidades?.map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.name}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <h1>Outras Informações</h1>
          <div>
            <label>
              Nome do Projeto
              <input
                disabled={nomeProjetoEnable}
                type="text"
                id="nome"
                placeholder="Meu Projeto"
                onChange={handleNomeChange}
              />
            </label>
            <label>
              Potência instalada (KW)
              <input
                disabled={potenciaInstaladaEnable}
                type="text"
                onChange={handlePotenciaInstaladaChange}
                placeholder="0000"
                id="potencia"
                style={{ width: '100px' }}
              />
            </label>
          </div>
          <br />
          <div>
            <label>
              Valor total do projeto
              <input
                disabled={valorTotalEnable}
                type="text"
                onChange={handleValorTotalChange}
                placeholder="R$40.000,00"
                id="valorTotal"
                style={{ width: '100px' }}
              />
            </label>
            <label>
              Entrada
              <input
                disabled={valorEntradaEnable}
                type="text"
                onChange={handleValorEntradaChange}
                placeholder="R$3.000,00"
                id="valorEntrada"
                style={{ width: '100px' }}
              />
            </label>
            <label>
              Carência
              <select
                disabled={valorCarenciaEnable}
                style={{ width: '100px' }}
                onChange={handleCarencia}
                id="carencia"
                value={valorCarencia}
                defaultValue={1}
              >
                <option value={1}>1 mês</option>
                <option value={2}>2 meses</option>
                <option value={3}>3 meses</option>
                <option value={4}>4 meses</option>
                <option value={5}>5 meses</option>
                <option value={6}>6 meses</option>
              </select>
            </label>
          </div>
          <br />
          <br />
          <input type="submit" id="submit_btn" value="Salvar dados" onClick={handleSubmit} />
          <br />
          <br />
          <h1>Documentação</h1>
          <div>
            <table>
              <tbody>
                <tr>
                  <td>
                    <label htmlFor="upload_equipamento">
                      Nota Fiscal do Equipamento
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        id="upload_equipamento"
                        name="upload_equipamento"
                      // onChange={handleEquipamentoChange}
                        type="file"
                      />
                      {!loadedFiles.equipamento && (
                      <img
                        src={uploadIcon}
                        alt="upload nota equipamento"
                        style={{ width: '15px' }}
                        className={style.uploadIcon}
                      />
                      )}
                      {
                      // eslint-disable-next-line no-nested-ternary
                      loadedFiles.equipamento === 'Sem documentos'
                        ? 'Não enviado'
                        : !loadedFiles.equipamento ? ''
                          : (<button type="button" onClick={handleDownloadEquipamento}>abrir arquivo</button>)
                    }
                      &nbsp;
                      {
                        // eslint-disable-next-line no-nested-ternary
                        loadedFiles.equipamento === 'Sem documentos'
                          ? ''
                          : !loadedFiles.equipamento ? ''
                            : (<button type="button" onClick={handleSubEquipamento}>Aprovar Documento</button>)
                      }
                    </label>
                  </td>
                  <td>{equipamento?.name}</td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="upload_contaluz">
                      Última Conta de Luz
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        id="upload_contaluz"
                        name="upload_contaluz"
                        // onChange={handleContaLuzChange}
                        type="file"
                      />
                      {!loadedFiles.contaLuz && (
                      <img
                        src={uploadIcon}
                        alt="upload conta luz"
                        style={{ width: '15px' }}
                        className={style.uploadIcon}
                      />
                      )}
                      {
                      // eslint-disable-next-line no-nested-ternary
                      loadedFiles.contaLuz === 'Sem documentos'
                        ? 'Não enviado'
                        : !loadedFiles.contaLuz ? ''
                          : <button type="button" onClick={handleDownloadContaLuz}>abrir arquivo</button>
                    }
                      &nbsp;
                      {
                        // eslint-disable-next-line no-nested-ternary
                        loadedFiles.contaLuz === 'Sem documentos'
                          ? ''
                          : !loadedFiles.contaLuz ? ''
                            : (<button type="button" onClick={handleSubContaLuz}>Aprovar Documento</button>)
                      }
                    </label>
                  </td>
                  <td>{contaLuz?.name}</td>
                </tr>
                {/* {contaLuz && ( */}
                {/*   <tr> */}
                {/*     <tr> */}
                {/*       <td className={style.electricity_bill_ownership}> */}
                {/*         <label> */}
                {/*           Conta de luz está no nome do cliente? */}
                {/*         </label> */}
                {/*       </td> */}
                {/*     </tr> */}
                {/*     <tr> */}
                {/*       <td className={style.electricity_bill_ownership}> */}
                {/*         <label> */}
                {/*           <input */}
                {/*             type="radio" */}
                {/*             name="radioClientOwner" */}
                {/*             checked={isClientOwner as boolean} */}
                {/*             onChange={() => setIsClientOwner(true)} */}
                {/*           /> */}
                {/*           Sim */}
                {/*         </label> */}
                {/*         <label> */}
                {/*           <input */}
                {/*             type="radio" */}
                {/*             checked={!isClientOwner as boolean} */}
                {/*             onChange={() => setIsClientOwner(false)} */}
                {/*             name="radioClientOwner" */}
                {/*           /> */}
                {/*           Não */}
                {/*         </label> */}
                {/*       </td> */}
                {/*     </tr> */}
                {/*   </tr> */}
                {/* )} */}
                {/* { !isClientOwner */}
                {/*   ? ( */}
                {/*     <tr> */}
                {/*       <td className={style.electricity_bill_ownership}> */}
                {/*         <label> */}
                {/*           <input */}
                {/*             value={1} */}
                {/*             type="radio" */}
                {/*             onChange={(e) => handleInputChangeRadioTypeFamily(1)} */}
                {/*             name="radioTypeFamily" */}
                {/*           /> */}
                {/*           Mãe */}
                {/*         </label> */}
                {/*         <label> */}
                {/*           <input */}
                {/*             value={2} */}
                {/*             type="radio" */}
                {/*             // checked={!isClientOwner as boolean} */}
                {/*             onChange={(e) => handleInputChangeRadioTypeFamily(2)} */}
                {/*             name="radioTypeFamily" */}
                {/*           /> */}
                {/*           Pai */}
                {/*         </label> */}
                {/*         <label> */}
                {/*           <input */}
                {/*             value={3} */}
                {/*             type="radio" */}
                {/*             // checked={!isClientOwner as boolean} */}
                {/*             onChange={(e) => handleInputChangeRadioTypeFamily(3)} */}
                {/*             name="radioTypeFamily" */}
                {/*           /> */}
                {/*           Esposo(a) */}
                {/*         </label> */}
                {/*         <label> */}
                {/*           <input */}
                {/*             value={4} */}
                {/*             type="radio" */}
                {/*             // checked={!isClientOwner as boolean} */}
                {/*             onChange={(e) => handleInputChangeRadioTypeFamily(4)} */}
                {/*             name="radioTypeFamily" */}
                {/*           /> */}
                {/*           Filho(a) */}
                {/*         </label> */}
                {/*         <label> */}
                {/*           <input */}
                {/*             value={5} */}
                {/*             type="radio" */}
                {/*             // checked={!isClientOwner as boolean} */}
                {/*             onChange={(e) => handleInputChangeRadioTypeFamily(5)} */}
                {/*             name="radioTypeFamily" */}
                {/*           /> */}
                {/*           Irmão/Irmã */}
                {/*         </label> */}
                {/*       </td> */}
                {/*     </tr> */}
                {/*   ) */}
                {/*   : ''} */}
                <tr>
                  <td>
                    <label htmlFor="upload_identidade">
                      Documento de Identificação
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        id="upload_identidade"
                        name="upload_identidade"
                        // onChange={handleIdentidadeChange}
                        type="file"
                      />
                      {!loadedFiles.identidade && (
                      <img
                        src={uploadIcon}
                        alt="upload documento identificação"
                        style={{ width: '15px' }}
                        className={style.uploadIcon}
                      />
                      )}
                      {
                      // eslint-disable-next-line no-nested-ternary
                      loadedFiles.identidade === 'Sem documentos'
                        ? 'Não enviado'
                        : !loadedFiles.identidade ? ''
                          : (<button type="button" onClick={handleDownloadIdentidade}>abrir arquivo</button>)
                    }
                      &nbsp;
                      {
                        // eslint-disable-next-line no-nested-ternary
                        loadedFiles.identidade === 'Sem documentos'
                          ? ''
                          : !loadedFiles.identidade ? ''
                            : (<button type="button" onClick={handleSubIdentidade}>Aprovar Documento</button>)
                      }
                    </label>
                  </td>
                  <td>{identidade?.name}</td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="upload_comprovanteRenda">
                      Comprovante de Renda
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        id="upload_comprovanteRenda"
                        name="upload_comprovanteRenda"
                        // onChange={handleComprovanteRendaChange}
                        type="file"
                      />
                      {!loadedFiles.comprovanteRenda && (
                      <img
                        src={uploadIcon}
                        alt="upload comprovante renda"
                        style={{ width: '15px' }}
                        className={style.uploadIcon}
                      />
                      )}
                      {
                      // eslint-disable-next-line no-nested-ternary
                      loadedFiles.comprovanteRenda === 'Sem documentos'
                        ? 'Não enviado'
                        : !loadedFiles.comprovanteRenda ? ''
                          : (<button type="button" onClick={handleDownloadComprovanteRenda}>abrir arquivo</button>)
                    }
                      &nbsp;
                      {
                        // eslint-disable-next-line no-nested-ternary
                        loadedFiles.comprovanteRenda === 'Sem documentos'
                          ? ''
                          : !loadedFiles.comprovanteRenda ? ''
                            : (<button type="button" onClick={handleSubComprovanteRenda}>Aprovar Documento</button>)
                      }
                    </label>
                  </td>
                  <td>{comprovanteRenda?.name}</td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="upload_iptu">
                      IPTU (em nome do cliente)
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        id="upload_iptu"
                        name="upload_iptu"
                        // onChange={handleIptuChange}
                        type="file"
                      />
                      {!loadedFiles.iptu && (
                      <img
                        src={uploadIcon}
                        alt="upload comprovante iptu"
                        style={{ width: '15px' }}
                        className={style.uploadIcon}
                      />
                      )}
                      {
                      // eslint-disable-next-line no-nested-ternary
                      loadedFiles.iptu === 'Sem documentos'
                        ? 'Não enviado'
                        : !loadedFiles.iptu ? ''
                          : (<button type="button" onClick={handleDownloadIptu}>abrir arquivo</button>)
                    }
                      &nbsp;
                      {
                        // eslint-disable-next-line no-nested-ternary
                        loadedFiles.iptu === 'Sem documentos'
                          ? ''
                          : !loadedFiles.iptu ? ''
                            : (<button type="button" onClick={handleSubIPTU}>Aprovar Documento</button>)
                      }
                    </label>
                  </td>
                  <td>{iptu?.name}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <br />
          <hr />
          <br />
          <br />
          <h1>
            Status do chamado:&nbsp;
            { status}
          </h1>
          <label>
            <input
              value={1}
              type="radio"
              name="radioStatus"
              checked={status === 'Aberto'}
              onChange={(e) => handleInputChangeRadioStatus(1)}
            />
            Manter aberto
          </label>
          <label>
            <input
              value={2}
              type="radio"
              checked={status === 'Fechado'}
              onChange={(e) => handleInputChangeRadioStatus(2)}
              name="radioStatus"
            />
            Fechar
          </label>
          <br />
          <br />
          <button
            type="button"
            style={{ display: 'block' }}
            onClick={handleSubmitIssue}
          >
            Salvar status
          </button>
          <br />
          <br />
          {/* <label>
            <input type="checkbox" id="scales" name="scales" />
            {text_produtor}
          </label> */}
          {/* <br /> */}
          {/* <label> */}
          {/*   <input */}
          {/*     type="checkbox" */}
          {/*     id="checkDeclaro" */}
          {/*     name="checkDeclaro" */}
          {/*     checked={checkDeclaro} */}
          {/*     onChange={checkDeclaroChange} */}
          {/*   /> */}
          {/*   {text_declaracao} */}
          {/* </label> */}
          {/* <br /> */}
          {/* <label> */}
          {/*   <input */}
          {/*     type="checkbox" */}
          {/*     id="checkAutorizo" */}
          {/*     name="checkAutorizo" */}
          {/*     checked={checkAutorizo} */}
          {/*     onChange={checkAutorizoChange} */}
          {/*   /> */}
          {/*   {text_autorizacao} */}
          {/*   <Anchor to="/termos">Ficha de Autorização do Uso de Dados</Anchor> */}
          {/*   . */}
          {/* </label> */}
          {/* <br /> */}
          {/* <label> */}
          {/*   <input */}
          {/*     type="checkbox" */}
          {/*     id="checkTermos" */}
          {/*     name="checkTermos" */}
          {/*     checked={checkTermos} */}
          {/*     onChange={checkTermosChange} */}
          {/*   /> */}
          {/*   {text_concordo_1} */}
          {/*   {' '} */}
          {/*   <Anchor to="/termos">Política de Privacidade</Anchor> */}
          {/*   {text_concordo_2} */}
          {/*   {' '} */}
          {/*   <Anchor to="/termos">Termos de Uso</Anchor> */}
          {/*   {text_concordo_3} */}
          {/* </label> */}
          {/* <br /> */}
          {/* <br /> */}

        </form>
        <br />
      </FormPage>
      <ToastContainer />
      <DialogComponent isOpen={isOpen} labelText={loadingText} />
    </div>
  );
}
