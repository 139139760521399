import React, { ChangeEvent, useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Box,
  FormControl, IconButton, InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table, TableBody,
  TableCell, TableContainer,
  TableHead,
  TableRow,
  TextField, Typography,
} from '@mui/material';
import { parseCookies } from 'nookies';
import { AxiosResponse } from 'axios';
import ClearIcon from '@mui/icons-material/Clear';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import FileOpenRoundedIcon from '@mui/icons-material/FileOpenRounded';
import { useHistory } from 'react-router-dom';
import Button from '../../components/elements/Button';
import {
  handleFileName, handleFixCurrency, isValidUrl, openPdf, sendDocument,
} from '../../utils/util';
import { SectionTitle } from '../../components/elements/SectionTitle';
import Divider from '../../components/elements/Divider';
import {
  FormField, Issue, Modification,
} from '../../contexts/@types';
import { defaultIssue, defaultModification } from '../../contexts/@defaultValues';
import { api } from '../../services/api';
import { DialogComponent } from '../../components/DialogComponent';

export function AbrirChamado() {
  const [issue, setIssue] = useState<Issue>(defaultIssue);
  const [modifications, setModifications] = useState<Array<Modification>>([]);
  const [modification, setModification] = useState<Modification>(defaultModification);
  const [formFields, setFormFields] = useState<Array<FormField>>([]);
  const [fieldsError, setFieldsError] = useState({
    codAttribute: false,
    newValue: false,
    matter: false,
    description: false,
  });

  const { 'nextauth.p': cprojId } = parseCookies();
  const { 'nextauth.tag': tag } = parseCookies();
  const projectId = Number(cprojId);
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const request = {
      headers: { 'Content-Type': 'application/json' },
      data: {
        projectId,
      },
    };
    const getOptions = async () => {
      const response: Promise<AxiosResponse<any, any>> = api.post('/formFields/get', request);
      response.then((resolved) => {
        const items: Array<FormField> = resolved.data;
        setFormFields(items);
        return items;
      });
    };
    getOptions();
  }, [projectId]);

  const handleFixFieldValue = (code: string, value: string | number) => {
    if (
      code === 'renda_cliente'
      || code === 'valor_patrimonio'
      || code === 'media_conta'
      || code === 'valor_projeto'
      || code === 'entrada_projeto'
    ) {
      return `R$ ${handleFixCurrency(value)}`;
    }
    return value;
  };

  const handleChangeIssue = (key: keyof Issue) => (e:
    ChangeEvent<HTMLInputElement>) => {
    let input: string | boolean | number | File | null = e.target.value;

    if (
      key === 'notaFiscalEquipamentos'
      || key === 'contaLuzRecente'
      || key === 'documentoIdentificacao'
      || key === 'comprovanteRenda'
      || key === 'iptu'
      || key === 'notaFiscal'
    ) {
      if (e.target.files) {
        const [file] = Array.from(e.target.files);
        if (file.type === 'application/pdf') input = file;
      } else if (e.target.value === 'clear') input = 'Sem documentos';
    }

    setIssue({
      ...issue,
      [key]: input,
    });
  };

  const handleRemoveFiles = (key: keyof Issue) => {
    const fakeEvent = {
      target: {
        value: 'clear',
      },
    } as React.ChangeEvent<HTMLInputElement>;
    handleChangeIssue(key)(fakeEvent);
  };

  const handleChangeMod = (key: keyof Modification) => (e:
    ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setModification({
      ...modification,
      [key]: value,
    });
  };

  const validateChangeFields = () => {
    const errors = {
      codAttribute: !modification.codAttribute,
      newValue: !modification.newValue,
    };

    setFieldsError({ ...fieldsError, ...errors });

    const fieldNames = {
      codAttribute: 'Campo a ser alterado',
      newValue: 'Novo valor',
    };

    const emptyFields = Object.keys(errors)
      .filter((key) => errors[key as keyof typeof errors])
      .map((key) => fieldNames[key as keyof typeof fieldNames])
      .join(';; ');

    if (emptyFields.length > 0) {
      toast.error(
        <>
          O(s) campo(s) a seguir devem ser preenchidos:
          {emptyFields.split('; ').map((e) => (
            <React.Fragment key={e}>
              <br />
              {e}
            </React.Fragment>
          ))}
        </>,
      );

      return false;
    }

    return true;
  };
  const handleAddMod = () => {
    if (!validateChangeFields()) return;
    const newMod = modifications;
    newMod.push(modification);
    setModifications(newMod);
    setModification(defaultModification);
  };

  const handleRemoveMod = (mod: Modification) => {
    const modsAtt = modifications.filter((e) => e.id !== mod.id);
    setModifications(modsAtt);
  };

  const handleCancel = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Tem certeza que deseja cancelar a abertura deste chamado?')) {
      history.goBack();
    }
  };

  const validateFields = () => {
    let errors = {
      matter: !issue.assunto,
      description: !issue.descricao,
      change: false,
    };

    if (modifications.length === 0
      && !issue.notaFiscalEquipamentos
      && !issue.contaLuzRecente
      && !issue.documentoIdentificacao
      && !issue.comprovanteRenda
      && !issue.iptu
      && !issue.notaFiscal) {
      errors = {
        ...errors,
        change: true,
      };
    }
    setFieldsError({ ...fieldsError, ...errors });

    const fieldNames = {
      matter: 'Assunto do chamado',
      description: 'Descrição do chamado',
      change: 'Deve solicitar alguma alteração',
    };

    const emptyFields = Object.keys(errors)
      .filter((key) => errors[key as keyof typeof errors])
      .map((key) => fieldNames[key as keyof typeof fieldNames])
      .join(';; ');

    if (emptyFields.length > 0) {
      toast.error(
        <>
          O(s) campo(s) a seguir devem ser preenchidos:
          {emptyFields.split('; ').map((e) => (
            <React.Fragment key={e}>
              <br />
              {e}
            </React.Fragment>
          ))}
        </>,
      );

      return false;
    }

    return true;
  };

  const haveSubmitableFile = () => issue.notaFiscalEquipamentos instanceof File
      || issue.contaLuzRecente instanceof File
      || issue.documentoIdentificacao instanceof File
      || issue.comprovanteRenda instanceof File
      || issue.iptu instanceof File
      || issue.notaFiscal instanceof File;

  const submitFiles = async () => {
    setLoading(true);
    const sentDocuments = {
      notaFiscalEquipamentos: await sendDocument('equipamento', issue.notaFiscalEquipamentos),
      contaLuzRecente: await sendDocument('contaLuz', issue.contaLuzRecente),
      documentoIdentificacao: await sendDocument('identidade', issue.documentoIdentificacao),
      comprovanteRenda: await sendDocument('comprovanteRenda', issue.comprovanteRenda),
      iptu: await sendDocument('iptu', issue.iptu),
      notaFiscal: await sendDocument('invoice', issue.notaFiscal),
    };

    const isDocumentsUploaded = Object.values(sentDocuments).some((e) => e !== 'Sem documentos');
    const thereIsFile = Object.values(sentDocuments).some((e) => e instanceof File);
    if (!isDocumentsUploaded || thereIsFile) {
      toast.error('Ocorreu um erro ao enviar os arquivos');
      setLoading(false);
      return false;
    }

    const documents = issue;
    documents.notaFiscalEquipamentos = sentDocuments.notaFiscalEquipamentos;
    documents.contaLuzRecente = sentDocuments.contaLuzRecente;
    documents.documentoIdentificacao = sentDocuments.documentoIdentificacao;
    documents.comprovanteRenda = sentDocuments.comprovanteRenda;
    documents.iptu = sentDocuments.iptu;
    documents.notaFiscal = sentDocuments.notaFiscal;

    setIssue(documents);
    setLoading(false);
    return true;
  };

  const submitIssue = async () => {
    setLoading(true);
    try {
      const request = {
        headers: { 'Content-Type': 'application/json' },
        data: {
          projectId,
          assunto: issue.assunto,
          descricao: issue.descricao,
          ownerId: tag,
          notaFiscalEquipamentos: issue.notaFiscalEquipamentos,
          contaLuzRecente: issue.contaLuzRecente,
          documentoIdentificacao: issue.documentoIdentificacao,
          comprovanteRenda: issue.comprovanteRenda,
          iptu: issue.iptu,
          notaFiscal: issue.notaFiscal,
          modifications,
        },
      };
      const response = await api.post('/issue/put', request);

      switch (response.data) {
        case 'ISSUE_CREATED':
          toast.success('Chamado aberto com sucesso!');
          setLoading(false);
          return true;
        case 'DATE_DEPOSIT_NULL':
          toast.error('Não foi possível solicitar alteração de data do depósito pois ela ainda não foi registrada.');
          setLoading(false);
          return false;
        case 'NOTA_FISCAL_NULL':
          toast.error('Não foi possível solicitar alteração da nota fiscal pois ela ainda não foi registrada.');
          setLoading(false);
          return false;
        default:
          toast.error(`Ocorreu um erro ao salvar o chamado.${response.data}`);
          setLoading(false);
          return false;
      }
    } catch ({ message }) {
      toast.error(`Ocorreu um erro ao salvar o chamado ${message}`);
      setLoading(false);
      return false;
    }
  };

  const handleSubmit = async () => {
    if (!validateFields()) return;

    if (haveSubmitableFile()) {
      const filesSubmited = await submitFiles();
      if (!filesSubmited) return;
    }

    const respIssue = await submitIssue();
    if (respIssue) history.push('/ListaChamados');
  };

  const cardStyle = {
    backgroundColor: '#fff',
    boxShadow: '0px 2px 2px 0px #0000001A',
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: '1rem',
    minHeight: 'fit-content',
  };

  return (
    <>
      <DialogComponent isOpen={loading} labelText="Enviando dados" />
      <Box sx={cardStyle}>
        <Grid
          container
          spacing={3}
        >
          <Grid xs={12}>
            <SectionTitle
              title={`Abrir chamado para o projeto #${projectId}`}
              helperText="Preencha o formulário abaixo para abrir um chamado com os gerentes da
               Soltech, para assim, serem efetuadas alterações cadastrais e documentais do projeto,
               cotação e cliente."
            />
          </Grid>
          <Grid xs={12} sx={{ mt: 0, pt: 0 }}>
            <TextField
              error={fieldsError.matter}
              fullWidth
              required
              label="Assunto do chamado"
              variant="outlined"
              value={issue.assunto}
              onChange={handleChangeIssue('assunto')}
              InputLabelProps={{
                shrink: !!issue.assunto,
              }}
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              error={fieldsError.description}
              fullWidth
              required
              label="Descrição do chamado"
              variant="outlined"
              value={issue.descricao}
              onChange={handleChangeIssue('descricao')}
              InputLabelProps={{
                shrink: !!issue.descricao,
              }}
            />
          </Grid>
          <Grid xs={12}>
            <SectionTitle
              title="Solicitação de mudanças"
            />
          </Grid>
          <Grid xs={12} lg={5}>
            <FormControl
              error={fieldsError.codAttribute}
              fullWidth
              required
            >
              <InputLabel id="demo-simple-select-label">Campo a ser alterado</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Campo a ser alterado"
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: '200px',
                    },
                  },
                }}
                value={modification.id || null}
                onChange={(e) => {
                  const selectedId = e.target.value;
                  const selectedForm = formFields.find((field) => field.id === selectedId);
                  if (selectedForm) {
                    setModification({
                      ...modification,
                      ...selectedForm,
                    });
                  }
                }}
              >
                {formFields.map((field) => (
                  <MenuItem
                    key={field.id}
                    value={field.id}
                  >
                    {field.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12} lg={5}>
            <TextField
              error={fieldsError.newValue}
              fullWidth
              required
              label="Novo valor"
              variant="outlined"
              value={modification.newValue}
              onChange={handleChangeMod('newValue')}
              InputLabelProps={{
                shrink: !!modification.newValue,
              }}
            />
          </Grid>
          <Grid xs={12} lg={2} display="flex" alignItems="center">
            <Button
              variant="contained"
              fullWidth
              onClick={handleAddMod}
            >
              Adicionar
            </Button>
          </Grid>
          <Grid xs={12}>
            <Divider variant="secondary" />
            <Typography sx={{
              fontSize: { xs: '12px', md: '16px' },
              fontFamily: 'Poppins',
              fontWeight: '400',
              margin: '1rem 0',
            }}
            >
              Mudanças solicitadas:
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Campo</TableCell>
                    <TableCell>Novo valor</TableCell>
                    <TableCell>Código do campo</TableCell>
                    <TableCell align="right" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {modifications.length > 0 ? modifications.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{row.name}</TableCell>
                      <TableCell>
                        {handleFixFieldValue(row.codAttribute, row.newValue)}
                      </TableCell>
                      <TableCell>{row.codAttribute}</TableCell>
                      <TableCell align="right">
                        <Button variant="outlined" onClick={() => handleRemoveMod(row)}>
                          Remover
                        </Button>
                      </TableCell>
                    </TableRow>
                  )) : (
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell colSpan={4}>Nenhuma modificação solicitada</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid xs={12}>
            <SectionTitle
              title="Enviar documentos"
              helperText="Envie os documentos que você deseja que sejam alterados nos cadastros."
            />
          </Grid>
          <Grid xs={12} lg={2.4}>
            <TextField
              label="Nota fiscal do equipamento"
              fullWidth
              value={isValidUrl(issue.notaFiscalEquipamentos)
                ? 'nota_fiscal_equipamento.pdf' : handleFileName(issue.notaFiscalEquipamentos)}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <>
                      {issue.notaFiscalEquipamentos
                        && issue.notaFiscalEquipamentos !== 'Sem documentos' && (
                        <IconButton
                          component="label"
                          onClick={() => handleRemoveFiles('notaFiscalEquipamentos')}
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      {!(isValidUrl(issue.notaFiscalEquipamentos)) && (
                        <IconButton component="label">
                          <CloudUploadIcon sx={{ color: '#EB5A12' }} />
                          <input
                            type="file"
                            accept=".pdf"
                            hidden
                            onChange={handleChangeIssue('notaFiscalEquipamentos')}
                          />
                        </IconButton>
                      )}
                    </>
                    {isValidUrl(issue.notaFiscalEquipamentos) && (
                      <IconButton
                        onClick={() => (
                          typeof issue.notaFiscalEquipamentos === 'string'
                            ? openPdf(issue.notaFiscalEquipamentos) : {})}
                      >
                        <FileOpenRoundedIcon sx={{ color: '#EB5A12' }} />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: issue.notaFiscalEquipamentos !== 'Sem documentos'
                  ? !!issue.notaFiscalEquipamentos : false,
              }}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid xs={12} lg={2.4}>
            <TextField
              label="Última conta de luz"
              fullWidth
              value={isValidUrl(issue.contaLuzRecente)
                ? 'conta_luz.pdf' : handleFileName(issue.contaLuzRecente)}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    {!(isValidUrl(issue.contaLuzRecente)) && (
                      <>
                        {issue.contaLuzRecente
                          && issue.contaLuzRecente !== 'Sem documentos' && (
                          <IconButton
                            component="label"
                            onClick={() => handleRemoveFiles('contaLuzRecente')}
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                        <IconButton component="label">
                          <CloudUploadIcon sx={{ color: '#EB5A12' }} />
                          <input
                            type="file"
                            accept=".pdf"
                            hidden
                            onChange={handleChangeIssue('contaLuzRecente')}
                          />
                        </IconButton>
                      </>
                    )}
                    {isValidUrl(issue.contaLuzRecente) && (
                      <IconButton
                        onClick={() => (
                          typeof issue.contaLuzRecente === 'string'
                            ? openPdf(issue.contaLuzRecente) : {})}
                      >
                        <FileOpenRoundedIcon sx={{ color: '#EB5A12' }} />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: issue.contaLuzRecente !== 'Sem documentos'
                  ? !!issue.contaLuzRecente : false,
              }}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid xs={12} lg={2.4}>
            <TextField
              label="Documento de identificação"
              fullWidth
              value={isValidUrl(issue.documentoIdentificacao)
                ? 'id.pdf' : handleFileName(issue.documentoIdentificacao)}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    {!(isValidUrl(issue.documentoIdentificacao)) && (
                      <>
                        {issue.documentoIdentificacao
                          && issue.documentoIdentificacao !== 'Sem documentos' && (
                          <IconButton
                            component="label"
                            onClick={() => handleRemoveFiles('documentoIdentificacao')}
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                        <IconButton component="label">
                          <CloudUploadIcon sx={{ color: '#EB5A12' }} />
                          <input
                            type="file"
                            accept=".pdf"
                            hidden
                            onChange={handleChangeIssue('documentoIdentificacao')}
                          />
                        </IconButton>
                      </>
                    )}
                    {isValidUrl(issue.documentoIdentificacao) && (
                      <IconButton
                        onClick={() => (
                          typeof issue.documentoIdentificacao === 'string'
                            ? openPdf(issue.documentoIdentificacao) : {})}
                      >
                        <FileOpenRoundedIcon sx={{ color: '#EB5A12' }} />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: issue.documentoIdentificacao !== 'Sem documentos'
                  ? !!issue.documentoIdentificacao : false,
              }}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid xs={12} lg={2.4}>
            <TextField
              label="Comprovante de renda"
              fullWidth
              value={isValidUrl(issue.comprovanteRenda)
                ? 'comprovante_renda.pdf' : handleFileName(issue.comprovanteRenda)}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    {!(isValidUrl(issue.comprovanteRenda)) && (
                      <>
                        {issue.comprovanteRenda
                          && issue.comprovanteRenda !== 'Sem documentos' && (
                          <IconButton
                            component="label"
                            onClick={() => handleRemoveFiles('comprovanteRenda')}
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                        <IconButton component="label">
                          <CloudUploadIcon sx={{ color: '#EB5A12' }} />
                          <input
                            type="file"
                            accept=".pdf"
                            hidden
                            onChange={handleChangeIssue('comprovanteRenda')}
                          />
                        </IconButton>
                      </>
                    )}
                    {isValidUrl(issue.comprovanteRenda) && (
                      <IconButton
                        onClick={() => (
                          typeof issue.comprovanteRenda === 'string'
                            ? openPdf(issue.comprovanteRenda) : {})}
                      >
                        <FileOpenRoundedIcon sx={{ color: '#EB5A12' }} />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: issue.comprovanteRenda !== 'Sem documentos'
                  ? !!issue.comprovanteRenda : false,
              }}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid xs={12} lg={2.4}>
            <TextField
              label="IPTU"
              fullWidth
              value={isValidUrl(issue.iptu)
                ? 'iptu.pdf' : handleFileName(issue.iptu)}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    {!(isValidUrl(issue.iptu)) && (
                      <>
                        {issue.iptu && issue.iptu !== 'Sem documentos' && (
                          <IconButton
                            component="label"
                            onClick={() => handleRemoveFiles('iptu')}
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                        <IconButton component="label">
                          <CloudUploadIcon sx={{ color: '#EB5A12' }} />
                          <input
                            type="file"
                            accept=".pdf"
                            hidden
                            onChange={handleChangeIssue('iptu')}
                          />
                        </IconButton>
                      </>
                    )}
                    {isValidUrl(issue.iptu) && (
                      <IconButton
                        onClick={() => (
                          typeof issue.iptu === 'string'
                            ? openPdf(issue.iptu) : {})}
                      >
                        <FileOpenRoundedIcon sx={{ color: '#EB5A12' }} />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: issue.iptu !== 'Sem documentos'
                  ? !!issue.iptu : false,
              }}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid xs={12} lg={2.4}>
            <TextField
              label="Nota fiscal"
              fullWidth
              value={isValidUrl(issue.notaFiscal)
                ? 'notaFiscal.pdf' : handleFileName(issue.notaFiscal)}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    {!(isValidUrl(issue.notaFiscal)) && (
                      <>
                        {issue.notaFiscal && issue.notaFiscal !== 'Sem documentos' && (
                          <IconButton
                            component="label"
                            onClick={() => handleRemoveFiles('notaFiscal')}
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                        <IconButton component="label">
                          <CloudUploadIcon sx={{ color: '#EB5A12' }} />
                          <input
                            type="file"
                            accept=".pdf"
                            hidden
                            onChange={handleChangeIssue('notaFiscal')}
                          />
                        </IconButton>
                      </>
                    )}
                    {isValidUrl(issue.notaFiscal) && (
                      <IconButton
                        onClick={() => (
                          typeof issue.notaFiscal === 'string'
                            ? openPdf(issue.notaFiscal) : {})}
                      >
                        <FileOpenRoundedIcon sx={{ color: '#EB5A12' }} />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: issue.notaFiscal !== 'Sem documentos'
                  ? !!issue.notaFiscal : false,
              }}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid xs={12}>
            <Divider variant="primary" />
          </Grid>
          <Grid xs={12} display="flex" justifyContent="flex-end" gap="1rem">
            <Button variant="outlined" sx={{ width: '10rem' }} onClick={handleCancel}>Cancelar</Button>
            <Button variant="contained" sx={{ width: '10rem' }} onClick={handleSubmit}>Salvar</Button>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </>
  );
}
