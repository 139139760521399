import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, useState } from 'react';

interface Props {
  isOpen: boolean;
  text: string;
  confirmDialog: Function;
  setDialogClose: Function;
}

const ConfirmDialogComponent = (props: Props) => {
  const {
    isOpen, confirmDialog, text, setDialogClose,
  } = props;
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  useEffect(() => {
    setIsOpenDialog(isOpen);
  }, [isOpen]);
  const confirmDialogProp = () => {
    confirmDialog();
  };
  const handleClose = () => {
    setDialogClose();
  };

  return (
    <div>
      <Dialog
        open={isOpenDialog}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-paper': {
            padding: '1rem',
            borderRadius: '1rem',
          },
        }}
      >
        <DialogTitle sx={{
          fontSize: { xs: '16px', md: '24px' },
          fontFamily: 'Poppins',
          color: '#EB5A12',
          fontWeight: '700',
        }}
        >
          Você tem certeza?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            sx={{
              width: '10rem',
              borderRadius: '2rem',
              borderColor: '#EB5A12',
              color: '#EB5A12',
              marginBottom: '0.5rem',
              marginTop: '1rem',
              '&:hover': {
                borderColor: '#EB5A12',
                backgroundColor: 'rgba(255,127,80,0.2)',
              },
            }}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            sx={{
              width: '10rem',
              borderRadius: '2rem',
              backgroundColor: '#EB5A12',
              marginBottom: '0.5rem',
              marginTop: '1rem',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#FF7F50',
              },
            }}
            onClick={confirmDialogProp}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export { ConfirmDialogComponent };
