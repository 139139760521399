import React, {
  ChangeEvent, SyntheticEvent, useRef, useState,
} from 'react';

// toastfy
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { useHistory } from 'react-router-dom';
import { parseCookies } from 'nookies';
import style from './cadastrogerente.module.scss';
import { onlyLetters } from '../../utils/util';

import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { Navigator } from '../../components/Navigator';
import { FormPage } from '../../components/FormPage';
import { RequiredFieldMark } from '../../components/RequiredFieldMark';

import { Baloon } from '../../components/Baloon';
import { api } from '../../services/api';
import { DialogComponent } from '../../components/DialogComponent';

export function CadastroGerente() {
  const emailMask = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const cpfMask = /[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}/;
  const cnpjMask = /[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}-?[0-9]{2}/;

  const { 'nextauth.tag': tag } = parseCookies();
  const op = 'Gerente';

  const ref = useRef<LoadingBarRef>(null);

  const [errorMessage, setErrorMessage] = useState<string[]>([]);

  const [emailValue, setEmailValue] = useState('');
  const [nomeValue, setNomeValue] = useState('');
  const [cpfValue, setCpfValue] = useState('');
  const [telefoneValue, setTelefoneValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  //= ==========================================================================
  //= ==========================================================================
  // FIELD VALIDATION
  //= ==========================================================================
  //= ==========================================================================
  const validateTelefone = (t: string) => t.length === 15;

  const validateCpf = (cpf: string) => cpfMask.test(cpf);

  const validatePassword = (password: string) => {
    if (password.length < 8) {
      return false;
    }

    // @TODO detect upper case
    // @TODO detect lower cases
    // @TODO detect numerics
    // @TODO detect symbols

    return true;
  };

  const validateEmail = (m: string) => emailMask.test(m);

  const validateNome = (n: string) => n.length > 10;

  //= ==========================================================================
  //= ==========================================================================
  // FORM VALIDATION
  //= ==========================================================================
  //= ==========================================================================
  const validateForm = () => {
    const messages: any[] = [];

    if (!validateCpf(cpfValue)) {
      messages.push('O campo "CPF" está formatado incorretamente');
    }

    if (!validateNome(nomeValue)) {
      messages.push('O campo "nome" está formatado incorretamente');
    }

    if (!validateTelefone(telefoneValue)) {
      messages.push('O campo "telefone" está formatado incorretamente');
    }

    if (!validatePassword(passwordValue)) {
      messages.push('O campo "senha" está formatado incorretamente');
    }

    if (!validateEmail(emailValue)) {
      messages.push('O campo "e-mail" está formatado incorretamente');
    }

    setErrorMessage(messages);
    return messages.length === 0;
  };

  /**
   * handleSubmit
   * Submits form to the backend.
   * @param event
   */
  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (validateForm()) {
      setLoadingText('Cadastrando gerente...');
      setIsOpen(true);
      const request = {
        headers: { 'Content-Type': 'application/json' },
        data: {
          op,
          emailValue,
          nomeValue,
          cpfValue,
          telefoneValue,
          passwordValue,
          operadorId: tag,
        },
      };

      const response = await api.post('user/insert', request);

      switch (response.data) {
        case 'EMAIL_REGISTERED_ALREADY':
          setIsOpen(false);
          toast.warn(
            'E-mail já cadastrado no sistema. Não foi possível inserir usuário.',
          );
          break;

        case 'CREATED_OK':
          setIsOpen(false);
          toast.success('Usuário inserido com sucesso!');
          window.location.reload();
          break;
        default:
          setIsOpen(false);
          toast.error(`Falha ao inserir usuário no sistema.${response.data}`);
          break;
      }
    } else {
      toast.warn('Alguns campos não estão preenchidos corretamente.');
    }
  };

  //= ==========================================================================
  //= ==========================================================================
  // ONCHANGE EVENTS
  //= ==========================================================================
  //= ==========================================================================
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailValue(e.target.value);
  };

  const handleNomeChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = onlyLetters(e);
    setNomeValue(e.target.value);
  };

  const handleCpfChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    for (let i = 0; i < e.target.value.length; ++i) {
      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    // apply the "___.___.___-__" mask
    let newValue = '';

    for (let i = 0; i < value.length; ++i) {
      newValue += value[i];
      if (i === 2 || i === 5) {
        newValue += '.';
      }
      if (i === 8) {
        newValue += '-';
      }
    }

    // forcefully limit characters count
    if (newValue.length > 14) {
      newValue = newValue.substring(0, 14);
    }

    e.target.value = newValue;
    setCpfValue(newValue);
  };

  const handleTelefoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    for (let i = 0; i < e.target.value.length; ++i) {
      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    // apply the "(__)_____-____" mask
    let newValue = '';

    for (let i = 0; i < value.length; ++i) {
      if (i === 0) {
        newValue += '(';
      }
      if (i === 2) {
        newValue += ') ';
      }
      if (i === 7) {
        newValue += '-';
      }

      newValue += value[i];
    }

    // forcefully limit characters count
    if (newValue.length > 15) {
      newValue = newValue.substring(0, 15);
    }

    e.target.value = newValue;
    setTelefoneValue(e.target.value);
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPasswordValue(e.target.value);
  };

  //= ==========================================================================
  //= ==========================================================================
  //
  //= ==========================================================================
  //= ==========================================================================
  const instrucoes = 'Preencha o formulário abaixo para cadastrar um novo gerente no sistema. '
    + 'Gerentes podem aceitar cadastros de integradoras, além de possuírem acesso a funções privilegiadas '
    + 'do sistema, assim como os operadores, entretanto, gerentes, podem cadastrar, ativar ou desativar os operadores.';

  return (
    <div>
      <LoadingBar color="#f11946" ref={ref} />
      <FormPage>
        <br />
        <form onSubmit={handleSubmit}>
          <h1>Cadastro de Gerente</h1>
          <Baloon mode="clean">{instrucoes}</Baloon>
          {errorMessage.length > 0 && (
            <Baloon mode="warning">
              <ul>
                {errorMessage.map((m) => (
                  <li key={m}>{m}</li>
                ))}
              </ul>
            </Baloon>
          )}
          <div>
            <label>
              Nome Completo
              <RequiredFieldMark />
              <input
                type="text"
                id="f_nome"
                className={style.f_long_field}
                placeholder="Nome Completo"
                onChange={handleNomeChange}
              />
            </label>
            <label>
              E-mail
              <RequiredFieldMark />
              <input
                type="text"
                id="f_email"
                className={style.f_long_field}
                placeholder="seuemail@email.com"
                onChange={handleEmailChange}
                value={emailValue}
              />
            </label>
            <label>
              CPF
              <RequiredFieldMark />
              <input
                type="text"
                id="f_cpf"
                onChange={handleCpfChange}
                placeholder="000.000.000-00"
              />
            </label>
            <label>
              Telefone
              <RequiredFieldMark />
              <input
                type="text"
                onChange={handleTelefoneChange}
                id="f_telefone"
                placeholder="(00) 00000-0000"
              />
            </label>
            <br />
            <br />
            <br />
            <label>
              Senha de Acesso (primeiro acesso)
              <RequiredFieldMark />
              <input
                id="f_senha"
                type="password"
                onChange={handlePasswordChange}
                placeholder=""
              />
            </label>
          </div>
          <br />
          <br />
          <input type="submit" value="Continuar" />
        </form>
        <br />
        <ToastContainer />
      </FormPage>
      <DialogComponent isOpen={isOpen} labelText={loadingText} />
    </div>
  );
}
