export const projectStatusList = [
  'Cadastro',
  'Inativo',
  'Análise - Soltech',
  'Análise - Bancos',
  'Financiamento recusado',
  'Financiamento aprovado',
  'Aguardando escolha das condições',
  'Aguardando documentos',
  'Análise - Documentos',
  'Aguardando NF',
  'Condições escolhidas',
  'Documentos recusados',
  'Projeto contratado',
];

export const integratorStatusList = [
  'Ativo',
  'Pendente',
  'Recusado',
  'Sem documentos',
];
