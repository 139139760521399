import { api } from '../services/api';

export const sendFile = async (label: string, file: any) => {
  const body = new FormData();
  body.append('filefield', file, label);

  const resp = await api.post('upload', body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return resp.data;
};
