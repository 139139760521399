import Modal from 'react-modal';
import React from 'react';
import style from './dialog.module.scss';
import { LoadingIcon } from '../LoadingIcon';

interface Props {
  isOpen: boolean;
  labelText: string;
}

const DialogComponent = (props: Props) => {
  const {
    isOpen, labelText,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      className={style.Modal}
      contentLabel="{labelText}"
      style={{ overlay: { zIndex: 50 } }}
    >
      <div className={style.dialog}>
        <LoadingIcon active={isOpen} />
      </div>
      <div className={style.dialogText}>{labelText}</div>
    </Modal>
  );
};

export { DialogComponent };
