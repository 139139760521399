import React, {
  ChangeEvent, useRef, useState,
} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { parseCookies } from 'nookies';
import {
  Box,
  Button, FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router-dom';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import style from './cadastrooperador.module.scss';
import { api } from '../../services/api';
import { DialogComponent } from '../../components/DialogComponent';
import { Operator } from '../../contexts/@types';
import { defaultOperator } from '../../contexts/@defaultValues';

export type OperatorError = {
  name: boolean,
  email: boolean,
  cpf: boolean,
  phone: boolean,
  password: boolean,
}

export function CadastroOperador() {
  const [passwordShown, setPasswordShown] = useState(false);
  const history = useHistory();
  const ref = useRef<LoadingBarRef>(null);
  const { 'nextauth.tag': tag } = parseCookies();
  const op = 'Operador';

  const [isOpen, setIsOpen] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  const [operator, setOperator] = useState<Operator>(defaultOperator);
  const [fieldsError, setFieldsError] = useState<OperatorError>({
    name: false,
    email: false,
    cpf: false,
    phone: false,
    password: false,
  });

  const handleChange = (prop: keyof Operator) => (event: ChangeEvent<HTMLInputElement>) => {
    setOperator({ ...operator, [prop]: event.target.value });
  };

  const validateOperatorForm = async () => {
    const errors = {
      name: !operator.name,
      email: !operator.email,
      cpf: !operator.cpf || operator.cpf.includes('_'),
      phone: !operator.phone || operator.phone.includes('_'),
      password: !operator.password,
    };

    setFieldsError(errors);

    const fieldNames = {
      name: 'Nome completo',
      email: 'E-mail',
      cpf: 'CPF',
      phone: 'Telefone',
      password: 'Senha de acesso',
    };

    const emptyFields = Object.keys(errors)
      .filter((key) => errors[key as keyof typeof errors])
      .map((key) => fieldNames[key as keyof typeof fieldNames])
      .join(';; ');

    if (emptyFields.length > 0) {
      toast.error(
        <>
          O(s) campo(s) a seguir devem ser preenchidos:
          {emptyFields.split('; ').map((e) => (
            <React.Fragment key={e}>
              <br />
              {e}
            </React.Fragment>
          ))}
        </>,
      );

      return true;
    }
    return false;
  };

  const handleSubmitOperator = async () => {
    if (await validateOperatorForm()) return;

    setLoadingText('Cadastrando operador...');
    setIsOpen(true);
    const request = {
      headers: { 'Content-Type': 'application/json' },
      data: {
        op,
        emailValue: operator.email,
        nomeValue: operator.name,
        cpfValue: operator.cpf,
        telefoneValue: operator.phone,
        passwordValue: operator.password,
        operadorId: tag,
      },
    };

    const response = await api.post('user/insert', request);

    switch (response.data) {
      case 'EMAIL_REGISTERED_ALREADY':
        setIsOpen(false);
        toast.error(
          'E-mail já cadastrado no sistema.',
        );
        break;

      case 'CREATED_OK':
        setIsOpen(false);
        toast.success('Operador cadastrado com sucesso.');
        history.push('/ListaOperador');
        break;
      default:
        setIsOpen(false);
        toast.error(`Falha ao inserir usuário no sistema.${response.data}`);
        break;
    }
  };

  const handleCancel = () => {
    history.push('/ListaOperador');
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const cardStyle = {
    minHeight: '100%',
    backgroundColor: '#fff',
    boxShadow: '0px 2px 2px 0px #0000001A',
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: '0.5rem 1.5rem',
    gap: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };
  return (
    <>
      <LoadingBar color="#f11946" ref={ref} />
      <Box sx={cardStyle}>
        <Grid container spacing={2} rowSpacing={2}>
          <Grid xs={12}>
            <Typography sx={{
              fontSize: { xs: '16px', md: '24px' },
              fontFamily: 'Poppins',
              color: '#EB5A12',
              fontWeight: '700',
            }}
            >
              Cadastro de operador
            </Typography>
            <hr className={style.titleDivisor} />
            <Typography sx={{
              fontSize: {
                xs: '12px',
                md: '16px',
              },
              fontFamily: 'Poppins',
              fontWeight: '400',
            }}
            >
              Preencha o formulário abaixo para cadastrar um novo operador no sistema.
              Operadores podem aceitar cadastros de integradoras, além de possuírem acesso a
              funções privilegiadas do sistema.
            </Typography>
          </Grid>
          <Grid xs={12} lg={3}>
            <TextField
              error={fieldsError.name}
              fullWidth
              required
              label="Nome completo"
              variant="outlined"
              onChange={handleChange('name')}
            />
          </Grid>
          <Grid xs={12} lg={3}>
            <TextField
              error={fieldsError.email}
              fullWidth
              required
              label="E-mail"
              variant="outlined"
              onChange={handleChange('email')}
            />
          </Grid>
          <Grid xs={12} lg={3}>
            <InputMask
              mask="999.999.999-99"
              maskPlaceholder="_"
              onChange={handleChange('cpf')}
            >
              <TextField
                error={fieldsError.cpf}
                fullWidth
                required
                label="CPF"
                variant="outlined"
              />
            </InputMask>
          </Grid>
          <Grid xs={12} lg={3}>
            <InputMask
              mask="(99) 9 9999-9999"
              maskPlaceholder="_"
              onChange={handleChange('phone')}
            >
              <TextField
                error={fieldsError.phone}
                fullWidth
                required
                label="Telefone"
                variant="outlined"
              />
            </InputMask>
          </Grid>
          <Grid xs={12} lg={3}>
            <FormControl variant="outlined" fullWidth required error={fieldsError.password}>
              <InputLabel htmlFor="outlined-adornment-password">Senha de acesso (primeiro acesso)</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={passwordShown ? 'text' : 'password'}
                onChange={handleChange('password')}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePassword}
                    >
                      {passwordShown ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                    </IconButton>
                  </InputAdornment>
                )}
                label="Senha de acesso (primeiro acesso)"
              />
            </FormControl>
          </Grid>
        </Grid>
        <div>
          <hr className={style.bottomDivisor} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              gap: '1rem',
            }}
          >
            <Button
              variant="outlined"
              onClick={() => handleCancel()}
              sx={{
                width: '10rem',
                borderRadius: '2rem',
                borderColor: '#EB5A12',
                color: '#EB5A12',
                marginBottom: '0.5rem',
                marginTop: '1rem',
                textTransform: 'none',
                '&:hover': {
                  borderColor: '#EB5A12',
                  backgroundColor: 'rgba(255,127,80,0.2)',
                },
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={() => handleSubmitOperator()}
              sx={{
                width: '10rem',
                borderRadius: '2rem',
                backgroundColor: '#EB5A12',
                marginBottom: '0.5rem',
                marginTop: '1rem',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#FF7F50',
                },
              }}
            >
              Cadastrar
            </Button>
          </Box>
        </div>
      </Box>
      <ToastContainer />
      <DialogComponent isOpen={isOpen} labelText={loadingText} />
    </>
  );

  // return (
  //   <div>
  //     <LoadingBar color="#f11946" ref={ref} />
  //     <FormPage>
  //       <br />
  //       <form onSubmit={handleSubmit}>
  //         <h1>Cadastro de Operador</h1>
  //         <Baloon mode="clean">{instrucoes}</Baloon>
  //         {errorMessage.length > 0 && (
  //           <Baloon mode="warning">
  //             <ul>
  //               {errorMessage.map((m) => (
  //                 <li key={m}>{m}</li>
  //               ))}
  //             </ul>
  //           </Baloon>
  //         )}
  //         <div>
  //           <label>
  //             Nome Completo
  //             <RequiredFieldMark />
  //             <input
  //               type="text"
  //               id="f_nome"
  //               className={style.f_long_field}
  //               placeholder="Nome Completo"
  //               onChange={handleNomeChange}
  //             />
  //           </label>
  //           <label>
  //             E-mail
  //             <RequiredFieldMark />
  //             <input
  //               type="text"
  //               id="f_email"
  //               className={style.f_long_field}
  //               placeholder="seuemail@email.com"
  //               onChange={handleEmailChange}
  //               value={emailValue}
  //             />
  //           </label>
  //           <label>
  //             CPF
  //             <RequiredFieldMark />
  //             <input
  //               type="text"
  //               id="f_cpf"
  //               onChange={handleCpfChange}
  //               placeholder="000.000.000-00"
  //             />
  //           </label>
  //           <label>
  //             Telefone
  //             <RequiredFieldMark />
  //             <input
  //               type="text"
  //               onChange={handleTelefoneChange}
  //               id="f_telefone"
  //               placeholder="(00) 00000-0000"
  //             />
  //           </label>
  //           <br />
  //           <br />
  //           <br />
  //           <label>
  //             Senha de Acesso (primeiro acesso)
  //             <RequiredFieldMark />
  //             <input
  //               id="f_senha"
  //               type="password"
  //               onChange={handlePasswordChange}
  //               placeholder=""
  //             />
  //           </label>
  //         </div>
  //         <br />
  //         <br />
  //         <input type="submit" value="Continuar" />
  //       </form>
  //       <br />
  //       <ToastContainer />
  //     </FormPage>
  //     <DialogComponent isOpen={isOpen} labelText={loadingText} />
  //   </div>
  // );
}
