import { createContext, useContext } from 'react';
import Pusher from 'pusher-js';

const PusherContext = createContext(Pusher.instances[0]);

// @ts-ignore
// eslint-disable-next-line react/prop-types
function PusherProvider({ pusher, children }) {
  return (
    // @ts-ignore
    <PusherContext.Provider value={{ pusher }}>
      {children}
    </PusherContext.Provider>
  );
}

function usePusher() {
  const context = useContext(PusherContext);
  if (!context) {
    throw new Error('usePusher must be used within a PusherProvider');
  }

  // @ts-ignore
  return context.pusher;
}

export { PusherProvider, usePusher };
