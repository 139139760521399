import { useHistory } from 'react-router-dom';
import style from './associateeditor.module.scss';

interface Associate{
  name?: string;
  motherName?: string;
  phone?: string;
  cpf?: string;
}

interface Props {
  data: Associate;
  onClickParam?: Function;
  idx: number;
  showRemove: boolean;
}

const AssociateEditor = (props: Props) => {
  const {
    data, onClickParam, idx, showRemove,
  } = props;

  const {
    name, motherName, phone, cpf,
  } = data;

  return (
    <div className={style.listaCadastroItemClass}>
      <table>
        <tbody>
          <tr>
            <td style={{ width: '35%' }}>
              <span>Sócio</span>
              <br />
              #
              {name}
            </td>
            <td style={{ width: '25%' }}>
              <span>CPF</span>
              <br />
              {cpf}
            </td>
            <td className={style.cnpjCelula}>
              <span>Nome da Mãe</span>
              <br />
              {motherName}
            </td>
            <td className={style.telefoneCelula} style={{ width: '15%' }}>
              <span>Telefone</span>
              <br />
              {phone}
            </td>
            <td style={{ width: '70px', display: (showRemove ? 'block' : 'none') }}>
              <button type="button" style={{ width: '70px' }} onClick={onClickParam ? () => onClickParam(idx) : () => ''}>Remover</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export { AssociateEditor };
