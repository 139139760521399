import {
  Box,
  Paper, PaperTypeMap,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import { Associate } from '../../contexts/@types';

interface Props {
  associates: Associate[]
}
const Index = (props: Props) => {
  const { associates } = props;
  return (
    <Box
      sx={{ overflow: 'auto' }}
    >
      <TableContainer
        sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}
      >
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Nome do sócio</TableCell>
              <TableCell>CPF</TableCell>
              <TableCell>Nome da mãe</TableCell>
              <TableCell>Telefone</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {(associates && associates.length > 0) && (associates.map((associateItem) => (
              <TableRow
                key={associateItem.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{associateItem.name}</TableCell>
                <TableCell>{associateItem.cpf}</TableCell>
                <TableCell>{associateItem.motherName}</TableCell>
                <TableCell>{associateItem.phone}</TableCell>
              </TableRow>
            )))}
            {(!associates || associates.length === 0) && (associates.map((associateItem) => (
              <TableRow
                key={associateItem.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{associateItem.name}</TableCell>
                <TableCell>{associateItem.cpf}</TableCell>
                <TableCell>{associateItem.motherName}</TableCell>
                <TableCell>{associateItem.phone}</TableCell>
              </TableRow>
            )))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export { Index };
