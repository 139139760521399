import React, { useEffect, useState } from 'react';
import {
  Box, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import { parseCookies } from 'nookies';
import { AxiosResponse } from 'axios';
import { DialogComponent } from '../../components/DialogComponent';
import style from '../DocumentosIntegradora/documentosintegradora.module.scss';
import { api } from '../../services/api';
import BarCharts from '../../components/DashboardCharts/BarCharts';
import Donut, { defaultDonutData, DonutData } from '../../components/DashboardCharts/Donut';
import BottomCharts, {
  BottomCardsProps,
  defaultBottomCardsProps,
} from '../../components/DashboardCharts/BottomCharts';
import { applyCurrencyMask } from '../../utils/util';

interface VendorData {
  name: string
  email: string
}

function DashboardVendedor() {
  const [vendorData, setVendorData] = useState<VendorData>();
  const [donutData, setDonutData] = useState<DonutData>(defaultDonutData);
  const [bottomCardsData, setBottomCardsData] = useState<BottomCardsProps>(defaultBottomCardsProps);
  const [isOpen, setIsOpen] = useState(false);
  const loadingText = 'Carregando dados...';
  const { 'nextauth.tag': tag } = parseCookies();

  useEffect(() => {
    const getVendorData = async () => {
      const response: Promise<AxiosResponse<any, any>> = api.post(
        '/user/get',
        { userId: Number(tag) },
      );
      response.then((resolved) => {
        const { data } = resolved;
        setVendorData(data);
      });
    };
    getVendorData();
    // If Use-effect is changed run without the disabled eslint to check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getProjectData = async () => {
      setIsOpen(true);
      const response: Promise<AxiosResponse<any, any>> = api.post(
        'user/vendor/data/get',
        { userId: tag },
      );
      response.then((e) => {
        const { data } = e;

        const donutDataRecover: DonutData = {
          projectValue: data.projectsValue,
          totalValueMonth: applyCurrencyMask(data.totalValueMonth),
          totalProjectsMonth: data.totalProjectsMonth,
          monthProjectsDifference: data.monthProjectsDifference,
          monthDifference: applyCurrencyMask(data.monthDifference),
        };
        setDonutData(donutDataRecover);

        const cardsDataRecover = {
          totalProjects: data.totalProjects,
          approvedFinancialProjectsMonth: data.approvedFinancialProjectsMonth,
          totalProjectsValue: data.totalProjectsValue,
          totalAprovePower: data.totalAprovePower,
        };
        setBottomCardsData({ ...bottomCardsData, ...cardsDataRecover });

        setIsOpen(false);
      });
    };
    getProjectData();
    // If Use-effect is changed run without the disabled eslint to check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag]);

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Grid
        container
        spacing={2}
        rowSpacing={2}
      >
        <Grid xs={12}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', md: 'row' },
            gap: { md: '4rem' },
          }}
          >
            <h5>
              {vendorData?.name}
            </h5>
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              <EmailRoundedIcon sx={{ color: '#EB5A12' }} />
              <Typography sx={{
                fontFamily: 'Poppins', fontSize: { xs: '12px', md: '16px' }, color: '#6F7481',
              }}
              >
                {
                  vendorData?.email
                }
              </Typography>
            </Box>
          </Box>
          {!isMobileScreen && <hr className={style.dashboardDivisor} />}
        </Grid>
        <Grid container sx={{ gap: { xs: '1rem', lg: '0' } }}>
          <Grid
            container
            xs={12}
            lg={7.99}
            sx={{
              display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
            }}
            order={{ xs: 2, lg: 1 }}
          >
            <Grid xs={12}>
              <BarCharts setIsOpen={setIsOpen} />
            </Grid>
            <Grid xs={12}>
              <BottomCharts bottomCardsData={bottomCardsData} />
            </Grid>
          </Grid>
          <Grid
            xs={12}
            lg={4}
            order={{ xs: 1, lg: 2 }}
            container
          >
            <Donut donutData={donutData} />
          </Grid>
        </Grid>
      </Grid>
      <DialogComponent isOpen={isOpen} labelText={loadingText} />
    </>
  );
}
export default DashboardVendedor;
