import { Box, Typography } from '@mui/material';
import React from 'react';

interface Props {
  title: string,
  data: string | number | null,
}

const DataDisplay = (props: Props) => {
  const { title, data } = props;

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Typography sx={{
        fontSize: { xs: '16px' },
        fontFamily: 'Poppins',
        color: '#19171C',
        fontWeight: '700',
      }}
      >
        { title }
      </Typography>
      <Typography sx={{
        fontSize: { xs: '16px' },
        fontFamily: 'Poppins',
        color: '#19171C',
        fontWeight: '400',
      }}
      >
        { data }
      </Typography>
    </Box>
  );
};

export { DataDisplay };
