import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import { FormHelperText, styled } from '@mui/material';
import { applyCurrencyMask } from '../../utils/util';

const iOSBoxShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

interface SingleBarGraphProps {
  title: string
  kWpCost: number
  reference: {
    value: number,
    label: string
  }
  width?: string
}

export default function SingleBarGraph(props: SingleBarGraphProps) {
  const {
    title, kWpCost, reference, width,
  }: SingleBarGraphProps = props;

  const formatCost = (str: number) => {
    const value = String(str);
    const len = value.length;
    if (len < 2) {
      return str;
    }
    return Number(`${value.slice(0, len - 2)}.${value.slice(len - 2)}`);
  };

  const cost = formatCost(kWpCost) / 1000;

  // Defina a porcentagem desejada para calcular o intervalo verde
  const desiredPercentage = 20;

  // Calcule a porcentagem do valor de referência em relação ao valor máximo
  const referenceValuePercentage = (reference.value / (cost > 10 ? cost : 10)) * 100;

  // Calcule a porcentagem desejada do valor de referência
  const limitOfReference = (reference.value * desiredPercentage) / 100;
  const percentageOfReferenceValue = (limitOfReference / (cost > 10 ? cost : 10)) * 100;

  // Calcule onde a cor verde deve começar e terminar
  const greenStart = referenceValuePercentage - percentageOfReferenceValue;
  const greenEnd = referenceValuePercentage + percentageOfReferenceValue;

  const IOSSlider = styled(Slider)(({ theme }) => ({
    color: theme.palette.mode === 'dark' ? '#000' : '#fff',
    height: 7,
    padding: '15px 0',
    cursor: 'default',
    '& .MuiSlider-mark': {
      height: 15,
      border: 'solid 1px rgba(0, 0, 0, 0.2)',
      borderRadius: '50rem',
    },
    '& .MuiSlider-thumb': {
      height: 15,
      width: 15,
      backgroundColor: '#fff',
      boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.1)',
      '&:focus, &:hover, &.Mui-active': {
        boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.1)',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          boxShadow: iOSBoxShadow,
        },
      },
      '&:before': {
        boxShadow:
          '0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)',
      },
    },
    '& .MuiSlider-valueLabel': {
      fontSize: 12,
      fontWeight: 'normal',
      top: -6,
      backgroundColor: 'unset',
      color: theme.palette.text.primary,
      '&::before': {
        display: 'none',
      },
      '& *': {
        background: 'transparent',
        color: theme.palette.mode === 'dark' ? '#fff' : '#000',
      },
    },
    '& .MuiSlider-track': {
      border: 'none',
      height: 5,
      width: '100%',
    },
    '& .MuiSlider-rail': {
      background: `linear-gradient(95deg, #ff0000 0%, #ff0000 ${greenStart - 10}%,
      #008000 ${greenStart}%, #008000 ${greenEnd}%, #ff0000 ${greenEnd + 10}%, #ff0000 100%)`,
      opacity: 1,
    },
    // '& .MuiSlider-rail': {
    //   background: `linear-gradient(95deg, #ff0000 0%, #008000 ${greenStart}%,
    //   #008000 ${greenEnd}%, #ff0000 100%)`,
    //   opacity: 1,
    // },
    // '& .MuiSlider-rail': {
    //   background: `linear-gradient(95deg, #ff0000 0%, #ff0000 ${greenStart}%,
    //   #008000 ${greenStart}%, #008000 ${greenEnd}%, #ff0000 ${greenEnd}%, #ff0000 100%)`,
    //   opacity: 1,
    // },
  }));

  return (
    <Box display="flex" flexDirection="column" gap="2rem" width={width}>
      <Box>
        <Typography
          id="track-false-range-slider"
          sx={{
            fontSize: { xs: '16px' },
            fontFamily: 'Poppins',
            color: '#19171C',
            fontWeight: '700',
          }}
        >
          { title }
        </Typography>
        <FormHelperText>
          Valor de referência em comparação ao histórico de preços da região
        </FormHelperText>
      </Box>
      <Box m="0 1rem">
        <IOSSlider
          aria-labelledby="track-false-range-slider"
          track={false}
          valueLabelDisplay="on"
          marks={[{ value: 0, label: 'R$ 0,00' }, reference, { value: 10, label: 'R$ 10,00' }]}
          value={cost}
          valueLabelFormat={(value) => `R$/Wp do projeto ${applyCurrencyMask(String(value.toFixed(2)).replace(/\./, ''))}`}
          min={0}
          max={cost > 10 ? cost : 10}
        />
      </Box>
    </Box>
  );
}
