import React, {
} from 'react';
import { ToastContainer } from 'react-toastify';
import { Link, Typography } from '@mui/material';
import style from '../DocumentosIntegradora/documentosintegradora.module.scss';

export function CadastroIntegradoraFinalizado() {
  return (
    <>
      <form>
        <h4 className={style.title}>Documentos da Integradora</h4>
        <hr className={style.divisor} />
        <h4>Em breve, você receberá nosso email!</h4>
        <Typography sx={{ marginBottom: '1rem', fontSize: { xs: '12px', md: '16px' }, fontFamily: 'Poppins' }}>
          Caso você não tenha recebido nosso email,
          entre em contato com a equipe da Soltech&reg;.
        </Typography>
        <br />
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link href="https://usesoltech.com.br/contato/">https://usesoltech.com.br/contato/</Link>
      </form>
      <ToastContainer />
    </>
  );
}
