import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import {
  Box, Button, MenuItem, Select, TextField, ThemeProvider, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { secondaryButton } from '../../themes/buttonTheme';

interface Anexos {
  tipoAnexo: string,
  conteudoBase64: string,
  nomeArquivo: string,
  extensao: string
}

interface Props {
  anexosList: Anexos[]
  changeFile: Function
  deleteFile: Function
}

export const OrderFileTable = (props: Props) => {
  const { anexosList, changeFile, deleteFile } = props;

  const [selectedValue, setSelectedValue] = useState('Documento');

  const fileOptions = [
    { value: 'Documento', label: 'Documento' },
    { value: 'Termo de adesão', label: 'Termo de adesão' },
    { value: 'Áudio', label: 'Áudio' },
    { value: 'Outros', label: 'Outros' },
  ];

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Nome</TableCell>
            <TableCell align="center">Tipo</TableCell>
            <TableCell align="center">Ação</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {anexosList.length > 0 && anexosList.map((item, index) => (
            <TableRow>
              <TableCell align="center">
                { item.nomeArquivo }
              </TableCell>
              <TableCell align="center">
                <TextField
                  sx={{ width: '200px' }}
                  select
                  id="demo-simple-select-helper"
                  value={selectedValue}
                  label="Tipo"
                  onChange={(event) => {
                    setSelectedValue(event.target.value);
                    changeFile(event, index);
                  }}
                >
                  {fileOptions.map(({
                    value,
                    label,
                  }) => (
                    <MenuItem
                      key={value}
                      value={value}
                    >
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              </TableCell>
              <TableCell align="center">
                <Box display="flex" justifyContent="center" alignItems="center">
                  <ThemeProvider theme={secondaryButton}>
                    <Button
                      onClick={(event) => deleteFile(event, index)}
                    >
                      Excluir
                    </Button>
                  </ThemeProvider>
                </Box>
              </TableCell>
            </TableRow>
          ))}
          {anexosList.length === 0 && (
            <TableRow>
              <TableCell
                colSpan={3}
              >
                <Typography
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    fontFamily: 'Poppins',
                    color: '#000',
                    fontWeight: '400',
                  }}
                >
                  Sem arquivos selecionados!
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
