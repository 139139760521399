import { createTheme } from '@mui/material/styles';

export const primaryButton = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        fullWidth: true,
        disableElevation: true,
        type: 'submit',
      },
      styleOverrides: {
        root: {
          borderRadius: '2rem',
          backgroundColor: '#EB5A12',
          marginBottom: '0.5rem',
          padding: '10px 24px',
          '&:hover': {
            backgroundColor: '#FF7F50',
          },
          fontFamily: 'Poppins',
          textTransform: 'none',
          fontWeight: 700,
        },
      },
    },
  },
});

export const secondaryButton = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        fullWidth: true,
        disableElevation: true,
        type: 'submit',
      },
      styleOverrides: {
        root: {
          color: '#EB5A12',
          border: '1px #EB5A12 solid',
          borderRadius: '2rem',
          backgroundColor: '#fff',
          marginBottom: '0.5rem',
          padding: '5px 12px',
          '&:hover': {
            borderColor: '#EB5A12',
            backgroundColor: 'rgba(255,127,80,0.2)',
          },
          fontFamily: 'Poppins',
          textTransform: 'none',
          fontWeight: 700,
        },
      },
    },
  },
});
