import { parseCookies } from 'nookies';
import React, {
  ChangeEvent, useEffect, useState,
} from 'react';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { toast, ToastContainer } from 'react-toastify';
import dayjs from 'dayjs';
import style from '../../components/ProjectQuotation/projectquotation.module.scss';
import { api } from '../../services/api';
import {
  Project, Customer, Address, City, State, Associate, CreditDataProps,
} from '../../contexts/@types';
import {
  defaultProject, defaultCustomer, defaultAddress, defaultCity, defaultState,
} from '../../contexts/@defaultValues';
import ProjectQuotation from '../../components/ProjectQuotation';
import ProjectCustomer from '../../components/ProjectCustumer';
import ProjectAnalysis from '../../components/ProjectAnalysis';
import ProjectContract from '../../components/ProjectContract';
import { DialogComponent } from '../../components/DialogComponent';
import { handleFixCurrency, sendDocument, timeStampToDateOnly } from '../../utils/util';

export interface FieldError {
  name: boolean,
  power: boolean,
  totalValue: boolean,
  entryValue: boolean,
  lack: boolean,
  customerName: boolean,
  cpf: boolean,
  rg: boolean,
  birthDate: boolean,
  phone: boolean,
  motherName: boolean,
  maritalStatus: boolean,
  occupation: boolean,
  monthlyIncome: boolean,
  occupationTime: boolean,
  assetValue:boolean,
  customerEmail: boolean,
  averageElectricityBill: boolean,
  zipCode: boolean,
  street: boolean,
  num: boolean,
  state: boolean,
  city: boolean,
  district: boolean,
  cnpj: boolean,
  foundationDate: boolean,
  socialReason: boolean,
  fantasyName: boolean,
  companyEmail: boolean,
  companyPhone: boolean,
  monthlyIncomeCompany: boolean,
  shareCapital: boolean,
  declaration: boolean,
  authorization: boolean,
  terms: boolean,
  fproposal: boolean,
  fidentidade: boolean,
}
export function NovoProjeto() {
  const [activeStep, setActiveStep] = useState(0);
  const [project, setProject] = useState<Project>(defaultProject);
  const [customer, setCustomer] = useState<Customer>(defaultCustomer);
  const [address, setAddress] = useState<Address>(defaultAddress);
  const [city, setCity] = useState<City>(defaultCity);
  const [state, setState] = useState<State>(defaultState);
  const [associates, setAssociates] = useState<Array<Associate>>([]);
  const [creditOptions, setCreditOptions] = useState<Array<CreditDataProps>>([]);
  const [chosenTax, setChosenTax] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [callUseEffect, setCallUseEffect] = useState<boolean>(false);
  const [disableForms, setDisableForms] = useState({
    quotation: false,
    customer: false,
    analysis: false,
    contract: false,
    debt: false,
  });
  const [fieldsError, setFieldsError] = useState<FieldError>({
    name: false,
    power: false,
    totalValue: false,
    entryValue: false,
    lack: false,
    customerName: false,
    cpf: false,
    rg: false,
    birthDate: false,
    phone: false,
    motherName: false,
    maritalStatus: false,
    occupation: false,
    monthlyIncome: false,
    occupationTime: false,
    assetValue: false,
    customerEmail: false,
    averageElectricityBill: false,
    zipCode: false,
    street: false,
    num: false,
    state: false,
    city: false,
    district: false,
    cnpj: false,
    foundationDate: false,
    socialReason: false,
    fantasyName: false,
    companyEmail: false,
    companyPhone: false,
    monthlyIncomeCompany: false,
    shareCapital: false,
    declaration: true,
    authorization: true,
    terms: true,
    fproposal: false,
    fidentidade: false,
  });

  const handleChangeAssociates = (e: Associate, action: string) => {
    if (action === 'add') setAssociates([...associates, e]);
    else if (action === 'remove') setAssociates(associates.filter((associate) => associate !== e));
  };

  const handleToggleTerms = (prop:
    keyof {declaration: boolean, authorization: boolean, terms: boolean }) => (e:
    ChangeEvent<HTMLInputElement>) => {
    setFieldsError({ ...fieldsError, [prop]: !(e.target.checked) });
  };

  const getProjectId = () => {
    const { 'nextauth.p': projId } = parseCookies();
    return Number(projId);
  };

  const loadQuotationData = (data: any) => {
    const loadedData = {
      ...data,
      valorTotal: handleFixCurrency(data.valorTotal),
      valorEntrada: handleFixCurrency(data.valorEntrada),
      dateDeposit: timeStampToDateOnly(data.dateDeposit),
    };
    setProject(loadedData);
  };

  const loadCustomerData = (value: any) => {
    try {
      api.post(
        'project/costumer/get',
        { projectId: value.id },
      ).then((response) => {
        const { data } = response;
        if (data === 'COULD_NOT_FIND_COSTUMER') return toast.error('Cliente não encontrado');
        setCustomer({
          ...customer,
          ...data,
          faturamentoMensal: handleFixCurrency(data.faturamentoMensal),
          valorPatrimonio: handleFixCurrency(data.valorPatrimonio),
          mediaContaLuz: handleFixCurrency(data.mediaContaLuz),
          dataNascimento: timeStampToDateOnly(data.dataNascimento),
        });
        setState(data.stateId);
        setAddress({
          ...address,
          ...data.enderecoInstalacao,
        });
        setAssociates(data.associates);
        return 'ok';
      });
    } catch ({ message }) {
      toast.error('Ocorreu um erro ao tentar resgatar as informações do cliente.');
    }
  };

  useEffect(() => {
    setLoading(true);
    const getProjectData = async () => {
      const response: Promise<AxiosResponse<any, any>> = api.post(
        'project/single/get',
        { projectId: getProjectId() },
      );

      response.then((resolved) => {
        setLoading(false);
        const { data } = resolved;
        if (data !== 'COULD_NOT_LOCATE_PROJECT') {
          if (!data.nome || !(data.nome.length !== 0)) return;
          loadQuotationData(data);
          setDisableForms({
            ...disableForms,
            quotation: true,
          });
          setActiveStep(1);

          if (!data.clienteId || !(data.clienteId !== 0)) return;
          loadCustomerData(data);
          setDisableForms({
            ...disableForms,
            quotation: true,
            customer: true,
          });
          setActiveStep(2);

          if (data.status === 'Aguardando escolha das condições'
            || data.status === 'Aguardando documentos'
            || data.status === 'Financiamento recusado'
            || data.status === 'Financiamento aprovado'
            || data.status === 'Análise - Bancos'
            || data.status === 'Análise - Soltech') {
            if (data.status === 'Financiamento recusado') {
              setDisableForms({
                ...disableForms,
                quotation: true,
                customer: true,
                analysis: true,
                contract: true,
              });
            }
            setActiveStep(2);
          }
          if ((data.chosen_analysisId && data.chosen_analysisId !== 0) || data.status === 'Aguardando escolha das condições') {
            const resp: Promise<AxiosResponse<any, any>> = api.post('/project/credit/grouped/get', {
              projectId: getProjectId(),
            });
            resp.then((creditData) => {
              setCreditOptions(creditData.data);
            });
          }

          if (data.status === 'Análise - Documentos') {
            setDisableForms({
              ...disableForms,
              quotation: true,
              customer: true,
              analysis: true,
            });
            setActiveStep(3);
          }

          if (data.status === 'Aguardando NF') {
            setDisableForms({
              ...disableForms,
              quotation: true,
              customer: true,
              analysis: true,
            });
            setActiveStep(3);
          }

          if (data.status === 'Condições escolhidas') {
            setDisableForms({
              ...disableForms,
              quotation: true,
              customer: true,
              analysis: true,
            });
            setActiveStep(3);
          }

          if (data.status === 'Documentos recusados') {
            setDisableForms({
              ...disableForms,
              quotation: true,
              customer: true,
              analysis: false,
              contract: true,
            });
            setActiveStep(2);
          }

          if (data.status === 'Projeto contratado') {
            setDisableForms({
              ...disableForms,
              quotation: true,
              customer: true,
              analysis: true,
              contract: true,
            });
            setActiveStep(3);
          }
        }
      });
    };
    getProjectData().then(() => {});
    // If Use-effect is changed run without the disabled eslint to check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callUseEffect]);

  const cardStyle = {
    minHeight: '100%',
    backgroundColor: '#fff',
    boxShadow: '0px 2px 2px 0px #0000001A',
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: '0.5rem 1.5rem',
    gap: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };

  const stepButtonStyle = {
    borderRadius: '0.5rem',
    backgroundColor: '#EB5A12',
    marginBottom: '0.5rem',
    marginTop: '1rem',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#FF7F50',
    },
  };

  const stepButton = (title: string) => {
    const step0 = activeStep === 0 && title === 'Cotação';
    const step1 = activeStep === 1 && title === 'Cliente';
    const step2 = activeStep === 2 && title === 'Análise';
    const step3 = activeStep === 3 && title === 'Contrato';
    // const step4 = activeStep === 4 && title === 'Débito';
    return (
      <Button
        fullWidth
        variant="contained"
        sx={stepButtonStyle}
        disabled={!(step0 || step1 || step2 || step3)}
      >
        <Box sx={{ display: 'flex', gap: '0.5rem' }}>
          {title === 'Cotação' && <ArticleOutlinedIcon />}
          {title === 'Cliente' && <PeopleAltOutlinedIcon />}
          {title === 'Análise' && <LeaderboardOutlinedIcon />}
          {title === 'Contrato' && <AssignmentOutlinedIcon />}
          {/* {title === 'Débito' && <CreditCardOutlinedIcon />} */}
          {title}
        </Box>
      </Button>
    );
  };

  const history = useHistory();

  const handleClose = () => {
    const msg = 'Deseja realmente cancelar o cadastro do projeto?';
    // eslint-disable-next-line no-alert
    if (window.confirm(msg)) {
      history.push('/MeusProjetosIntegradora');
    }
  };

  const isNextStep = () => {
    if (activeStep === 0 && disableForms.quotation) return true;
    if (activeStep === 1 && disableForms.customer) return true;
    if (activeStep === 2 && disableForms.analysis) return true;
    if (activeStep === 3 && disableForms.contract) return true;
    if (activeStep === 4 && disableForms.debt) return true;
    return false;
  };

  function formatCurrency(value: string) {
    if (value === '0,0') return '';
    let input = value.replace(/\D/g, '');
    input = (parseInt(input, 10) / 100).toFixed(2).replace(/\./g, ',');
    return input;
  }

  const handleChangeQuotation = (key: keyof Project) => (e: ChangeEvent<HTMLInputElement>) => {
    let input: string | boolean | number | File | null = e.target.value;
    if (key === 'valorEntrada' || key === 'valorTotal') {
      input = input.replace(/\D/g, '');
      input = input ? formatCurrency(input) : '';
    } else if (key === 'potencia') {
      input = input.replace(/[^0-9.]/g, ''); // Remove tudo que não é dígito ou ponto
      if ((input.match(/\./g) || []).length > 1) { // Se houver mais de um ponto
        input = input.substring(0, input.lastIndexOf('.')); // Remove o último ponto
      }
    } if (key === 'proposal' || key === 'invoice' || key === 'receipt') {
      if (e.target.files) {
        const [file] = Array.from(e.target.files);
        if (file.type === 'application/pdf') input = file;
      } else input = null;
    }
    setProject({ ...project, [key]: input });
  };

  const validateQuotationForm = async () => {
    const errors = {
      name: !project.nome,
      power: !project.potencia || String(project.potencia) === '0.00' || String(project.potencia) === '0',
      totalValue: !project.valorTotal || project.valorTotal === '0,00',
      entryValue: !project.valorEntrada,
      lack: !project.carencia,
    };

    console.log(project.potencia);

    setFieldsError({ ...fieldsError, ...errors });

    const fieldNames = {
      name: 'Nome do projeto',
      power: 'Potência instalada',
      totalValue: 'Valor total do projeto',
      entryValue: 'Valor de entrada do projeto',
      lack: 'Carência',
    };

    const emptyFields = Object.keys(errors)
      .filter((key) => errors[key as keyof typeof errors])
      .map((key) => fieldNames[key as keyof typeof fieldNames])
      .join(';; ');

    if (emptyFields.length > 0) {
      toast.error(
        <>
          O(s) campo(s) a seguir devem ser preenchidos:
          {emptyFields.split('; ').map((e) => (
            <React.Fragment key={e}>
              <br />
              {e}
            </React.Fragment>
          ))}
        </>,
      );

      return 'nok';
    }
    return 'ok';
  };

  const { 'nextauth.tag': tag } = parseCookies();
  const handleSubmitProject = async () => {
    setLoading(true);
    try {
      const response = await api.post(
        'project/put',
        { userId: Number(tag) },
      );
      const { projectId } = response.data;
      const newProject = project;
      newProject.id = projectId;
      setProject(newProject);
      setLoading(false);
      return 'ok';
    } catch ({ message }) {
      setLoading(false);
      toast.error('Ocorreu um erro ao cadastrar o projeto.');
      return message;
    }
  };

  const fixCurrencyToSubmit = (value: string) => {
    const currency = value.replace(/,/g, '');
    return Number(currency);
  };

  const handleSubmitQuotation = async () => {
    setLoading(true);
    try {
      await api.post('project/quotation/set', {
        data: {
          projectId: Number(project.id),
          nome: project.nome,
          carencia: Number(project.carencia),
          valorEntrada: fixCurrencyToSubmit(project.valorEntrada),
          valorTotal: fixCurrencyToSubmit(project.valorTotal),
          potencia: Number(project.potencia),
          parcelasBtg: 3,
        },
      });
      setLoading(false);
      return 'ok';
    } catch ({ message }) {
      setLoading(false);
      toast.error('Ocorreu um erro ao cadastrar a cotação.');
      return message;
    }
  };

  function formatOccupationTime(value: string) {
    let input = value;
    input = input.replace(/\D/g, '');
    if (Number(input) > 50) input = '50';
    if (Number(input) < 0) input = '0';
    return input;
  }

  const handleChangeCustomer = (key: keyof Customer) => (e:
    ChangeEvent<HTMLInputElement>) => {
    let input: string | boolean | number | File | null = e.target.value;

    if (
      key === 'faturamentoMensal'
      || key === 'valorPatrimonio'
      || key === 'mediaContaLuz'
      || key === 'faturamentoMensalEmpresa'
      || key === 'capitalSocial'
    ) input = formatCurrency(input);
    if (key === 'tempoProfissao') input = formatOccupationTime(input);
    if (key === 'company_link') input = e.target.checked;
    if (
      key === 'proposal'
      || key === 'documentoIdentificacao'
      || key === 'comprovanteRenda'
      || key === 'iptu'
      || key === 'contaLuzRecente'
      || key === 'notaFiscalEquipamentos'
    ) {
      if (e.target.files) {
        const [file] = Array.from(e.target.files);
        if (file.type !== 'application/pdf') {
          toast.error('Por favor, insira somente arquivos do tipo pdf.');
          input = null;
        } else if (file.type === 'application/pdf') input = file;
      } else {
        if (key === 'contaLuzRecente') {
          const newCustomer = customer;
          newCustomer['electricityBillOwnership'] = true;
          newCustomer['electricityBillFamiliarType'] = 0;
          setCustomer(newCustomer);
        }
        input = null;
      }
    }

    if (key === 'electricityBillOwnership') input = input === 'true';
    setCustomer({
      ...customer,
      [key]: input,
    });
  };

  const validateCustomerForm: () => string = () => {
    let errors = {
      customerName: !customer.nome,
      cpf: !address.zip,
      rg: !customer.rg,
      birthDate: !dayjs(customer.dataNascimento, 'DD/MM/YYYY').isValid(),
      phone: !customer.telefone,
      motherName: !customer.nomeMae,
      maritalStatus: !customer.estadoCivil,
      occupation: !customer.profissao,
      monthlyIncome: !customer.faturamentoMensal,
      occupationTime: !customer.tempoProfissao,
      assetValue: !customer.valorPatrimonio,
      customerEmail: !customer.email,
      averageElectricityBill: !customer.mediaContaLuz,
      zipCode: !address.zip,
      street: !address.street1,
      num: !address.number,
      state: !state.id,
      city: !city.id,
      district: !address.district,
      cnpj: false,
      foundationDate: false,
      socialReason: false,
      fantasyName: false,
      companyEmail: false,
      companyPhone: false,
      monthlyIncomeCompany: false,
      shareCapital: false,
      fidentidade: !customer.documentoIdentificacao
        || typeof customer.documentoIdentificacao === 'string',
      fproposal: !project.proposal
        || typeof project.proposal === 'string',
    };

    if (customer.company_link) {
      errors = {
        ...errors,
        cnpj: !customer.cnpj,
        foundationDate: !customer.dataAbertura,
        socialReason: !customer.razaoSocial,
        fantasyName: !customer.nomeFantasia,
        companyEmail: !customer.emailEmpresa,
        companyPhone: !customer.telefoneComercial,
        monthlyIncomeCompany: !customer.faturamentoMensalEmpresa,
        shareCapital: !customer.capitalSocial,
        fidentidade: !customer.documentoIdentificacao,
      };
    }

    setFieldsError({ ...fieldsError, ...errors });

    const fieldNames = {
      customerName: 'Nome do cliente',
      cpf: 'CPF',
      rg: 'RG',
      birthDate: 'Data de nascimento',
      phone: 'Telefone',
      motherName: 'Nome da mãe',
      maritalStatus: 'Estado civil',
      occupation: 'Profissão',
      monthlyIncome: 'Faturamento mensal',
      occupationTime: 'Tempo de profissão',
      assetValue: 'Valor do patrimônio',
      customerEmail: 'E-mail do cliente',
      averageElectricityBill: 'Média da conta de luz',
      zipCode: 'CEP',
      street: 'Logradouro',
      num: 'Nº',
      state: 'Estado',
      city: 'Cidade',
      district: 'Bairro',
      cnpj: 'CNPJ',
      foundationDate: 'Data de fundação',
      socialReason: 'Razão social',
      fantasyName: 'Nome fantasia',
      companyEmail: 'E-mail da empresa',
      companyPhone: 'Telefone da empresa',
      monthlyIncomeCompany: 'Faturamento mensal da empresa',
      shareCapital: 'Média da conta de luz',
      fproposal: 'Proposta comercial',
      fidentidade: 'Documento de Identificação CNH/RG',
    };

    const emptyFields = Object.keys(errors)
      .filter((key) => errors[key as keyof typeof errors])
      .map((key) => fieldNames[key as keyof typeof fieldNames])
      .join(';; ');

    if (emptyFields.length > 0) {
      toast.error(
        <>
          O(s) campo(s) a seguir devem ser preenchidos:
          {emptyFields.split('; ').map((e) => (
            <React.Fragment key={e}>
              <br />
              {e}
            </React.Fragment>
          ))}
        </>,
      );

      return 'nok';
    }

    if (fieldsError.declaration || fieldsError.authorization || fieldsError.terms) {
      toast.error('Deve estar de acordo com os termos para salvar');
      return 'nok';
    }
    return 'ok';
  };

  const handleSubmitCustomer = async () => {
    setLoading(true);
    // let fproposta;
    // if (customer.proposal) {
    //   fproposta = 'No file here';
    //   fproposta = await sendDocument('proposta', customer.proposal);
    // }
    // if (fproposta === 'No file here') {
    //   setLoading(false);
    //   toast.error('Ocorreu um erro ao tentar salvar o arquivo.');
    //   return 'nok';
    // }
    const documents = {
      fidentidade: await sendDocument('identidade', customer.documentoIdentificacao),
      proposta: await sendDocument('proposta', project.proposal),
    };

    try {
      await api.post('project/costumer/put', {
        data: {
          capitalSocial: customer.capitalSocial,
          cnpj: customer.cnpj,
          company_link: customer.company_link,
          cpf: customer.cpf,
          dataAbertura: customer.dataAbertura,
          dataNascimento: customer.dataNascimento,
          email: customer.email,
          emailEmpresa: customer.emailEmpresa,
          estadoCivil: customer.estadoCivil,
          renda: fixCurrencyToSubmit(customer.faturamentoMensal),
          faturamentoMensal: fixCurrencyToSubmit(customer.faturamentoMensalEmpresa),
          mediaLuz: fixCurrencyToSubmit(customer.mediaContaLuz),
          nome: customer.nome,
          nomeFantasia: customer.nomeFantasia,
          nomeMae: customer.nomeMae,
          profissao: customer.profissao,
          fproposta: documents.proposta,
          razaoSocial: customer.razaoSocial,
          rg: customer.rg,
          telefone: customer.telefone,
          telefoneComercial: customer.telefoneComercial,
          tempoProfissao: customer.tempoProfissao,
          valorPatrimonio: fixCurrencyToSubmit(customer.valorPatrimonio),

          cidade: address.cityId,
          district: address.district,
          numero: address.number,
          rua: address.street1,
          complemento: address.street2,
          cep: address.zip,

          projectId: project.id,
          potencia: project.potencia,
          type: project.type,

          associates: associates || [],
          fidentidade: documents.fidentidade,

        },
      }).then((response) => {
        setLoading(false);
        if (response.data === 'COSTUMER_UPDATED') {
          toast.success(
            'Cliente registrado com sucesso!',
          );
        } else toast('Ocorreu um erro na tentativa de registrar o cliente.');
        return 'ok';
      });
      return 'ok';
    } catch ({ message }) {
      toast.error('Ocorreu um erro ao cadastrar o cliente.');
      return message;
    }
  };

  const validateAnalysis = async () => {
    const errors = {
      chosenTax: !chosenTax || chosenTax === 0,
      incomeProof: !customer.comprovanteRenda
        || typeof customer.comprovanteRenda === 'string',
      iptu: !customer.iptu || typeof customer.iptu === 'string',
      lastEletricBill: !customer.contaLuzRecente
        || typeof customer.contaLuzRecente === 'string',
    };

    console.log(errors.lastEletricBill);

    if (Object.values(errors).every((val) => val === true)) {
      toast.error('Nada para ser enviado.'
        + ' Selecione uma opção de crédito ou documento para ser enviado.');
      return 'nok';
    }

    return 'ok';
  };

  const updateTax = async () => {
    const response = await api.post('project/tax/put', {
      data: {
        projectId: project.id,
        chosen_analysisId: Number(chosenTax),
      },
    });

    if (response.data !== 'CREDIT_OPTIONS_UPDATED') {
      toast.error('Falha ao atualiza a taxa.');
      return;
    }
    toast.success('Taxa escolhida com sucesso.');
  };

  const handleSubmitAnalysis = async () => {
    setLoading(true);
    if (chosenTax) {
      await updateTax();
    }

    if (
      project.proposal instanceof File
      || customer.documentoIdentificacao instanceof File
      || customer.comprovanteRenda instanceof File
      || customer.iptu instanceof File
      || customer.contaLuzRecente instanceof File
    ) {
      const documents = {
        fproposta: await sendDocument('proposal', project.proposal),
        fcomprovanteRenda: await sendDocument('comprovanteRenda', customer.comprovanteRenda),
        fiptu: await sendDocument('iptu', customer.iptu),
        fcontaluz: await sendDocument('contaLuz', customer.contaLuzRecente),
      };

      const requestBody = {
        headers: { 'Content-Type': 'multipart/form-data' },
        data: {
          projectId: project.id,
          electricityBillFamiliarType: Number(customer.electricityBillFamiliarType),
          electricityBillOwnership: customer.electricityBillOwnership,
          ...documents,
        },
      };

      const response = await api.post(
        '/project/sendCostumerDocs',
        requestBody,
      );
      if (response.data === 'UNABLE_TO_UPDATE_DOC') {
        toast.error('Ocorreu um erro ao enviar o(s) documento(s).');
      }
      toast.success('Documentos enviados com sucesso.');
      setProject({
        ...project,
        proposal: documents.fproposta,
      });
      setCustomer({
        ...customer,
        comprovanteRenda: documents.fcomprovanteRenda,
        iptu: documents.fiptu,
        contaLuzRecente: documents.fcontaluz,
      });
    }
    setLoading(false);
  };

  const validateContract = async () => {
    const errors = {
      invoice: !project.invoice || typeof project.invoice === 'string',
      equipmentInvoice: !customer.notaFiscalEquipamentos
        || typeof customer.notaFiscalEquipamentos === 'string',
    };

    if (Object.values(errors).every((val) => val === true)) {
      toast.error('Nada para ser enviado.');
      return 'nok';
    }

    return 'ok';
  };

  const handleSubmitContract = async () => {
    setLoading(true);
    if (
      project.invoice instanceof File
      || customer.notaFiscalEquipamentos instanceof File
    ) {
      const documents = {
        finvoice: await sendDocument('invoice', project.invoice),
        fequipamento: await sendDocument('identidade', customer.documentoIdentificacao),
      };

      if (project.invoice instanceof File) {
        const request = {
          headers: { 'Content-Type': 'application/json' },
          data: {
            projectId: project.id,
            invoice: documents.finvoice,
          },
        };
        const response = await api.post('project/sendInvoiceDoc', request);
        if (response.data === 'UNABLE_TO_UPDATE_DOC') {
          toast.error('Ocorreu um erro ao enviar a nota fiscal.');
        } else toast.success('Nota fiscal enviada com sucesso.');
      }

      if (customer.notaFiscalEquipamentos instanceof File) {
        const request = {
          headers: { 'Content-Type': 'application/json' },
          data: {
            projectId: project.id,
            fequipamento: documents.fequipamento,
          },
        };
        const response = await api.post('/project/sendCostumerEquipmentDoc', request);
        if (response.data === 'UNABLE_TO_UPDATE_DOC') {
          toast.error('Ocorreu um erro ao enviar a nota fiscal do equipamento.');
        } else toast.success('Nota fiscal do equipamento enviada com sucesso.');
      }
      setProject({
        ...project,
        invoice: documents.finvoice,
      });
      setCustomer({
        ...customer,
        notaFiscalEquipamentos: documents.fequipamento,
      });
    }
    setLoading(false);
  };

  const handleStepNavigation = async (direction: string) => {
    const isWithinStepRange = activeStep >= 0 && activeStep < 5;
    const isNextDirection = direction === 'next';
    const isBackDirection = direction === 'back';
    const isSaveDirection = direction === 'save';

    if (!isWithinStepRange) return;

    if (isNextDirection && activeStep < 4) {
      setActiveStep(activeStep + 1);
      return;
    }

    if (isBackDirection && activeStep > 0) {
      setActiveStep(activeStep - 1);
      return;
    }

    if (isSaveDirection && activeStep === 0) {
      const quotationValidation = await validateQuotationForm();
      if (quotationValidation !== 'ok') return;

      const projectSubmission = await handleSubmitProject();
      if (projectSubmission !== 'ok') return;

      const quotationSubmission = await handleSubmitQuotation();
      if (quotationSubmission !== 'ok') return;

      setDisableForms({ ...disableForms, quotation: true });
      toast.success('Projeto e cotação registrados com sucesso!');
      setProject({
        ...project,
        status: 'Cadastro',
      });
      setActiveStep(activeStep + 1);
      return;
    }

    if (isSaveDirection && activeStep === 1) {
      const customerValidation = await validateCustomerForm();
      if (customerValidation !== 'ok') return;

      const response = await handleSubmitCustomer();
      if (response !== 'ok') return;
      toast.success('Cliente registrado com sucesso!');
      setDisableForms({ ...disableForms, customer: true });
      setProject({
        ...project,
        status: 'Análise - Soltech',
      });
      setActiveStep(activeStep + 1);
      return;
    }

    if (isSaveDirection && activeStep === 2) {
      const analysisValidation = await validateAnalysis();
      if (analysisValidation !== 'ok') return;

      await handleSubmitAnalysis().then(() => {
        setCallUseEffect(!callUseEffect);
      });
      return;
    }

    if (isSaveDirection && activeStep === 3) {
      const contractValidation = await validateContract();
      if (contractValidation !== 'ok') return;

      await handleSubmitContract().then(() => {
        setCallUseEffect(!callUseEffect);
      });
      return;
    }

    toast.info('Próxima navegação ainda não foi implementada.');
  };

  return (
    <>
      <ToastContainer />
      <Box sx={cardStyle}>
        <div>
          <Grid container spacing={10} rowSpacing={2}>
            {['Cotação', 'Cliente', 'Análise', 'Contrato'].map((e) => (
              <Grid xs={3}>
                {stepButton(e)}
              </Grid>
            ))}
          </Grid>
          { activeStep === 0
            && (
            <ProjectQuotation
              project={project}
              handleChangeProject={handleChangeQuotation}
              isDisabled={disableForms.quotation}
              fieldsError={fieldsError}
            />
            )}
          { activeStep === 1
            && (
            <ProjectCustomer
              customer={customer}
              handleChangeCustomer={handleChangeCustomer}
              address={address}
              setAddress={setAddress}
              city={city}
              setCity={setCity}
              state={state}
              setState={setState}
              project={project}
              handleChangeProject={handleChangeQuotation}
              associates={associates}
              handleChangeAssociates={handleChangeAssociates}
              isDisabled={disableForms.customer}
              handleToggleTerms={handleToggleTerms}
              fieldsError={fieldsError}
              setCustomer={setCustomer}
              validateCustomerForm={validateCustomerForm}
            />
            ) }
          {(activeStep === 2) && (
            <ProjectAnalysis
              project={project}
              handleChangeProject={handleChangeQuotation}
              customer={customer}
              handleChangeCustomer={handleChangeCustomer}
              creditOptions={creditOptions}
              chosenTax={chosenTax}
              setChosenTax={setChosenTax}
              isDisabled={disableForms.analysis}
            />
          )}
          {(activeStep === 3) && (
            <ProjectContract
              project={project}
              handleChangeProject={handleChangeQuotation}
              customer={customer}
              handleChangeCustomer={handleChangeCustomer}
              isDisabled={disableForms.contract}
            />
          )}
          {/* {(activeStep === 4) && (window.location.assign('/Debito'))} */}
        </div>
        <Box>
          <hr className={style.bottomDivisor} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              gap: '1rem',
            }}
          >
            <Button
              variant="outlined"
              sx={{
                width: '10rem',
                borderRadius: '2rem',
                borderColor: '#EB5A12',
                color: '#EB5A12',
                marginBottom: '0.5rem',
                marginTop: '1rem',
                textTransform: 'none',
                '&:hover': {
                  borderColor: '#EB5A12',
                  backgroundColor: 'rgba(255,127,80,0.2)',
                },
              }}
              onClick={activeStep === 0 ? handleClose : () => { handleStepNavigation('back'); }}
            >
              Voltar
            </Button>
            {(activeStep === 3 && disableForms.contract) || (
              <Button
                variant="contained"
                sx={{
                  width: '10rem',
                  borderRadius: '2rem',
                  backgroundColor: '#EB5A12',
                  marginBottom: '0.5rem',
                  marginTop: '1rem',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#FF7F50',
                  },
                }}
                onClick={() => handleStepNavigation(isNextStep() ? 'next' : 'save')}
              >
                {isNextStep() && <>Próximo</>}
                {!isNextStep() && <>Salvar</>}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <DialogComponent isOpen={loading} labelText="Carregando informações do projeto" />
    </>
  );
}
