import { styled } from '@mui/system';
import React from 'react';

interface DividerProps {
  variant: 'primary' | 'secondary'
}

const StyledDivider = styled('hr')(({ variant }: DividerProps) => ({
  border: variant === 'primary' ? '1px solid #EB5A12' : '1px solid #DBDDE4',
  margin: '0.75rem 0 0.5rem 0',
}));

export default function Divider(props: DividerProps) {
  const {
    variant,
  }: DividerProps = props;
  return <StyledDivider variant={variant} />;
}
