import React, {
  ChangeEvent,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { parseCookies } from 'nookies';
import Grid from '@mui/material/Unstable_Grid2';
import {
  Autocomplete,
  Box, Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import FileOpenRoundedIcon from '@mui/icons-material/FileOpenRounded';
import InputMask from 'react-input-mask';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import {
  applyNoSymbolCurrencyMask,
  currencyToSubmit,
  handleFixCurrency,
  isValidUrl,
  openPdf,
  timeStampToDateOnly,
} from '../../utils/util';
import { api } from '../../services/api';
import style from './issue.module.scss';
import {
  Address,
  City, Customer, Issue, Modification, Project, State,
} from '../../contexts/@types';
import {
  defaultAddress,
  defaultCity,
  defaultCustomer,
  defaultIssue,
  defaultProject,
  defaultState,
} from '../../contexts/@defaultValues';
import { DialogComponent } from '../DialogComponent';

interface FieldError {
  solved: boolean,
  reason: boolean,
}

interface FieldsToChange {
  nome_cliente: boolean
  cpf_cliente: boolean
  nome_projeto: boolean
  potencia_projeto: boolean
  valor_projeto: boolean
  entrada_projeto: boolean
  carencia_projeto: boolean
  rg_cliente: boolean
  data_nascimento: boolean
  nome_mae: boolean
  telefone_cliente: boolean
  renda_cliente: boolean
  email_cliente: boolean
  media_conta: boolean
  cep_cliente: boolean
  numero_residencia: boolean
  estado_civil: boolean
  profissao: boolean
  tempo_profissao: boolean
  valor_patrimonio: boolean
  tipo_projeto: boolean
  data_deposito: boolean
}

interface LoadedFilesProps {
  equipamento: string | null,
  contaLuz: string | null,
  identidade: string | null,
  iptu: string | null,
  comprovanteRenda: string | null,
  notaFiscal: string | null
}

export function IssueDetails() {
  const [loadedFiles, setLoadedFiles] = useState<LoadedFilesProps>({
    equipamento: null,
    contaLuz: null,
    identidade: null,
    iptu: null,
    comprovanteRenda: null,
    notaFiscal: null,
  });
  const [customer, setCustomer] = useState<Customer>(defaultCustomer);
  const [address, setAddress] = useState<Address>(defaultAddress);
  const [states, setStates] = useState<Array<State>>([defaultState]);
  const [state, setState] = useState<State>(defaultState);
  const [cities, setCities] = useState<Array<City>>([defaultCity]);
  const [city, setCity] = useState<City>(defaultCity);
  const [issue, setIssue] = useState<Issue>(defaultIssue);
  const [project, setProject] = useState<Project>(defaultProject);
  const [modifications, setModifications] = useState<Array<Modification>>([]);

  const [fieldsError, setFieldsError] = useState<FieldError & FieldsToChange>({
    solved: false,
    reason: false,
    nome_cliente: false,
    cpf_cliente: false,
    nome_projeto: false,
    potencia_projeto: false,
    valor_projeto: false,
    entrada_projeto: false,
    carencia_projeto: false,
    rg_cliente: false,
    data_nascimento: false,
    nome_mae: false,
    telefone_cliente: false,
    renda_cliente: false,
    email_cliente: false,
    media_conta: false,
    cep_cliente: false,
    numero_residencia: false,
    estado_civil: false,
    profissao: false,
    tempo_profissao: false,
    valor_patrimonio: false,
    tipo_projeto: false,
    data_deposito: false,
  });

  const [enableFields, setEnableFields] = useState<FieldsToChange>({
    nome_cliente: false,
    cpf_cliente: false,
    nome_projeto: false,
    potencia_projeto: false,
    valor_projeto: false,
    entrada_projeto: false,
    carencia_projeto: false,
    rg_cliente: false,
    data_nascimento: false,
    nome_mae: false,
    telefone_cliente: false,
    renda_cliente: false,
    email_cliente: false,
    media_conta: false,
    cep_cliente: false,
    numero_residencia: false,
    estado_civil: false,
    profissao: false,
    tempo_profissao: false,
    valor_patrimonio: false,
    tipo_projeto: false,
    data_deposito: false,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingText, setLoadingText] = useState('');
  const { 'nextauth.tag': tag } = parseCookies();
  const { 'nextauth.roles': cookieRoles } = parseCookies();
  const history = useHistory();

  const getCities = async (stateID: number) => {
    const response = await api.post('/address/city/get', { stateId: stateID });
    setCities(response.data);
  };
  useEffect(() => {
    setLoadingText('Buscando dados do chamado...');
    setLoading(true);
    const getStates = async () => {
      const response = await api.post('/address/state/get', {}, {});
      setStates(response.data);
    };

    // eslint-disable-next-line consistent-return
    const getIssue = async () => {
      const { id } = history.location.state as { id: string };
      const issueId = { issueId: id };
      const response = await api.post('/issue/single/get', issueId, {});
      const { data } = response;

      if (!data) return toast.error('Não foi possível carregar as informações do chamado.');

      setIssue(data);

      setLoadedFiles({
        contaLuz: data.contaLuzRecente,
        identidade: data.documentoIdentificacao,
        equipamento: data.notaFiscalEquipamentos,
        iptu: data.iptu,
        comprovanteRenda: data.comprovanteRenda,
        notaFiscal: data.invoice,
      });

      setCustomer(data.projeto.cliente);

      if (data.projeto.cliente) {
        setAddress(data.projeto.cliente.enderecoInstalacao);
        setState(data.projeto.cliente.enderecoInstalacao.city.state);
        setCity(data.projeto.cliente.enderecoInstalacao.city);
      }

      setProject(data.projeto);

      setModifications(data.modification);

      if (cookieRoles === 'Gerente') {
        let enableFieldsData = enableFields;
        data.modification.forEach((e: Modification) => {
          if ((e.codAttribute as keyof FieldsToChange) in enableFields) {
            enableFieldsData = { ...enableFieldsData, [e.codAttribute]: true };
          }
        });
        setEnableFields(enableFieldsData);
      }
    };

    getStates().then(() => {});
    getIssue().then(() => { setLoading(false); });
    // If Use-effect is changed run without the disabled eslint to check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag]);

  const zipSearch = async (zip: string) => {
    try {
      const getByZipResponse = await api.post('/address/cep/get', { data: { ref: zip.replace(/\D/g, '') } });
      return getByZipResponse.data;
    } catch {
      toast.error(`CEP ${zip} não encontrado`);
      return false;
    }
  };
  useEffect(() => {
    if (!!address.zip && !address.zip.includes('_')) {
      const zipCODE = address.zip.replace(/\D/g, '');
      zipSearch(zipCODE).then((data) => {
        if (!data) return;
        if (!states) return;

        const newAddress: Address = {
          ...address,
          district: data.bairro,
          street1: data.rua,
          cityId: data.cidadeId,
        };
        setAddress(newAddress);

        const newCity: City = {
          id: data.cidadeId,
          name: data.cidade,
          stateId: data.estadoId,
        };
        setCity(newCity);

        const selectedState = states.find((e) => e.id === data.estadoId);
        if (!selectedState) return;
        const newState: State = {
          id: data.estadoId,
          name: selectedState.name,
          acronym: selectedState.acronym,
        };
        setState(newState);
      });
    }
    // If Use-effect is changed run without the disabled eslint to check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states, address.zip]);

  const validateFields = () => {
    let errors: FieldError | FieldsToChange = {
      solved: issue.resolvido === null,
      reason: !issue.motivos,
    };

    if (cookieRoles === 'Gerente') {
      const managerErrors: { [key: string]: boolean } = {
        nome_cliente: !customer.nome,
        cpf_cliente: !customer.cpf || customer.cpf.includes('_'),
        nome_projeto: !project.nome,
        potencia_projeto: !project.potencia,
        valor_projeto: !project.valorTotal,
        entrada_projeto: !project.valorEntrada,
        carencia_projeto: !project.carencia,
        rg_cliente: !customer.rg,
        data_nascimento: !customer.dataNascimento,
        nome_mae: !customer.nomeMae,
        telefone_cliente: !customer.telefone || customer.telefone.includes('_'),
        renda_cliente: !customer.faturamentoMensal,
        email_cliente: !customer.email,
        media_conta: !customer.mediaContaLuz,
        cep_cliente: !address.zip,
        numero_residencia: !address.number,
        estado_civil: !customer.estadoCivil,
        profissao: !customer.profissao,
        tempo_profissao: !customer.tempoProfissao,
        valor_patrimonio: !customer.valorPatrimonio,
        tipo_projeto: !project.type,
        data_deposito: !project.dateDeposit,
      };

      modifications.forEach((mod) => {
        if (mod.codAttribute in managerErrors) {
          errors = {
            ...errors,
            [mod.codAttribute]: managerErrors[mod.codAttribute],
          };
        }
      });
    }

    setFieldsError({ ...fieldsError, ...errors });

    const fieldNames = {
      solved: 'Status do projeto',
      reason: 'Motivo',
      nome_cliente: 'Nome completo',
      cpf_cliente: 'CPF',
      nome_projeto: 'Nome do projeto',
      potencia_projeto: 'Potência do projeto',
      valor_projeto: 'Valor total do projeto',
      entrada_projeto: 'valor de entrada do projeto',
      carencia_projeto: 'Carência do projeto',
      rg_cliente: 'RG',
      data_nascimento: 'Data de nascimento',
      nome_mae: 'Nome da mãe',
      telefone_cliente: 'Telefone',
      renda_cliente: 'Faturamento mensal',
      email_cliente: 'E-mail',
      media_conta: 'Média da conta de luz',
      cep_cliente: 'CEP',
      numero_residencia: 'Número da residência',
      estado_civil: 'Estado civil',
      profissao: 'Profissão',
      tempo_profissao: 'Tempo de profissão',
      valor_patrimonio: 'Valor do patrimônio',
      tipo_projeto: 'Tipo do projeto',
      data_deposito: 'Data do depósito',
    };

    const emptyFields = Object.keys(errors)
      .filter((key) => errors[key as keyof typeof errors])
      .map((key) => fieldNames[key as keyof typeof fieldNames])
      .join(';; ');

    if (emptyFields.length > 0) {
      toast.error(
        <>
          O(s) campo(s) a seguir devem ser preenchidos:
          {emptyFields.split('; ').map((e) => (
            <React.Fragment key={e}>
              <br />
              {e}
            </React.Fragment>
          ))}
        </>,
      );

      return 'nok';
    }

    return 'ok';
  };
  const submitStatus = async () => {
    let responseStatus;
    if (cookieRoles === 'Gerente') {
      const request = {
        headers: { 'Content-Type': 'application/json' },
        data: {
          issueId: issue.id,
          tag,
          status: issue.resolvido ? 'Fechado' : 'Aberto',
        },
      };
      responseStatus = await api.post('/issue/status/put', request);
    } else {
      const request = {
        headers: { 'Content-Type': 'application/json' },
        data: {
          issueId: issue.id,
          tag,
          resolvido: issue.resolvido,
          motivos: issue.motivos,
        },
      };
      responseStatus = await api.post('/issue/solved/put', request);
    }

    switch (responseStatus.data) {
      case 'ISSUE_UPDATED':
      case 'ISSUE_SOLVED':
        toast.success('Chamado alterado com sucesso!');
        setLoading(false);
        history.goBack();
        break;
      default:
        setLoading(false);
        toast.error(`Falha ao alterar o chamado.${responseStatus.data}`);
        break;
    }
  };

  const submitData = async () => {
    setLoading(true);
    try {
      await api.post('/project/costumer/alter', {
        data: {
          issueId: issue.id,
          tag,
          projectId: project.id,
          nome: customer.nome,
          cpf: customer.cpf,
          rg: customer.rg,
          dataNascimento: customer.dataNascimento,
          profissao: customer.profissao,
          estadoCivil: customer.estadoCivil,
          tempoProfissao: customer.tempoProfissao,
          valorPatrimonio: customer.valorPatrimonio,
          nomeMae: customer.nomeMae,
          telefone: customer.telefone,
          renda: customer.faturamentoMensal,
          email: customer.email,
          mediaLuz: customer.mediaContaLuz,
          rua: address.street1,
          numero: address.number,
          complemento: address.street2,
          cep: address.zip,
          cidade: city.id,
          potenciaInstalada: project.potencia,
          nomeProjeto: project.nome,
          valorTotal: Number(project.valorTotal),
          valorEntrada: Number(project.valorEntrada),
          valorCarencia: Number(project.carencia),
          dateDeposit: project.dateDeposit,
        },
      }).then((response) => {
        setLoading(false);

        switch (response.data) {
          case 'NEW_CLIENT_INSERTED':
          case 'COSTUMER_UPDATED':
            toast.success('Dados Salvos!');
            return true;
          default:
            toast.error(`Falha ao atualizar os dados. ${response.data}`);
            return false;
        }
      });
    } catch ({ message }) {
      toast.error(`Falha ao atualizar os dados. ${message}`);
      return message;
    }
    return false;
  };

  const verifyFiles = () => (
    loadedFiles.contaLuz?.toLowerCase() !== 'sem documentos'
    || loadedFiles.iptu?.toLowerCase() !== 'sem documentos'
    || loadedFiles.equipamento?.toLowerCase() !== 'sem documentos'
    || loadedFiles.comprovanteRenda?.toLowerCase() !== 'sem documentos'
    || loadedFiles.identidade?.toLowerCase() !== 'sem documentos'
    || loadedFiles.notaFiscal?.toLowerCase() !== 'sem documentos'
  );

  const submitFiles = async () => {
    try {
      const response = await api.post('project/issueDocs/put', {
        data: {
          tag,
          projectId: project.id,
          fequipamento: loadedFiles.equipamento?.toLowerCase() !== 'sem documento' ? loadedFiles.equipamento : null,
          fcontaluz: loadedFiles.contaLuz?.toLowerCase() !== 'sem documento' ? loadedFiles.contaLuz : null,
          fidentidade: loadedFiles.identidade?.toLowerCase() !== 'sem documento' ? loadedFiles.identidade : null,
          fiptu: loadedFiles.iptu?.toLowerCase() !== 'sem documento' ? loadedFiles.iptu : null,
          fcomprovanteRenda: loadedFiles.comprovanteRenda?.toLowerCase() !== 'sem documento' ? loadedFiles.comprovanteRenda : null,
          fnotaFiscal: loadedFiles.notaFiscal?.toLowerCase() !== 'sem documento' ? loadedFiles.notaFiscal : null,
        },
      });

      setLoading(false);

      switch (response.data) {
        case 'FILES_INSERTED':
        case 'DOCS_INSERTED':
        case 'FILES_UPDATED':
        case 'DOCS_UPDATED':
          toast.success('Arquivos alterados!');
          return true;
        default:
          toast.error(`Falha ao atualizar os arquivos. ${response.data}`);
          return false;
      }
    } catch ({ message }) {
      toast.error(`Falha ao atualizar os arquivos. ${message}`);
      return false;
    }
  };

  const handleSubmit = async () => {
    setLoadingText('Atualizando chamado...');
    setLoading(true);

    const validation = validateFields();
    if (validation !== 'ok') {
      setLoading(false);
      return;
    }

    let isFilesSaved = false;
    const haveFiles = verifyFiles();
    if (haveFiles && cookieRoles === 'Gerente') {
      // eslint-disable-next-line no-alert
      if (window.confirm('Foi solicitado a alteração de arquivos, deseja sobreescrever os arquivos solicitados nos dados do projeto?')) {
        isFilesSaved = await submitFiles();
      }
      setLoading(false);
    }

    if (haveFiles && !isFilesSaved) return;

    // eslint-disable-next-line no-alert
    if (window.confirm(`Você tem certeza que deseja marcar este chamado como ${(issue.resolvido) ? '"Resolvido"' : '"Não resolvido" '}?`)) {
      if (modifications.length > 0 && cookieRoles === 'Gerente') {
        const dataSubmission = await submitData();
        if (dataSubmission) {
          setLoading(false);
          return;
        }
      }
      await submitStatus()
        .then(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };
  const estadoCivilValue = customer && (customer.estadoCivil !== 'Não selecionado' ? customer.estadoCivil : '');
  const handleChangeFieldValue = (code: string, value: string | number) => {
    let vl = value;
    if (
      code === 'renda_cliente'
      || code === 'valor_patrimonio'
      || code === 'media_conta'
      || code === 'valor_projeto'
      || code === 'entrada_projeto'
    ) {
      vl = String(vl).replace(/[^0-9.,]/g, '').trim();
      return `R$ ${applyNoSymbolCurrencyMask(vl)}`;
    }
    return vl;
  };

  const handleChangeCustomer = (key: keyof Customer) => (e:
    ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    if (key === 'faturamentoMensal' || key === 'valorPatrimonio' || key === 'mediaContaLuz') {
      value = currencyToSubmit(value);
    }
    setCustomer({
      ...customer,
      [key]: value,
    });
  };

  const handleChangeAddress = (key: keyof Address) => (e:
    ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setAddress({
      ...address,
      [key]: value,
    });
  };

  const handleChangeProject = (key: keyof Project) => (e:
    ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    if (key === 'potencia' || key === 'valorTotal' || key === 'valorEntrada') {
      value = currencyToSubmit(value);
    }
    setProject({
      ...project,
      [key]: value,
    });
  };

  return (
    <>
      <DialogComponent isOpen={loading} labelText={loadingText} />
      <Grid container spacing={2} rowSpacing={3}>
        <Grid xs={12} lg={8} container spacing={2} rowSpacing={2}>
          <Grid xs={12}>
            <Typography sx={{
              fontSize: { xs: '16px', md: '24px' },
              fontFamily: 'Poppins',
              color: '#EB5A12',
              fontWeight: '700',
            }}
            >
              Chamado aberto para o projeto #
              {project && (project.id)}
            </Typography>
            <hr className={style.divisor} />
          </Grid>
          <Grid xs={12} sx={{ mt: 0, pt: 0 }}>
            <TextField
              disabled
              fullWidth
              required
              label="Assunto do chamado"
              variant="outlined"
              value={issue.assunto}
              InputLabelProps={{
                shrink: !!issue.assunto,
              }}
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              disabled
              fullWidth
              required
              label="Descrição do chamado"
              variant="outlined"
              value={issue.descricao}
              InputLabelProps={{
                shrink: !!issue.descricao,
              }}
            />
          </Grid>
        </Grid>
        <Grid xs={12} lg={4} container spacing={2} rowSpacing={1}>
          <Grid xs={12}>
            <Typography sx={{
              fontSize: { xs: '16px', md: '24px' },
              fontFamily: 'Poppins',
              color: '#EB5A12',
              fontWeight: '700',
            }}
            >
              Documentação
            </Typography>
            <hr className={style.divisor} />
          </Grid>
          <Grid xs={12} sx={{ mt: 0, pt: 0 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{
                fontSize: {
                  xs: '12px',
                  md: '16px',
                },
                fontFamily: 'Poppins',
                fontWeight: '400',
              }}
              >
                Comprovante de renda
              </Typography>

              {isValidUrl(loadedFiles.comprovanteRenda) ? (
                <IconButton
                  onClick={() => (
                    typeof loadedFiles.comprovanteRenda === 'string'
                      ? openPdf(loadedFiles.comprovanteRenda) : {})}
                >
                  <FileOpenRoundedIcon sx={{ color: '#EB5A12' }} />
                </IconButton>
              ) : 'Não enviado'}
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{
                fontSize: {
                  xs: '12px',
                  md: '16px',
                },
                fontFamily: 'Poppins',
                fontWeight: '400',
              }}
              >
                Conta de luz recente
              </Typography>

              {isValidUrl(loadedFiles.contaLuz) ? (
                <IconButton
                  onClick={() => (
                    typeof loadedFiles.contaLuz === 'string'
                      ? openPdf(loadedFiles.contaLuz) : {})}
                >
                  <FileOpenRoundedIcon sx={{ color: '#EB5A12' }} />
                </IconButton>
              ) : 'Não enviado'}
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{
                fontSize: {
                  xs: '12px',
                  md: '16px',
                },
                fontFamily: 'Poppins',
                fontWeight: '400',
              }}
              >
                Documento de identificação
              </Typography>

              {isValidUrl(loadedFiles.identidade) ? (
                <IconButton
                  onClick={() => (
                    typeof loadedFiles.identidade === 'string'
                      ? openPdf(loadedFiles.identidade) : {})}
                >
                  <FileOpenRoundedIcon sx={{ color: '#EB5A12' }} />
                </IconButton>
              ) : 'Não enviado'}
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{
                fontSize: {
                  xs: '12px',
                  md: '16px',
                },
                fontFamily: 'Poppins',
                fontWeight: '400',
              }}
              >
                IPTU
              </Typography>

              {isValidUrl(loadedFiles.iptu) ? (
                <IconButton
                  onClick={() => (
                    typeof loadedFiles.iptu === 'string'
                      ? openPdf(loadedFiles.iptu) : {})}
                >
                  <FileOpenRoundedIcon sx={{ color: '#EB5A12' }} />
                </IconButton>
              ) : 'Não enviado'}
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{
                fontSize: {
                  xs: '12px',
                  md: '16px',
                },
                fontFamily: 'Poppins',
                fontWeight: '400',
              }}
              >
                Nota fiscal do equipamento
              </Typography>
              {isValidUrl(loadedFiles.equipamento) ? (
                <IconButton
                  onClick={() => (
                    typeof loadedFiles.equipamento === 'string'
                      ? openPdf(loadedFiles.equipamento) : {})}
                >
                  <FileOpenRoundedIcon sx={{ color: '#EB5A12' }} />
                </IconButton>
              ) : 'Não enviado'}

            </Box>
          </Grid>
          <Grid xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{
                fontSize: {
                  xs: '12px',
                  md: '16px',
                },
                fontFamily: 'Poppins',
                fontWeight: '400',
              }}
              >
                Nota fiscal
              </Typography>

              {isValidUrl(loadedFiles.notaFiscal) ? (
                <IconButton
                  onClick={() => (
                    typeof loadedFiles.notaFiscal === 'string'
                      ? openPdf(loadedFiles.notaFiscal) : {})}
                >
                  <FileOpenRoundedIcon sx={{ color: '#EB5A12' }} />
                </IconButton>
              ) : 'Não enviado'}
            </Box>
          </Grid>
        </Grid>
        <Grid xs={12} container spacing={2} rowSpacing={2}>
          <Grid xs={12}>
            <Typography sx={{
              fontSize: { xs: '16px', md: '24px' },
              fontFamily: 'Poppins',
              color: '#EB5A12',
              fontWeight: '700',
            }}
            >
              Cadastro do cliente
            </Typography>
            <hr className={style.divisor} />
          </Grid>
          <Grid xs={12} lg={2.4}>
            <TextField
              error={fieldsError.nome_cliente}
              fullWidth
              required
              label="Nome completo"
              variant="outlined"
              value={customer ? customer.nome : ''}
              onChange={handleChangeCustomer('nome')}
              InputLabelProps={{
                shrink: customer && !!customer.nome,
              }}
              disabled={!enableFields.nome_cliente}
            />
          </Grid>
          <Grid xs={12} lg={2.4}>
            <InputMask
              mask="999.999.999-99"
              maskPlaceholder="_"
              disabled={!enableFields.cpf_cliente}
              value={customer ? customer.cpf : ''}
              onChange={handleChangeCustomer('cpf')}
            >
              <TextField
                error={fieldsError.cpf_cliente}
                fullWidth
                required
                label="CPF"
                variant="outlined"
                InputLabelProps={{
                  shrink: customer && !!customer.cpf,
                }}
              />
            </InputMask>
          </Grid>
          <Grid xs={12} lg={2.4}>
            <TextField
              error={fieldsError.rg_cliente}
              fullWidth
              required
              label="RG"
              variant="outlined"
              InputLabelProps={{
                shrink: customer && !!customer.rg,
              }}
              disabled={!enableFields.rg_cliente}
              value={customer ? customer.rg : ''}
              onChange={handleChangeCustomer('rg')}
            />
          </Grid>
          <Grid xs={12} lg={2.4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disabled={!enableFields.data_nascimento}
                label="Data de nascimento *"
                openTo="month"
                views={['year', 'month', 'day']}
                format="DD/MM/YYYY"
                value={customer ? dayjs(timeStampToDateOnly(customer.dataNascimento), 'DD/MM/YYYY') : null}
                onChange={(e) => {
                  const fakeEvent = {
                    target: {
                      value: e?.format('DD/MM/YYYY'),
                    },
                  } as React.ChangeEvent<HTMLInputElement>;
                  handleChangeCustomer('dataNascimento')(fakeEvent);
                }}
                sx={{ width: '100%' }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid xs={12} lg={2.4}>
            <InputMask
              mask="(99) 9 9999-9999"
              maskPlaceholder="_"
              disabled={!enableFields.telefone_cliente}
              value={customer ? customer.telefone : ''}
              onChange={handleChangeCustomer('telefone')}
            >
              <TextField
                error={fieldsError.telefone_cliente}
                fullWidth
                required
                label="Telefone"
                variant="outlined"
                InputLabelProps={{
                  shrink: customer && !!customer.telefone,
                }}
              />
            </InputMask>
          </Grid>
          <Grid xs={12} lg={3}>
            <TextField
              error={fieldsError.nome_mae}
              fullWidth
              required
              label="Nome da mãe"
              variant="outlined"
              InputLabelProps={{
                shrink: customer && !!customer.nomeMae,
              }}
              disabled={!enableFields.nome_mae}
              value={customer ? customer.nomeMae : ''}
              onChange={handleChangeCustomer('nomeMae')}
            />
          </Grid>
          <Grid xs={12} lg={3}>
            <FormControl fullWidth required error={fieldsError.estado_civil}>
              <InputLabel id="select-marital-status">Estado civil</InputLabel>
              <Select
                disabled={!enableFields.estado_civil}
                label="Estado civil"
                value={estadoCivilValue}
                onChange={(e) => {
                  const fakeEvent = {
                    target: {
                      value: e.target.value,
                    },
                  } as React.ChangeEvent<HTMLInputElement>;
                  handleChangeCustomer('dataNascimento')(fakeEvent);
                }}
                labelId="select-marital-status"
              >
                <MenuItem value="Casado">Casado</MenuItem>
                <MenuItem value="Solteiro">Solteiro</MenuItem>
                <MenuItem value="Viúvo">Viúvo</MenuItem>
                <MenuItem value="União Estável">União Estável</MenuItem>
                <MenuItem value="Separado">Separado</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12} lg={3}>
            <TextField
              error={fieldsError.profissao}
              fullWidth
              required
              label="Profissão"
              variant="outlined"
              InputLabelProps={{
                shrink: customer && !!customer.profissao,
              }}
              disabled={!enableFields.profissao}
              value={customer ? customer.profissao : ''}
              onChange={handleChangeCustomer('profissao')}
            />
          </Grid>
          <Grid xs={12} lg={3}>
            <TextField
              error={fieldsError.renda_cliente}
              fullWidth
              required
              label="Renda/Faturamento mensal (líquida)"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
              }}
              disabled={!enableFields.renda_cliente}
              value={customer ? handleFixCurrency(customer.faturamentoMensal) : ''}
              onChange={handleChangeCustomer('faturamentoMensal')}
            />
          </Grid>
          <Grid xs={12} lg={2.2}>
            <TextField
              error={fieldsError.tempo_profissao}
              fullWidth
              required
              label="Tempo de profissão (Máx. 50 anos)"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">ano(s)</InputAdornment>,
              }}
              disabled={!enableFields.tempo_profissao}
              value={customer ? customer.tempoProfissao : ''}
              onChange={handleChangeCustomer('tempoProfissao')}
            />
          </Grid>
          <Grid xs={12} lg={2.6}>
            <TextField
              error={fieldsError.valor_patrimonio}
              fullWidth
              required
              label="Valor do patrimônio"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
              }}
              disabled={!enableFields.valor_patrimonio}
              value={customer ? handleFixCurrency(customer.valorPatrimonio) : ''}
              onChange={handleChangeCustomer('valorPatrimonio')}
            />
          </Grid>
          <Grid xs={12} lg={3}>
            <TextField
              error={fieldsError.email_cliente}
              fullWidth
              required
              label="E-mail do cliente"
              variant="outlined"
              InputLabelProps={{
                shrink: customer && !!customer.email,
              }}
              disabled={!enableFields.email_cliente}
              value={customer ? customer.email : ''}
              onChange={handleChangeCustomer('email')}
            />
          </Grid>
          <Grid xs={12} lg={2.6}>
            <TextField
              error={fieldsError.media_conta}
              fullWidth
              required
              label="Média da conta de luz (últimos 3 meses)"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
              }}
              disabled={!enableFields.media_conta}
              value={customer ? handleFixCurrency(customer.mediaContaLuz) : ''}
              onChange={handleChangeCustomer('mediaContaLuz')}
            />
          </Grid>
          <Grid xs={12} lg={1.6}>
            <FormControl fullWidth required error={fieldsError.tipo_projeto}>
              <InputLabel id="select-project-type">Tipo do projeto</InputLabel>
              <Select
                disabled={!enableFields.tipo_projeto}
                label="Tipo do projeto"
                value={project.type}
                onChange={(e) => {
                  const fakeEvent = {
                    target: {
                      value: e.target.value,
                    },
                  } as React.ChangeEvent<HTMLInputElement>;
                  handleChangeProject('type')(fakeEvent);
                }}
                labelId="select-project-type"
              >
                <MenuItem value="1">Comercial</MenuItem>
                <MenuItem value="2">Rural</MenuItem>
                <MenuItem value="3">Residencial</MenuItem>
                <MenuItem value="4">Industrial</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid xs={12} container spacing={2} rowSpacing={2}>
          <Grid xs={12}>
            <Typography sx={{
              fontSize: { xs: '16px', md: '24px' },
              fontFamily: 'Poppins',
              color: '#EB5A12',
              fontWeight: '700',
            }}
            >
              Endereço da instalação
            </Typography>
            <hr className={style.divisor} />
          </Grid>
          <Grid xs={12} lg={2}>
            <InputMask
              mask="99.999-999"
              maskPlaceholder="_"
              disabled={!enableFields.cep_cliente}
              value={address.zip}
              onChange={handleChangeAddress('zip')}
            >
              <TextField
                error={fieldsError.cep_cliente}
                fullWidth
                required
                label="CEP"
                variant="outlined"
                InputLabelProps={{
                  shrink: !!address.zip,
                }}
              />
            </InputMask>
          </Grid>
          <Grid xs={12} lg={3}>
            <TextField
              fullWidth
              required
              label="Logradouro"
              variant="outlined"
              InputLabelProps={{
                shrink: !!address.street1,
              }}
              disabled
              value={address.street1}
            />
          </Grid>
          <Grid xs={12} lg={1}>
            <TextField
              error={fieldsError.numero_residencia}
              fullWidth
              required
              label="Nº"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={!enableFields.numero_residencia}
              value={customer ? address.number : ''}
              onChange={handleChangeAddress('number')}
            />
          </Grid>
          <Grid xs={12} lg={2}>
            <TextField
              fullWidth
              label="Complemento"
              variant="outlined"
              InputLabelProps={{
                shrink: !!address.street2,
              }}
              disabled
              value={customer ? address.number : ''}
            />
          </Grid>
          <Grid xs={12} lg={2}>
            <Autocomplete
              disabled
              id="state"
              disableClearable
              options={states}
              getOptionLabel={(option: State) => option.acronym}
              value={state}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Estado"
                  required
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid xs={12} lg={2}>
            <Autocomplete
              disabled
              id="city"
              disableClearable
              options={cities}
              getOptionLabel={(option: City) => option.name}
              value={city}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Cidade"
                  required
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid xs={12} container spacing={2} rowSpacing={2}>
          <Grid xs={12}>
            <Typography sx={{
              fontSize: { xs: '16px', md: '24px' },
              fontFamily: 'Poppins',
              color: '#EB5A12',
              fontWeight: '700',
            }}
            >
              Outras informações
            </Typography>
            <hr className={style.divisor} />
          </Grid>
          <Grid xs={12} lg={2.4}>
            <TextField
              error={fieldsError.nome_projeto}
              fullWidth
              required
              label="Nome do projeto"
              variant="outlined"
              value={project.nome}
              onChange={handleChangeProject('nome')}
              InputLabelProps={{
                shrink: !!project.nome,
              }}
              disabled={!enableFields.nome_projeto}
            />
          </Grid>
          <Grid xs={12} lg={2.4}>
            <TextField
              error={fieldsError.potencia_projeto}
              fullWidth
              required
              label="Potência instalada (kW)"
              variant="outlined"
              value={project.potencia}
              onChange={handleChangeProject('potencia')}
              InputProps={{
                endAdornment: <InputAdornment position="end">kW</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={!enableFields.potencia_projeto}
            />
          </Grid>
          <Grid xs={12} lg={2.4}>
            <TextField
              error={fieldsError.valor_projeto}
              fullWidth
              required
              label="Valor total do projeto"
              variant="outlined"
              value={applyNoSymbolCurrencyMask(project.valorTotal)}
              onChange={handleChangeProject('valorTotal')}
              InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={!enableFields.valor_projeto}
            />
          </Grid>
          <Grid xs={12} lg={2.4}>
            <TextField
              error={fieldsError.entrada_projeto}
              fullWidth
              required
              label="Valor de entrada do projeto"
              variant="outlined"
              value={applyNoSymbolCurrencyMask(project.valorEntrada)}
              onChange={handleChangeProject('valorEntrada')}
              InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={!enableFields.entrada_projeto}
            />
          </Grid>
          <Grid xs={12} lg={2.4}>
            <FormControl fullWidth required error={fieldsError.carencia_projeto}>
              <InputLabel id="demo-simple-select-label" shrink>Carência</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Carência"
                value={project.carencia > 0 ? project.carencia : null}
                onChange={(e) => {
                  const fakeEvent = {
                    target: {
                      value: e.target.value,
                    },
                  } as React.ChangeEvent<HTMLInputElement>;
                  handleChangeProject('carencia')(fakeEvent);
                }}
                disabled={!enableFields.carencia_projeto}
              >
                <MenuItem value={1}>1 mês</MenuItem>
                <MenuItem value={2}>2 meses</MenuItem>
                <MenuItem value={3}>3 meses</MenuItem>
                <MenuItem value={4}>4 meses</MenuItem>
                <MenuItem value={5}>5 meses</MenuItem>
                <MenuItem value={6}>6 meses</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12} lg={2.4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disabled={!enableFields.data_deposito}
                label="Data do depósito *"
                openTo="month"
                views={['year', 'month', 'day']}
                format="DD/MM/YYYY"
                value={project.dateDeposit ? dayjs(timeStampToDateOnly(project.dateDeposit), 'DD/MM/YYYY') : null}
                onChange={(e) => {
                  const fakeEvent = {
                    target: {
                      value: e?.format('DD/MM/YYYY'),
                    },
                  } as React.ChangeEvent<HTMLInputElement>;
                  handleChangeProject('dateDeposit')(fakeEvent);
                }}
                sx={{ width: '100%' }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid xs={12} lg={6} container spacing={2} rowSpacing={2}>
          <Grid xs={12}>
            <Typography sx={{
              fontSize: { xs: '16px', md: '24px' },
              fontFamily: 'Poppins',
              color: '#EB5A12',
              fontWeight: '700',
            }}
            >
              Mudanças de campos solicitadas
            </Typography>
            <hr className={style.divisor} />
          </Grid>
          <Grid xs={12}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Campo</TableCell>
                    <TableCell>Novo valor</TableCell>
                    <TableCell>Código do campo</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {modifications.length > 0 ? modifications.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{row.name}</TableCell>
                      <TableCell>
                        {handleChangeFieldValue(row.codAttribute, row.newValue)}
                      </TableCell>
                      <TableCell>{row.codAttribute}</TableCell>
                    </TableRow>
                  )) : (
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell colSpan={3}>Nenhuma modificação solicitada</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid xs={12} lg={6} container spacing={2} rowSpacing={0}>
          <Grid xs={12}>
            <Typography sx={{
              fontSize: { xs: '16px', md: '24px' },
              fontFamily: 'Poppins',
              color: '#EB5A12',
              fontWeight: '700',
            }}
            >
              Status do chamado
            </Typography>
            <hr className={style.divisor} />
          </Grid>
          <Grid xs={12} lg={4.4}>
            <FormControl disabled={cookieRoles !== 'Gerente' ? issue.status === 'Fechado' : false} error={fieldsError.solved}>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={String(issue.resolvido)}
                onChange={(e) => {
                  setIssue({
                    ...issue,
                    resolvido: (e.target.value === 'true'),
                  });
                }}
              >
                <FormControlLabel value="false" control={<Radio />} label="Manter aberto" />
                <FormControlLabel value="true" control={<Radio />} label="Fechar" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid xs={12} lg={7.6}>
            <TextField
              error={fieldsError.reason}
              fullWidth
              multiline
              maxRows={4}
              required
              label="Descrição"
              variant="outlined"
              value={issue.motivos}
              onChange={(e) => {
                setIssue({
                  ...issue,
                  motivos: e.target.value,
                });
              }}
              disabled={cookieRoles !== 'Gerente' ? issue.status === 'Fechado' : false}
            />
          </Grid>
          <Grid xs={12} sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
            {(cookieRoles !== 'Gerente' && issue.status === 'Fechado') || (
              <Button
                fullWidth
                variant="contained"
                sx={{
                  width: '10rem',
                  borderRadius: '2rem',
                  backgroundColor: '#EB5A12',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#FF7F50',
                  },
                }}
                onClick={handleSubmit}
              >
                Salvar alterações
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
