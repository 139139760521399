import { useHistory } from 'react-router-dom';
import style from './chamdoeditor.module.scss';

interface ModificationData {
  id?: number;
  name?: string;
  newValue?: string;
  issueId?: number;
  formFieldId?: number;
  codAttribute?: string;
}

interface Props {
  data: ModificationData;
  idx: number;
}

const ChamadoAvaliarEditor = (props: Props) => {
  const {
    data, idx,
  } = props;

  const {
    id, name, newValue, codAttribute,
  } = data;

  const history = useHistory();

  // const applyCurrencyMask = (n: string) => {
  //   const value : string = n.toString().split('').reverse().join('');
  //
  //   // let newValue = '';
  //   for (let x = 0; x < value.length; x++) {
  //     newValue += value[x];
  //     if (x === 1) {
  //       if (x < value.length - 1) { newValue += ','; }
  //     }
  //
  //     if (x === 4 || x === 7) {
  //       if (x < value.length - 1) { newValue += '.'; }
  //     }
  //   }
  //
  //   newValue = newValue.split('').reverse().join('');
  //   newValue = `R$ ${newValue}`;
  //   return newValue;
  // };

  return (
    <div className={style.listaCadastroItemClass}>
      <table>
        <tbody>
          <tr>
            <td style={{ width: '150px' }}>
              <span>Campo</span>
              <br />
              #
              {name}
            </td>
            <td style={{ width: '150px' }}>
              <span>Novo Valor</span>
              <br />
              {newValue}
            </td>
            <td style={{ width: '150px' }}>
              <span>Código do campo</span>
              <br />
              {codAttribute}
            </td>
            {/* <td className={style.cnpjCelula}> */}
            {/*   <span>Valor da Parcela</span> */}
            {/*   <br /> */}
            {/*   {applyCurrencyMask(valorParcela.toString())} */}
            {/* </td> */}
            {/* <td className={style.telefoneCelula} style={{ width: '15%' }}> */}
            {/*   <span>Taxa</span> */}
            {/*   <br /> */}
            {/*   {taxa} */}
            {/*   % */}
            {/* </td> */}
            {/* <td style={{ width: '70px', display: (showRemove ? 'block' : 'none') }}> */}
            {/*   <button type="button" style={{ width: '70px' }} onClick={() =>
            onClickParam(idx)}>Remover</button> */}
            {/* </td> */}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export { ChamadoAvaliarEditor };
