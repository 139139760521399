import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Box } from '@mui/material';
import { IssueDetails } from '../../components/IssueDetails';

export function DetalheChamado() {
  const cardStyle = {
    backgroundColor: '#fff',
    boxShadow: '0px 2px 2px 0px #0000001A',
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: '1rem',
    minHeight: '82vh',
  };
  return (
    <Box sx={cardStyle}>
      <ToastContainer />
      <IssueDetails />
    </Box>
  );
}
