import Grid from '@mui/material/Unstable_Grid2';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  ChangeEvent,
  useEffect,
  useState,
} from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import FileOpenRoundedIcon from '@mui/icons-material/FileOpenRounded';
import { toast } from 'react-toastify';
import { parseCookies } from 'nookies';
import { AxiosResponse } from 'axios';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import style from '../ProjectCustumer/projectcustomer.module.scss';
import { CreditDataProps, Customer, Project } from '../../contexts/@types';
import { Post } from '../Posts';
import { api } from '../../services/api';
import { usePusher } from '../../hooks/PusherContext';
import {
  applyCurrencyMask,
  handleFileName,
  handleFixCurrency,
  isValidUrl,
  openPdf,
  timeStampToDate,
} from '../../utils/util';

function Row(props: { row: CreditDataProps }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, borderBottom: 'unset' }}>
        <TableCell sx={{ width: '5%', borderBottom: 'unset' }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography sx={{ fontFamily: 'Poppins' }}>
            {row.name}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Número de parcelas</TableCell>
                    <TableCell align="center">Valor das parcelas</TableCell>
                    <TableCell align="center">Taxa</TableCell>
                    <TableCell align="center">PDF</TableCell>
                    <TableCell align="right">Escolher taxa</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.bancos.map((creditOption) => (
                    <TableRow key={creditOption.id}>
                      <TableCell align="center">{creditOption.parcelas}</TableCell>
                      <TableCell align="center">{applyCurrencyMask(creditOption.valorParcela)}</TableCell>
                      <TableCell align="center">
                        {creditOption.taxa}
                        %
                      </TableCell>
                      <TableCell align="center">
                        {isValidUrl(creditOption.bancoDoc)
                          ? (
                            <IconButton
                              onClick={() => (openPdf(creditOption.bancoDoc))}
                            >
                              <FileOpenRoundedIcon sx={{ color: '#EB5A12' }} />
                            </IconButton>
                          )
                          : 'Sem documentos'}
                      </TableCell>
                      <TableCell align="right">
                        <FormControlLabel value={creditOption.id} control={<Radio />} label="" />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

interface CollapsibleTableProps {
  rows: Array<CreditDataProps>
  project: Project
  chosenTax: number | undefined,
  setChosenTax: Function
  isDisabled: boolean
}

function CollapsibleTable(props: CollapsibleTableProps) {
  const {
    rows, project, chosenTax, setChosenTax, isDisabled,
  }: CollapsibleTableProps = props;
  return (
    <FormControl
      fullWidth
      disabled={
        !!(project.chosen_analysisId && project.chosen_analysisId !== 0)
        || isDisabled
      }
    >
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        value={project.chosen_analysisId && project.chosen_analysisId !== 0
          ? project.chosen_analysisId : chosenTax}
        onChange={(e) => setChosenTax(e.target.value)}
      >
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead />
            <TableBody>
              {rows.map((row) => (
                <Row key={row.name} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </RadioGroup>
    </FormControl>
  );
}

interface ProjectAnalysisProps {
  project: Project
  handleChangeProject: Function
  customer: Customer
  handleChangeCustomer: Function
  creditOptions: Array<CreditDataProps>,
  chosenTax: number | undefined,
  setChosenTax: Function
  isDisabled: boolean
}

interface Message {
  userID: number,
  senderName: string,
  receiverName: string,
  message: string,
  isFile: boolean,
  fileTitle: string,
  filePath: string,
  created_at: string,
  direction: string,
  messageFile: any
}

const ProjectAnalysis = (props: ProjectAnalysisProps) => {
  const {
    project,
    handleChangeProject,
    customer,
    handleChangeCustomer,
    creditOptions,
    chosenTax,
    setChosenTax,
    isDisabled,
  }: ProjectAnalysisProps = props;
  const [messages, setMessages] = useState<Message[]>([]);
  const [message, setMessage] = useState('');
  const [messageFile, setMessageFile] = useState<any>(null);
  const [user, setUser] = useState('');
  const pusher = usePusher();

  const handleAdicionarMensagem = (value: string) => {
    setMessage(value);
    setMessageFile(null);
  };

  const getUserId = () => {
    const { 'nextauth.tag': userId } = parseCookies();
    return parseInt(userId, 10);
  };
  const userID = getUserId();

  const getToday = () => {
    const date = new Date();
    const day = date.getDate().toString();
    let month = ((date.getMonth() + 1).toString());
    if (month.length === 1) month = `0${month}`;
    const hour = date.getHours();
    const min = date.getMinutes();
    const wish = `Good ${(hour < 12 && 'Morning') || (hour < 17 && 'Afternoon') || 'Evening'}, `;
    const year = date.getFullYear().toString();
    // check if date is after today
    return `${day}/${month}/${year} - ${hour}:${min}`;
  };

  const submitMessage = async () => {
    if (message.length > 0) {
      const sendMessage = await api.post('/message/send', {
        data: {
          projectId: project.id,
          message,
          userID,
          isFile: false,
          fileTitle: '',
          filePath: '',
        },
      });

      if (sendMessage.data === 'CREATED_OK') {
        messages.push({
          message,
          userID: getUserId(),
          senderName: user,
          filePath: '',
          messageFile: '',
          fileTitle: '',
          receiverName: 'Ele',
          direction: '',
          created_at: getToday(),
          isFile: false,
        });
        setMessage('');
        setMessageFile(null);
      }
    }
  };

  const extensionFile = (fileName: string) => fileName.split('.');

  const sendFileMessage = async (label: string, file: any, type: string) => {
    const body = new FormData();
    body.append('filefield', file, label);
    body.append('type', type);
    toast.success('Enviando arquivo, aguarde...');
    const response = await api.post('/upload/fileMessage', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  };

  const handleAdicionarArquivo = async (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;

    const file = fileList.item(0);
    if (!file) return;

    const type = extensionFile(file.name);

    const filePath2 = await sendFileMessage('messageFile', fileList[0], type[0]);

    const sendMessage = await api.post('/message/send', {
      data: {
        projectId: project.id,
        message,
        userID,
        isFile: true,
        fileTitle: fileList[0].name,
        filePath: filePath2,
      },
    });

    if (sendMessage.data === 'CREATED_OK') {
      messages.push({
        message,
        userID: getUserId(),
        senderName: user,
        filePath: filePath2,
        messageFile: fileList[0],
        fileTitle: fileList[0].name,
        receiverName: 'Ele',
        direction: '',
        created_at: getToday(),
        isFile: true,
      });
      setMessageFile({ ...messageFile, file: fileList[0] });
    } else if (sendMessage.data === 'COULD_NOT_SEND_MESSAGE') {
      toast.warning('Não foi possível enviar o arquivo!');
    }
  };

  let userName = '';
  const getMessages = async () => {
    const response: Promise<AxiosResponse<any, any>> = api.post(
      '/message/get',
      { projectId: project.id },
    );

    response.then(async (resolved) => {
      const { data } = resolved;
      const newMessages: Message[] = [];
      for (let i = 0; i < data.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        const resp = await api.post('/user/get', {
          userId: data[i].userID,
        });
        // eslint-disable-next-line no-loop-func
        userName = resp.data.name;
        newMessages.unshift({
          userID: data[i].userID,
          message: data[i].message,
          senderName: userName,
          filePath: data[i].filePath,
          fileTitle: data[i].fileTitle,
          messageFile: '',
          receiverName: `Quem recebeu${2}`,
          direction: '',
          created_at: timeStampToDate(data[i].created_at),
          isFile: data[i].isFile,
        });
      }
      setMessages(newMessages);
    });
  };

  useEffect(() => {
    getMessages().then(() => {});
    const channel = pusher.subscribe(project.id.toString());
    channel.bind('update_messages_integrator', () => {
      getMessages().then(() => {});
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2} rowSpacing={2}>
      <Grid xs={12}>
        <Typography sx={{
          fontSize: {
            xs: '16px',
            md: '24px',
          },
          fontFamily: 'Poppins',
          color: '#EB5A12',
          fontWeight: '700',
        }}
        >
          Análise de crédito
        </Typography>
        <hr className={style.titleDivisor} />
        {(!creditOptions || creditOptions.length === 0) && (
          <Typography sx={{
            fontSize: { xs: '12px', md: '16px' },
            fontFamily: 'Poppins',
            fontWeight: '400',
          }}
          >
            {
              project.status !== 'Financiamento aprovado'
              && project.status !== 'Financiamento recusado'
              && 'Projeto aguardando análise de crédito.'
            }
            {
              project.status === 'Financiamento aprovado'
              && 'Financiamento aprovado. Aguardando condições de credito.'
            }
            {
              project.status === 'Financiamento recusado'
              && 'Financiamento recusado.'
            }
            {
              project.status === 'Documentos recusados'
              && 'Os documentos foram recusados pelo banco. Se desejar, pode enviar novamente para uma nova análise.'
            }
          </Typography>
        )}
      </Grid>
      {creditOptions && creditOptions.length !== 0 && (
        <Grid xs={12}>
          <CollapsibleTable
            rows={creditOptions}
            project={project}
            chosenTax={chosenTax}
            setChosenTax={setChosenTax}
            isDisabled={isDisabled}
          />
        </Grid>
      )}
      <Grid xs={12}>
        <Typography sx={{
          fontSize: {
            xs: '16px',
            md: '24px',
          },
          fontFamily: 'Poppins',
          color: '#EB5A12',
          fontWeight: '700',
        }}
        >
          Documentação do cliente
        </Typography>
        <hr className={style.titleDivisor} />
        {(project.status === 'Documentos recusados') && (
          <Typography sx={{
            fontSize: { xs: '12px', md: '16px' },
            fontFamily: 'Poppins',
            fontWeight: '400',
          }}
          >
            Os documentos foram recusados pelo banco.
            Se desejar, pode enviar novamente para uma nova análise.
          </Typography>
        )}
      </Grid>
      <Grid xs={12} lg={3}>
        <TextField
          required
          label={isValidUrl(customer.comprovanteRenda) ? '' : 'Comprovante de renda'}
          margin="normal"
          fullWidth
          value={isValidUrl(customer.comprovanteRenda)
            ? 'comprovante_de_renda.pdf' : handleFileName(customer.comprovanteRenda)}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                {((!isValidUrl(customer.comprovanteRenda) && !isDisabled)
                  || project.status === 'Documentos recusados')
                  && (
                  <>
                    {customer.comprovanteRenda && customer.comprovanteRenda !== 'Sem documentos' && (
                      <IconButton component="label" onClick={handleChangeCustomer('comprovanteRenda')}>
                        <ClearIcon />
                      </IconButton>
                    )}
                    <IconButton component="label">
                      <CloudUploadIcon sx={{ color: '#EB5A12' }} />
                      <input
                        type="file"
                        accept=".pdf"
                        hidden
                        onChange={handleChangeCustomer('comprovanteRenda')}
                      />
                    </IconButton>
                  </>
                  )}
                {isValidUrl(customer.comprovanteRenda) && (
                  <IconButton
                    onClick={() => (
                      typeof customer.comprovanteRenda === 'string'
                        ? openPdf(customer.comprovanteRenda) : {})}
                  >
                    <FileOpenRoundedIcon sx={{ color: '#EB5A12' }} />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: !!handleFileName(customer.comprovanteRenda),
          }}
          variant="outlined"
          disabled
        />
      </Grid>
      <Grid xs={12} lg={3}>
        <TextField
          label={isValidUrl(customer.iptu) ? '' : 'IPTU (em nome do cliente)'}
          margin="normal"
          fullWidth
          value={isValidUrl(customer.iptu) ? 'iptu.pdf' : handleFileName(customer.iptu)}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                {((!isValidUrl(customer.iptu) && !isDisabled)
                    || project.status === 'Documentos recusados')
                    && (
                    <>
                      {customer.iptu && customer.iptu !== 'Sem documentos' && (
                      <IconButton component="label" onClick={handleChangeCustomer('iptu')}>
                        <ClearIcon />
                      </IconButton>
                      )}
                      <IconButton component="label">
                        <CloudUploadIcon sx={{ color: '#EB5A12' }} />
                        <input
                          type="file"
                          accept=".pdf"
                          hidden
                          onChange={handleChangeCustomer('iptu')}
                        />
                      </IconButton>
                    </>
                    )}
                {isValidUrl(customer.iptu) && (
                  <IconButton
                    onClick={() => (
                      typeof customer.iptu === 'string'
                        ? openPdf(customer.iptu) : {})}
                  >
                    <FileOpenRoundedIcon sx={{ color: '#EB5A12' }} />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: !!handleFileName(customer.iptu),
          }}
          variant="outlined"
          disabled
        />
      </Grid>
      <Grid xs={12} lg={3}>
        <TextField
          required
          label={isValidUrl(customer.contaLuzRecente) ? '' : 'Última conta de luz'}
          margin="normal"
          fullWidth
          value={isValidUrl(customer.contaLuzRecente) ? 'conta_de_luz.pdf' : handleFileName(customer.contaLuzRecente)}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                {((!isValidUrl(customer.contaLuzRecente) && !isDisabled)
                  || project.status === 'Documentos recusados')
                  && (
                  <>
                    {customer.contaLuzRecente && customer.contaLuzRecente !== 'Sem documentos' && (
                      <IconButton component="label" onClick={handleChangeCustomer('contaLuzRecente')}>
                        <ClearIcon />
                      </IconButton>
                    )}
                    <IconButton component="label">
                      <CloudUploadIcon sx={{ color: '#EB5A12' }} />
                      <input
                        type="file"
                        accept=".pdf"
                        hidden
                        onChange={handleChangeCustomer('contaLuzRecente')}
                      />
                    </IconButton>
                  </>
                  )}
                {isValidUrl(customer.contaLuzRecente) && (
                  <IconButton
                    onClick={() => (
                      typeof customer.contaLuzRecente === 'string'
                        ? openPdf(customer.contaLuzRecente) : {})}
                  >
                    <FileOpenRoundedIcon sx={{ color: '#EB5A12' }} />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: !!handleFileName(customer.contaLuzRecente),
          }}
          variant="outlined"
          disabled
        />
      </Grid>
      {!!handleFileName(customer.contaLuzRecente) && (
        <Grid xs={12} lg={3.1}>
          <FormControl margin="normal" disabled={isValidUrl(customer.contaLuzRecente) || isDisabled}>
            <FormLabel id="demo-row-radio-buttons-group-label">A conta de luz está no nome do cliente?</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={String(customer.electricityBillOwnership)}
              onChange={handleChangeCustomer('electricityBillOwnership')}
            >
              <FormControlLabel value="true" control={<Radio />} label="Sim" />
              <FormControlLabel value="false" control={<Radio />} label="Não" />
            </RadioGroup>
          </FormControl>
        </Grid>
      )}
      {!customer.electricityBillOwnership && (
        <Grid xs={12} lg={5.9}>
          <FormControl margin="normal" disabled={isValidUrl(customer.contaLuzRecente) || isDisabled}>
            <FormLabel id="demo-row-radio-buttons-group-label">A conta de luz está no nome de qual familiar?</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={customer.electricityBillFamiliarType}
              onChange={handleChangeCustomer('electricityBillFamiliarType')}
            >
              <FormControlLabel value={0} control={<Radio />} label="Mãe" />
              <FormControlLabel value={1} control={<Radio />} label="Pai" />
              <FormControlLabel value={2} control={<Radio />} label="Esposo(a)" />
              <FormControlLabel value={3} control={<Radio />} label="Filho(a)" />
              <FormControlLabel value={4} control={<Radio />} label="Irmão/Irmã" />
            </RadioGroup>
          </FormControl>
        </Grid>
      )}
      <Grid xs={12} container spacing={2} rowSpacing={2}>
        <Grid xs={12}>
          <Typography sx={{
            fontSize: {
              xs: '16px',
              md: '24px',
            },
            fontFamily: 'Poppins',
            color: '#EB5A12',
            fontWeight: '700',
          }}
          >
            Mensagens
          </Typography>
          <hr className={style.titleDivisor} />
        </Grid>
        <Grid xs={12} sx={{ backgroundColor: '#eee' }}>
          {(messages.length > 0) && messages.map((m) => (
            <Post dataProp={
              {
                userID: m.userID,
                senderName: m.senderName,
                filePath: m.filePath,
                fileTitle: m.fileTitle,
                isFile: m.isFile,
                message: m.message,
                direction: m.direction,
                receiverName: m.receiverName,
                created_at: m.created_at,
              }
            }
            />
          ))}
        </Grid>
        <Grid lg={10}>
          <TextField
            fullWidth
            label="Enviar nova mensagem"
            value={message}
            onChange={(e) => handleAdicionarMensagem(e.target.value)}
            onKeyDown={(e) => { if (e.key === 'Enter') submitMessage(); }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton component="label">
                    <UploadFileRoundedIcon sx={{ color: '#EB5A12' }} />
                    <input
                      type="file"
                      accept=".pdf"
                      hidden
                      onInput={handleAdicionarArquivo}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid lg={2} sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            fullWidth
            variant="contained"
            sx={{
              borderRadius: '2rem',
              backgroundColor: '#EB5A12',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#FF7F50',
              },
            }}
            onClick={submitMessage}
          >
            Enviar mensagem
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProjectAnalysis;
