import {
  IconButton, InputAdornment, TextField, Typography,
} from '@mui/material';
import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import ClearIcon from '@mui/icons-material/Clear';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import FileOpenRoundedIcon from '@mui/icons-material/FileOpenRounded';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { handleFileName, isValidUrl, openPdf } from '../../utils/util';
import { Customer, Project } from '../../contexts/@types';
import style from './projectcontrat.module.scss';

interface ProjectContractProps {
  project: Project
  handleChangeProject: Function
  customer: Customer
  handleChangeCustomer: Function
  isDisabled: boolean
}

const ProjectContract = (props: ProjectContractProps) => {
  const {
    project,
    handleChangeProject,
    customer,
    handleChangeCustomer,
    isDisabled,
  }: ProjectContractProps = props;
  return (
    <Grid container spacing={2} rowSpacing={2}>
      <Grid xs={12}>
        <Typography sx={{
          fontSize: {
            xs: '16px',
            md: '24px',
          },
          fontFamily: 'Poppins',
          color: '#EB5A12',
          fontWeight: '700',
        }}
        >
          Contratação do projeto
        </Typography>
        <hr className={style.titleDivisor} />
      </Grid>
      {(project.status === 'Aguardando NF' || project.status === 'Projeto contratado' || project.status === 'Condições escolhidas') && (
        <>
          <Grid xs={12} lg={3}>
            <TextField
              required
              label={isValidUrl(project.invoice) ? '' : 'Enviar nota fiscal'}
              fullWidth
              value={isValidUrl(project.invoice) ? 'nota_fiscal.pdf' : handleFileName(project.invoice)}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    {!(isValidUrl(project.invoice)) && !isDisabled && (
                    <>
                      {project.invoice && project.invoice !== 'Sem documentos' && (
                        <IconButton component="label" onClick={handleChangeProject('invoice')}>
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton component="label">
                        <CloudUploadIcon sx={{ color: '#EB5A12' }} />
                        <input
                          type="file"
                          accept=".pdf"
                          hidden
                          onChange={handleChangeProject('invoice')}
                        />
                      </IconButton>
                    </>
                    )}
                    {isValidUrl(project.invoice) && (
                    <IconButton
                      onClick={() => (
                        typeof project.invoice === 'string' ? openPdf(project.invoice) : {})}
                    >
                      <FileOpenRoundedIcon sx={{ color: '#EB5A12' }} />
                    </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: !!project.invoice,
              }}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid xs={12} lg={3}>
            <TextField
              label={isValidUrl(customer.notaFiscalEquipamentos) ? '' : 'Enviar nota fiscal do equipamento'}
              fullWidth
              value={isValidUrl(customer.notaFiscalEquipamentos) ? 'nota_fiscal_equipamento.pdf' : handleFileName(customer.notaFiscalEquipamentos)}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    {!(isValidUrl(customer.notaFiscalEquipamentos)) && !isDisabled && (
                      <>
                        {customer.notaFiscalEquipamentos && customer.notaFiscalEquipamentos !== 'Sem documentos' && (
                          <IconButton
                            component="label"
                            onClick={handleChangeCustomer('notaFiscalEquipamentos')}
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                        <IconButton component="label">
                          <CloudUploadIcon sx={{ color: '#EB5A12' }} />
                          <input
                            type="file"
                            accept=".pdf"
                            hidden
                            onChange={handleChangeCustomer('notaFiscalEquipamentos')}
                          />
                        </IconButton>
                      </>
                    )}
                    {isValidUrl(customer.notaFiscalEquipamentos) && (
                      <IconButton
                        onClick={() => (
                          typeof customer.notaFiscalEquipamentos === 'string' ? openPdf(customer.notaFiscalEquipamentos) : {})}
                      >
                        <FileOpenRoundedIcon sx={{ color: '#EB5A12' }} />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: customer.notaFiscalEquipamentos !== 'Sem documentos' ? !!customer.notaFiscalEquipamentos : false,
              }}
              variant="outlined"
              disabled
            />
          </Grid>
        </>
      )}
      {(project.status !== 'Aguardando NF' && project.status !== 'Projeto contratado' && project.status !== 'Condições escolhidas') && (
        <Typography sx={{
          fontSize: { xs: '12px', md: '16px' },
          fontFamily: 'Poppins',
          fontWeight: '400',
        }}
        >
          Aguarde a análise dos documentos do cliente para emitir a nota fiscal!
        </Typography>
      )}
      {project.status === 'Projeto contratado' && (
        <>
          <Grid xs={12} sx={{ mt: '2rem' }}>
            <Typography sx={{
              fontSize: {
                xs: '16px',
                md: '24px',
              },
              fontFamily: 'Poppins',
              color: '#EB5A12',
              fontWeight: '700',
            }}
            >
              Detalhes do depósito
            </Typography>
            <hr className={style.titleDivisor} />
            {(project.status === 'Projeto contratado') && (
              <Typography sx={{
                fontSize: {
                  xs: '12px',
                  md: '16px',
                },
                fontFamily: 'Poppins',
                fontWeight: '400',
              }}
              >
                O projeto foi contratado.
              </Typography>
            )}
          </Grid>
          <Grid xs={12} lg={3}>
            <TextField
              label={isValidUrl(project.receipt) ? '' : 'Comprovante de depósito não enviado'}
              fullWidth
              value={isValidUrl(project.receipt) ? 'comprovante_de_deposito.pdf' : handleFileName(project.receipt)}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    {isValidUrl(project.receipt) && (
                      <IconButton
                        onClick={() => (
                          typeof project.receipt === 'string' ? openPdf(project.receipt) : {})}
                      >
                        <FileOpenRoundedIcon sx={{ color: '#EB5A12' }} />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: !!project.receipt,
              }}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid xs={12} lg={2.4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disabled={isDisabled}
                label="Data do depósito"
                format="DD/MM/YYYY"
                value={project.dateDeposit
                  && dayjs(project.dateDeposit, 'DD/MM/YYYY').isValid()
                  ? dayjs(project.dateDeposit, 'DD/MM/YYYY') : null}
                sx={{ width: '100%' }}
              />
            </LocalizationProvider>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ProjectContract;
