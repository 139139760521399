import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';

interface Props {
  value: string,
  label: string,
  handleChange: Function,
  customError?: boolean,
}

const BaseDatePicker = (props: Props) => {
  const {
    value, handleChange, label, customError = false,
  } = props;

  // it returns the date as a string: dd/mm/yyyy
  const handleDateChange = (date: Dayjs | null) => {
    if (!date) return;
    const formattedDate = date.format('DD/MM/YYYY');
    handleChange(formattedDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        openTo="month"
        views={['year', 'month', 'day']}
        format="DD/MM/YYYY"
        maxDate={dayjs()}
        disableFuture
        value={value && dayjs(value, 'DD/MM/YYYY').isValid() ? dayjs(value, 'DD/MM/YYYY') : null}
        slotProps={{ textField: { error: customError } }}
        onChange={(newValue) => handleDateChange(newValue)}
        sx={{ flexGrow: 1, width: '100%' }}
      />
    </LocalizationProvider>
  );
};

export default BaseDatePicker;
