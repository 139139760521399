import { NavLink } from 'react-router-dom';
import { parseCookies } from 'nookies';
import 'react-chat-elements/dist/main.css';
import { ChatItem, MessageBox } from 'react-chat-elements';
import { Toast } from 'react-toastify/dist/components';
import { toast, ToastContainer } from 'react-toastify';
import style from './post.module.scss';
import { TestBuild } from '../TestBuild';
import logo from '../../assets/images/soltech.svg';
import { BotaoUsuario } from '../BotaoUsuario';
import pdfIcon from '../../assets/images/downloadPDF.png';
import { api } from '../../services/api';

interface Mensagem {
  userID: number,
  senderName: string;
  receiverName: string;
  message: string;
  isFile: boolean,
  fileTitle: string,
  filePath: string,
  direction: string,
  created_at: string
}

interface Props {
  dataProp: Mensagem;
}
// @ts-ignore
const Post = (props: Props) => {
  const { dataProp } = props;
  const date = new Date();
  let direction = '';
  const getFile = async (urlx: string) => {
    const respo = await api.post('file/get', { data: urlx });

    if (respo.status === 403) {
      toast.error('Não foi possível acessar o arquivo.');
    }
    return respo.data;
  };
  const openFile = async (file: string) => {
    const furl = await getFile(file);
    window.open(furl, '_blank');
  };

  const getUserId = () => {
    const { 'nextauth.tag': userId } = parseCookies();
    return parseInt(userId, 10);
  };

  direction = dataProp.userID === getUserId() ? 'right' : 'left';

  // @ts-ignore
  return (
    <div className={style.App}>
      {dataProp.isFile ? (
        <MessageBox
          // @ts-ignore
          position={direction}
          type="file"
          title={dataProp.senderName}
          text={dataProp.fileTitle}
          // @ts-ignore
          date={dataProp.created_at}
          dateString={dataProp.created_at}
          onClick={() => {
            openFile(dataProp.filePath);
          }}
          data={{
            uri: 'https://www.sample-videos.com/pdf/Sample-pdf-5mb.pdf',
            status: {
              autoDownload: true,
              click: true,
              loading: 0,
            },
          }}
        />
      ) : (
        <MessageBox
          // @ts-ignore
          position={direction}
          type="text"
          text={dataProp.message}
          title={dataProp.senderName}
          // @ts-ignore
          date={dataProp.created_at}
          dateString={dataProp.created_at}
          titleColor="#ea5b13"
        />
      )}
    </div>
  );
};
export { Post };
