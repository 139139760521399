import { parseCookies } from 'nookies';
import { FC } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';

interface PrivateRouteProps extends RouteProps {}

export const PrivateRoute: FC<PrivateRouteProps> = ({ ...rest }) => {
  const { 'nextauth.token': accessToken } = parseCookies();

  const history = useHistory();
  // If there is no access token we redirect to "/" page.
  if (!accessToken) {
    history.replace('/Login');
  }

  return <Route {...rest} />;
};
