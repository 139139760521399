import { NavLink } from 'react-router-dom';
import { parseCookies, setCookie } from 'nookies';
import { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import style from './stepperproject.module.scss';
import { api } from '../../services/api';

interface Props {
  activePage: number;
  projectId: number;
  status?: string;
}

const StepperProject = ({ activePage, projectId, status = 'Ativo' }: Props) => {
  const { 'nextauth.stepper': cookieRoles } = parseCookies();

  return (
    <div className={style.stepper}>
      <nav>
        <ul>
          <li className={style.stepperDivisor}>
            Projeto #
            {projectId === 0 ? 'Novo' : projectId }
          </li>
          <li>
            <span
              className={activePage === 1 ? style.stepperOn : style.stepperOff}
            >
              {status !== 'Inativo'
                ? (
                  <NavLink exact to="/NovoProjeto">
                    <span>&nbsp;1</span>
                    &nbsp;Cotação
                  </NavLink>
                ) : (
                  <NavLink exact to="/AnaliseCredito">
                    <span>&nbsp;1</span>
                    &nbsp;Cotação
                  </NavLink>
                )}
            </span>
          </li>
          <li>
            <span
              className={activePage === 2 ? style.stepperOn : style.stepperOff}
            >
              {status !== 'Inativo'
                ? (
                  <NavLink exact to="/CadastroCliente">
                    <span>&nbsp;2</span>
                    {' '}
                    Cliente
                  </NavLink>
                ) : (
                  <NavLink exact to="/AnaliseCredito">
                    <span>&nbsp;3</span>
                    {' '}
                    Cliente
                  </NavLink>
                )}
            </span>
          </li>
          <li>
            <span
              className={activePage === 3 ? style.stepperOn : style.stepperOff}
            >
              {status !== 'Inativo'
                ? (
                  <NavLink exact to="/AnaliseCredito">
                    <span>&nbsp;3</span>
                    {' '}
                    Análise
                  </NavLink>
                ) : (
                  <NavLink exact to="/AnaliseCredito">
                    <span>&nbsp;3</span>
                    {' '}
                    Análise
                  </NavLink>
                )}
            </span>
          </li>
          <li>
            <span
              className={activePage === 4 ? style.stepperOn : style.stepperOff}
            >
              {status !== 'Inativo'
                ? (
                  <NavLink exact to="/Contrato">
                    <span>&nbsp;4</span>
                    {' '}
                    Contrato
                  </NavLink>
                ) : (
                  <NavLink exact to="/AnaliseCredito">
                    <span>&nbsp;4</span>
                    {' '}
                    Contrato
                  </NavLink>
                )}
            </span>
          </li>
          <li>
            <span
              className={activePage === 5 ? style.stepperOn : style.stepperOff}
            >
              {status !== 'Inativo'
                ? (
                  <NavLink exact to="/Debito">
                    <span>&nbsp;5</span>
                    {' '}
                    Débito
                  </NavLink>
                ) : (
                  <NavLink exact to="/AnaliseCredito">
                    <span>&nbsp;5</span>
                    {' '}
                    Débito
                  </NavLink>
                )}
            </span>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export { StepperProject };
