import './aceitecadastros.module.scss';
import React, { useEffect, useRef, useState } from 'react';
import { AxiosResponse } from 'axios';
import { ToastContainer } from 'react-toastify';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { Box, Typography } from '@mui/material';
import { api } from '../../services/api';
import style from '../MeusProjetos/meusprojetos.module.scss';
import { DialogComponent } from '../../components/DialogComponent';
import IntegratorsTable from '../../components/IntegratorsTable';

interface User {
  passwordChanged: boolean;
  id: number;
}

interface IntegratorData {
  id: number;
  razaoSocial: string;
  cnpj: string;
  telefone: string;
  cidade: string;
  estado: string;
  ativo: string;
  email: string;
  user: User;
}

interface IntegratorStatus {
  ativo: number;
  pendente: number;
  recusado: number;
  semDocumentos: number;
}

export function AceiteCadastros() {
  const [integrators, setIntegrators] = useState<IntegratorData[]>([]);
  const [integratorsQuantity, setIntegratorsQuantity] = useState<IntegratorStatus>();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const getIntegratorNumbers = async () => {
      setIsOpen(true);
      const response: Promise<AxiosResponse<any, any>> = api.post(
        'integrator/numbers/get/',
        '',
      );
      response.then((resolved) => {
        setIntegratorsQuantity(resolved.data);
      });
    };

    const getIntegrators = async () => {
      setIsOpen(true);
      const response: Promise<AxiosResponse<any, any>> = api.post(
        'integrator/list/get',
        '',
      );
      response.then((resolved) => {
        const items: IntegratorData[] = resolved.data;
        setIntegrators(items);
        return items;
      });
    };

    getIntegratorNumbers();
    getIntegrators();
    setIsOpen(false);
  }, []);

  const ref = useRef<LoadingBarRef>(null);

  const cardStyle = {
    backgroundColor: '#fff',
    boxShadow: '0px 2px 2px 0px #0000001A',
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: '1rem',
    minHeight: '82vh',
  };

  return (
    <Box sx={cardStyle}>
      <LoadingBar color="#f11946" ref={ref} />
      <Typography sx={{
        fontSize: { xs: '16px', md: '24px' },
        fontFamily: 'Poppins',
        color: '#EB5A12',
        fontWeight: '700',
      }}
      >
        Aceite Integradora
      </Typography>
      <hr className={style.divisor} />
      <IntegratorsTable
        integrators={integrators}
        integratorsQt={integratorsQuantity || {
          ativo: 0, recusado: 0, pendente: 0, semDocumentos: 0,
        }}
      />
      <ToastContainer />
      <DialogComponent isOpen={isOpen} labelText="Carregando dados" />
    </Box>
  );
}
