import { Box } from '@mui/material';
import { ReactNode } from 'react';

interface Props{
  children: ReactNode;
}
const Card = (props: Props) => {
  const {
    children,
  }: Props = props;

  const cardStyle = {
    backgroundColor: '#fff',
    boxShadow: '0px 2px 2px 0px #0000001A',
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: '1rem',
    minHeight: 'fit-content',
  };
  return (
    <Box sx={cardStyle}>
      {children}
    </Box>
  );
};

export default Card;
