const emailMask = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const rules = {
  // eslint-disable-next-line eqeqeq
  required: (value: any) => !value || value === '0' || value === 0 || value.length === 0,
  email: (value: any) => !emailMask.test(value),
  optionalEmail: (value: any) => !emailMask.test(value) && value.toString().length > 0,
  cpf: (value: any) => value.length < 14,
  phone: (value: any) => value.length < 13,
  date: (value: any) => new Date(value).toLocaleDateString() !== 'Invalid Date',
  cep: (value: any) => value.length < 9,
};

export const checkAllRules = (rulesList: boolean[]) => rulesList.some((item) => item);
