import React, { useEffect, useRef, useState } from 'react';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { AxiosResponse } from 'axios';
import { ToastContainer } from 'react-toastify';
import { Box, Typography } from '@mui/material';
import style from './listaoperador.module.scss';
import { api } from '../../services/api';
import { DialogComponent } from '../../components/DialogComponent';
import OperatorsTable from '../../components/OperatorsTable';
import { sortArray } from '../../utils/util';

export interface OperatorData {
  id: number;
  nome: string;
  email: string;
  status: string;
}

export function ListaOperador() {
  const ref = useRef<LoadingBarRef>(null);
  const [operators, setOperators] = useState<Array<OperatorData>>([]);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const getOperators = async () => {
      const response: Promise<AxiosResponse<any, any>> = api.post('/user/operator/all/get');
      response.then((resolved) => {
        const items: OperatorData[] = resolved.data;
        setOperators(items);
        setLoading(false);
        return items;
      });
    };
    getOperators();
  }, []);

  const sortOperators = (attribute: keyof OperatorData) => {
    setOperators(sortArray(operators, attribute));
  };

  const cardStyle = {
    backgroundColor: '#fff',
    boxShadow: '0px 2px 2px 0px #0000001A',
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: '1rem',
    minHeight: '82vh',
  };
  return (
    <Box sx={cardStyle}>
      <LoadingBar color="#f11946" ref={ref} />
      <Typography sx={{
        fontSize: { xs: '16px', md: '24px' },
        fontFamily: 'Poppins',
        color: '#EB5A12',
        fontWeight: '700',
      }}
      >
        Operadores
      </Typography>
      <hr className={style.divisor} />
      <ToastContainer />
      <OperatorsTable operators={operators} sortOperators={sortOperators} />
      <DialogComponent isOpen={loading} labelText="Carregando dados" />
    </Box>
  );
}
