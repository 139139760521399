import React, { useEffect, useRef, useState } from 'react';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { parseCookies } from 'nookies';
import { ToastContainer } from 'react-toastify';
import { Box, Typography } from '@mui/material';
import style from './listavendedor.module.scss';
import { api } from '../../services/api';
import { DialogComponent } from '../../components/DialogComponent';
import VendorsTable from '../../components/VendorsTable';
import { sortArray } from '../../utils/util';
import { OperatorData } from '../ListaOperador';

export interface VendorData {
  id: number;
  nome: string;
  email: string;
  status: string;
  passwordChanged: boolean;
}

export function ListaVendedor() {
  const ref = useRef<LoadingBarRef>(null);
  const [vendors, setVendors] = useState<Array<VendorData>>([]);
  const history = useHistory();
  const { 'nextauth.tag': tag } = parseCookies();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getVendors = async () => {
      setLoading(true);
      const data = {
        userId: parseInt(tag, 10),
      };
      const response: Promise<AxiosResponse<any, any>> = api.post('/user/vendor/all/get', data);
      response.then((resolved) => {
        const items: Array<VendorData> = resolved.data;
        setLoading(false);
        items.sort((a, b) => ((a.status < b.status) ? 1 : -1));
        setVendors(items);
        return items;
      });
    };
    getVendors();
  }, [tag]);

  const sortVendors = (attribute: keyof VendorData) => {
    setVendors(sortArray(vendors, attribute));
  };

  const cardStyle = {
    backgroundColor: '#fff',
    boxShadow: '0px 2px 2px 0px #0000001A',
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: '1rem',
    minHeight: '82vh',
  };
  return (
    <Box sx={cardStyle}>
      <LoadingBar color="#f11946" ref={ref} />
      <Typography sx={{
        fontSize: { xs: '16px', md: '24px' },
        fontFamily: 'Poppins',
        color: '#EB5A12',
        fontWeight: '700',
      }}
      >
        Meus vendedores
      </Typography>
      <hr className={style.divisor} />
      <ToastContainer />
      <VendorsTable vendors={vendors} sortVendors={sortVendors} />
      <DialogComponent isOpen={loading} labelText="Carregando dados" />
    </Box>
  );
}
