import React, {
  ChangeEvent, useEffect, useRef, useState,
} from 'react';
import {
  TableCell,
  TableHead,
  Paper,
  TableContainer,
  TableRow,
  TableBody,
  Table,
  TablePagination,
  Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { parseCookies } from 'nookies';
import { timeStampToDateOnly } from '../../utils/util';
import { IssueData } from '../../pages/ListaChamados';

interface IssuesTableProps {
  issues: Array<IssueData>
  sortIssues: Function
}
interface Column {
  id: 'id' | 'assunto' | 'ownerIssues' | 'created_at' | 'status' | 'resolvido' | 'actions'
  label: string
  minWidth?: number
  align?: 'right' | 'center' | 'left'
  format?: (value: number) => string
}

const IssuesTable = (props: IssuesTableProps) => {
  const {
    issues,
    sortIssues,
  }: IssuesTableProps = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const history = useHistory();

  const { 'nextauth.roles': cookieRoles } = parseCookies();
  const isManager = cookieRoles === 'Gerente';

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [columns, setColumns] = useState<readonly Column[]>([
    { id: 'id', label: 'Código', align: 'left' },
    { id: 'assunto', label: 'Assunto', align: 'left' },
    { id: 'ownerIssues', label: 'Operador', align: 'left' },
    { id: 'created_at', label: 'Aberto em', align: 'left' },
    { id: 'status', label: 'Status', align: 'left' },
    { id: 'resolvido', label: 'Aberto', align: 'left' },
    { id: 'actions', label: '', align: 'left' },
  ]);

  const ref = useRef<LoadingBarRef>(null);

  const handleClickDetails = (issue: IssueData) => {
    history.push({
      pathname: '/DetalheChamado',
      state: { id: issue.id },
    });
  };

  const handleStatusColor = (status: string) => {
    if (status === 'Criado') return 'blue';
    if (status === 'Aberto') return 'blue';
    if (status === 'Não resolvido') return 'red';
    if (status === 'Fechado') return 'green';
    return '#000';
  };

  useEffect(() => {
    if (!isManager) {
      setColumns(columns.filter((col) => col.id !== 'resolvido'));
    }
    // If Use-effect is changed run without the disabled eslint to check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isManager]);

  return (
    <div>
      <LoadingBar color="#f11946" ref={ref} />
      <Paper sx={{
        width: '100%',
        overflow: 'hidden',
        boxSizing: 'border-box',
        boxShadow: 'none',
      }}
      >
        <TableContainer sx={{ maxHeight: 490 }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    onClick={() => sortIssues(column.id)}
                    sx={{
                      userSelect: 'none',
                      cursor: (
                        column.id === 'id'
                        || column.id === 'assunto'
                        || column.id === 'ownerIssues'
                        || column.id === 'created_at'
                        || column.id === 'status'
                        || column.id === 'resolvido'
                      ) ? 'pointer' : 'arrow',
                      '&:hover': {
                        textShadow: '1px 1px 1px #0000005A',
                      },
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {issues && (
                issues.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: IssueData) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell>
                        {row.id}
                      </TableCell>
                      <TableCell>
                        {row.assunto}
                      </TableCell>
                      <TableCell>
                        {row.ownerIssues.name}
                      </TableCell>
                      <TableCell>
                        {timeStampToDateOnly(row.created_at)}
                      </TableCell>
                      <TableCell
                        sx={{ color: handleStatusColor(row.status) }}
                      >
                        {row.status}
                      </TableCell>
                      {isManager && (
                        <TableCell
                          sx={{ color: row.resolvido === 'Com resposta' ? 'green' : 'blue' }}
                        >
                          {row.resolvido}
                        </TableCell>
                      )}
                      <TableCell align="right">
                        <Typography
                          onClick={() => handleClickDetails(row)}
                          sx={{
                            fontSize: '12px',
                            fontFamily: 'Poppins',
                            color: '#EB5A12',
                            fontWeight: '700',
                            cursor: 'pointer',
                            userSelect: 'none',
                            '&:hover': {
                              textShadow: '1px 1px 1px #0000005A',
                            },
                          }}
                        >
                          Detalhes
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
              )}
              {(!issues || issues.length === 0) && (
                <TableRow
                  hover
                  role="checkbox"
                >
                  <TableCell>
                    Nenhum projeto cadastrado!
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={issues.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Linhas por página"
        />
      </Paper>
    </div>
  );
};
export default IssuesTable;
