import Dialog from '@mui/material/Dialog';
import { pdfjs, Document, Page } from 'react-pdf';
import React, { useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import {
  createTheme,
  IconButton, Stack, ThemeProvider, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import style from './pdfviewer.module.scss';

interface Props {
  isOpen: boolean
  url: string
  close: Function
}
const PDFViewer = (props: Props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  const { url, isOpen, close } = props;

  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageWidth, setPageWidth] = useState(0);
  const [showContainer, setShowContainer] = useState<boolean>(false);
  const bkTheme = useTheme();
  const isMobileScreen = useMediaQuery(bkTheme.breakpoints.down('lg'));

  const theme = createTheme({
    components: {
      MuiDialogContent: {
        styleOverrides: {
          root: {
            // webkit for chromium based browsers
            '&::-webkit-scrollbar': {
              width: '0.3em',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#EB5A12',
              outline: '1px solid slategrey',
            },
            // Firefox only css
            scrollbarWidth: 'thin',
            scrollbarColor: '#EB5A12 #F9F9F9',
          },
        },
      },
    },
  });

  const handleClose = () => {
    close(false);
    setShowContainer(false);
    setPageNumber(1);
  };

  const onDocumentLoadSuccess = (pdfInfo: { numPages: number }) => {
    setPageWidth(window.innerWidth);
    setShowContainer(true);
    setNumPages(pdfInfo.numPages);
  };

  const changePage = (offset: number) => {
    setPageNumber(pageNumber + offset);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="xl">
      {showContainer && (
      <Stack
        sx={{
          padding: '0.5rem 1rem',
        }}
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <IconButton
          sx={{
            color: 'black',
          }}
          onClick={handleClose}
        >
          <CloseIcon height="20px" />
        </IconButton>
      </Stack>
      )}
      <ThemeProvider theme={theme}>
        <DialogContent
          sx={{
            paddingTop: 0,
          }}
        >
          <Document
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
            loading="Carregando PDF..."
          >
            <Page
              key={pageNumber}
              pageNumber={pageNumber}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              width={isMobileScreen ? 700 : 1100}
            />
          </Document>
        </DialogContent>
      </ThemeProvider>
      {showContainer && (
        <>
          <div
            style={{ padding: '0 1rem' }}
          >
            <hr className={style.divisor} />
          </div>
          <Stack
            sx={{
              padding: '1rem',
            }}
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Typography
              sx={{
                fontSize: '12px',
                marginRight: '10px',
              }}
            >
              {`${pageNumber} de ${numPages}`}
            </Typography>
            <IconButton
              sx={{
                border: 'black solid 1px',
                borderRadius: 1,
                p: 0,
                m: 0,
                marginRight: '5px',
              }}
              disabled={pageNumber <= 1}
              onClick={() => changePage(-1)}
            >
              <KeyboardArrowLeftIcon height="20px" />
            </IconButton>
            <IconButton
              sx={{
                border: 'black solid 1px',
                borderRadius: 1,
                p: 0,
                m: 0,
              }}
              disabled={pageNumber >= numPages}
              onClick={() => changePage(1)}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </Stack>
        </>
      )}
    </Dialog>
  );
};
export default PDFViewer;
