import { toast } from 'react-toastify';
import { api } from '../services/api';

export const getFile = async (urlx: String) => {
  const respo = await api.post('file/get', { data: urlx });

  if (respo.status === 403) {
    toast.error('Não foi possível acessar o arquivo.');
  }

  return respo.data;
};
