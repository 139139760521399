import React, {
  Component,
  ChangeEvent,
  useState,
  ChangeEventHandler, SyntheticEvent,
} from 'react';
import Select, { ActionMeta } from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { parseCookies } from 'nookies';
import style from './debito.module.scss';

import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { Navigator } from '../../components/Navigator';
import { FormPage } from '../../components/FormPage';

import enelLogo from '../../assets/images/enel.png';

import { RequiredFieldMark } from '../../components/RequiredFieldMark';
import { StepperProject } from '../../components/StepperProject';

export function Debito() {
  const [fornecedora, setFornecedora] = useState('');
  const getProjectId = () => {
    const { 'nextauth.p': cprojId } = parseCookies();
    return parseInt(cprojId, 10);
  };
  const handleSubmit = () => {
    toast.info('Em breve!!!');
  };
  const handleFornecedoraChange = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setFornecedora(e.target.value);
  };

  return (
    <div>
      <StepperProject activePage={5} projectId={getProjectId()} />
      <br />
      <br />
      <FormPage>
        <br />
        <form>
          <h1>Cadastrar Débito</h1>
          <div>
            <label>
              Número do Cliente
              <input type="text" placeholder="123456-8" />
            </label>
            <label>
              Número de Parcelas
              <input type="number" placeholder="24" min="0" max="48" />
            </label>
            <label>
              Valor Parcela
              <input type="text" placeholder="R$ 240,00" />
            </label>
            <label>
              Fornecedora
              <RequiredFieldMark />
              <select
                value={fornecedora}
                className={style.debitoSelect}
                onChange={handleFornecedoraChange}
                id="fornecedora"
              >
                <option value="1">Enel</option>
                <option value="2">Outra</option>
              </select>
            </label>
          </div>
          <br />
          <br />
          <button type="button" onClick={handleSubmit}>
            Enviar à Fornecedora&nbsp;&gt;
          </button>
        </form>
        <br />
        <br />
      </FormPage>
      <ToastContainer />
    </div>
  );
}
