import { Box } from '@mui/material';
import { DialogBodyComponent } from '../../DialogBodyComponent';

interface Props {
  isModalOpen: boolean
  localClose: Function
  localConfirm: Function
}

export const ConfirmCobranca = (props: Props) => {
  const {
    isModalOpen, localClose, localConfirm,
  } = props;
  return (
    <DialogBodyComponent
      title="Cobrança em Conta"
      helperText="Confirma os dados?"
      cancelButtonText="Voltar"
      confirmButtonText="Confirmar"
      isOpen={isModalOpen}
      close={localClose}
      confirm={localConfirm}
    >
      <Box
        height={25}
      />
    </DialogBodyComponent>
  );
};
