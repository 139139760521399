import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import {
  DataGrid, GridColDef, GridValueFormatterParams,
} from '@mui/x-data-grid';
import {
  Box, useMediaQuery, useTheme,
} from '@mui/material';
import { DialogComponent } from '../../components/DialogComponent';
import { api } from '../../services/api';
import { applyCurrencyMask } from '../../utils/util';
import { SectionTitle } from '../../components/elements/SectionTitle';

interface ProjectsReports {
  month: string,
  newProjects: string,
  newProjectsValue: number,
  newProjectsDone: string,
  newProjectsDoneValue: number,
}

export function RelatorioVendasAnual() {
  const [projectsReports, setProjectsReports] = useState<ProjectsReports[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  // const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  // const isMd = useMediaQuery(theme.breakpoints.only('md'));
  // const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  // const isXl = useMediaQuery(theme.breakpoints.only('xl'));

  const cardStyle2 = {
    backgroundColor: '#fff',
    borderRadius: '10px',
    minHeight: 'fit-content',
    minWidth: '900px',
    padding: { xs: '1rem', sm: '2%' },
    margin: '0 auto',
    overflowX: { xs: 'auto', md: 'hidden' },
    scrollbarColor: '#EA5B13 lightgrey',
  };

  useEffect(() => {
    setIsOpen(true);
    setLoadingText('Carregando dados...');
    const getTableData = async () => {
      api.post('/user/vendor/sales/data/get').then((resp) => {
        const { data } = resp;
        setProjectsReports(data);
        setIsOpen(false);
      });
    };

    getTableData();
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'month',
      headerName: 'Mês/Ano',
      type: 'string',
      flex: 0.5,
      sortable: true,
      align: 'left',
    },
    {
      field: 'newProjects',
      headerName: 'Projetos simulados (Total)',
      description: 'Quantidade total de projetos simulados na Soltech no mês.',
      type: 'number',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      sortable: true,
    },
    {
      field: 'newProjectsValue',
      headerName: 'Projetos simulados (R$)',
      description: 'Valor total de projetos simulados na Soltech no mês.',
      type: 'number',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      sortable: true,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return '';
        }
        return `${applyCurrencyMask(params.value.toString(10))}`;
      },
    },
    {
      field: 'newProjectsDone',
      headerName: 'Projetos contratados (Total)',
      description: 'Quantidade total de projetos contratados na Soltech no mês.',
      type: 'number',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      sortable: true,
    },
    {
      field: 'newProjectsDoneValue',
      headerName: 'Projetos contratados (Total)',
      description: 'Valor total de projetos simulados na Soltech no mês.',
      type: 'number',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      sortable: true,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return '';
        }
        return `${applyCurrencyMask(params.value.toString())}`;
      },
    },
  ];

  return (
    <>
      <Box sx={cardStyle2}>
        <>
          <SectionTitle title="Relatório de simulações e contratações dos últimos 12 meses" />
          <DataGrid
            sx={{
              '& .MuiDataGrid-columnHeader': {
                fontFamily: 'Poppins',
                fontSize: '12px',
                wordBreak: 'break-word',
              },
              '& .MuiDataGrid-row': {
                fontFamily: 'Poppins',
                fontSize: '12px',
                fontWeight: 400,
              },
            }}
            rowHeight={isXs ? 30 : 45}
            disableRowSelectionOnClick
            rows={projectsReports}
            getRowId={(row) => row.month}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 12,
                },
              },
            }}
            pageSizeOptions={[12]}
          />
        </>
      </Box>
      <DialogComponent isOpen={isOpen} labelText={loadingText} />
      <ToastContainer />
    </>
  );
}
