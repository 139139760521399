import {
  Address, Associate, BankAccount, BankBacen, City, Customer, Country, CreditOption,
  EntryQuotation, HelteToken,
  Integrator, IntegratorEvaluation, Issue, Modification, Person, Project, ProjectUpdate,
  State, User, UserRole, Bank, Vendor, Operator, FormField,
} from '../@types';

export const defaultModification: Modification = {
  codAttribute: '',
  created_at: '',
  id: 0,
  issueId: 0,
  name: '',
  newValue: '',
  formFieldId: 0,
};

export const defaultIssue: Issue = {
  assunto: '',
  closed_at: '',
  comprovanteRenda: 'Sem documentos',
  contaLuzRecente: 'Sem documentos',
  created_at: '',
  descricao: '',
  documentoIdentificacao: 'Sem documentos',
  id: 0,
  iptu: 'Sem documentos',
  managerId: 0,
  modificationId: 0,
  motivos: '',
  notaFiscalEquipamentos: 'Sem documentos',
  notaFiscal: 'Sem documentos',
  ownerId: 0,
  projetoId: 0,
  resolvido: false,
  status: '',
};

export const defaultProjectUpdate: ProjectUpdate = {
  changed_at: '',
  created_at: '',
  id: 0,
  new_Status: '',
  old_Status: '',
  projectId: 0,
  userId: 0,
};

export const defaultBankBacen: BankBacen = {
  COMPE: '',
  Charge: '',
  CreditDocument: 0,
  DateOperationStarted: '',
  DatePixStarted: '',
  DateRegistered: '',
  DateUpdated: '',
  DetectaFlow: 0,
  Document: '',
  ISPB: '',
  LegalCheque: 0,
  LongName: '',
  Network: '',
  PixType: '',
  Products: '',
  SalaryPortability: '',
  ShortName: '',
  Type: '',
  Url: '',
  createdAt: '',
  id: 0,
  updateAt: '',
};

export const defaultBankAccount: BankAccount = {
  accountNumber: '',
  agency: '',
  bankId: 0,
  id: 0,
};

export const defaultBank: Bank = {
  id: 0,
  name: '',
  email: '',
  active: false,
  cod: '',
};

export const defaultUserRole: UserRole = {
  id: 0,
  roleName: '',
};

export const defaultUser: User = {
  id: 0,
  name: '',
  cpf: '',
  phone: '',
  email: '',
  password: '',
  userAvatar: '',
  active: false,
  integradoraId: 0,
  passwordChanged: false,
};

export const defaultHelteToken: HelteToken = {
  id: '',
  stateCheck: '',
  token: '',
  userId: 0,
};

export const defaultIntegrator: Integrator = {
  addressId: 0,
  bankAccountId: 0,
  cnpj: '',
  dataFundacao: '',
  docBalanco: '',
  docCat: '',
  docContrato: '',
  docCrea: '',
  docCrp: '',
  id: 0,
  name: '',
  numWorkers: 0,
  phoneNumber: '',
  potencyW: 0,
  status: '',
  userId: 0,
};

export const defaultPerson: Person = {
  coporationId: 0,
  cpf: '',
  id: 0,
  name: '',
  phoneNumber: '',
  userId: 0,
};

export const defaultIntegratorEvaluation: IntegratorEvaluation = {
  datahora: '',
  empresaId: 0,
  homologaBalancete: false,
  homologaCat: false,
  homologaContrato: false,
  homologaCrea: false,
  homologaCrp: false,
  id: 0,
  justificativa: '',
  userHomologadorId: 0,
};

export const defaultCountry: Country = {
  id: 0,
  name: '',
};

export const defaultState: State = {
  acronym: '',
  id: 0,
  name: '',
};

export const defaultCity: City = {
  id: 0,
  name: '',
  stateId: 0,
};

export const defaultAddress: Address = {
  cityId: 0,
  district: '',
  id: 0,
  number: 0,
  street1: '',
  street2: '',
  zip: '',
};

export const defaultAssociate: Associate = {
  cpf: '',
  created_at: '',
  email: '',
  id: 0,
  motherName: '',
  name: '',
  phone: '',
};

export const defaultCustomer: Customer = {
  capitalSocial: 0,
  cnpj: '',
  company_link: false,
  cpf: '',
  created_at: 0,
  dataAbertura: '',
  dataNascimento: '',
  email: '',
  emailEmpresa: '',
  enderecoInstalacaoId: 0,
  estadoCivil: '',
  faturamentoMensal: '',
  faturamentoMensalEmpresa: '',
  id: '',
  mediaContaLuz: '',
  nome: '',
  nomeFantasia: '',
  nomeMae: '',
  profissao: '',
  razaoSocial: '',
  rg: '',
  telefone: '',
  telefoneComercial: '',
  tempoProfissao: 0,
  valorPatrimonio: '',
  proposal: null,
  contaLuzRecente: null,
  documentoIdentificacao: null,
  comprovanteRenda: null,
  iptu: null,
  electricityBillOwnership: true,
  electricityBillFamiliarType: 0,
  status: '',
  notaFiscalEquipamentos: '',
  potenciaInstalada: 0,
};

export const defaultCreditOption: CreditOption = {
  banco: '',
  bancoId: 0,
  bancoDoc: '',
  id: 0,
  parcelas: 0,
  projetoId: 0,
  taxa: 0,
  valorParcela: 0,
};

export const defaultEntryQuotation: EntryQuotation = {
  banco: '',
  caa: 0,
  cam: 0,
  id: 0,
  parcela: 0,
  projetoId: 0,
  taa: 0,
  tam: 0,
};

export const defaultProject: Project = {
  accountDeposit: 0,
  chosen_analysisId: 0,
  budgetId: 0,
  bankDealId: 0,
  carencia: 0,
  checkDeposit: false,
  clientId: 0,
  cobrancaEmConta: false,
  dataCriacao: '',
  dateDeposit: '',
  id: 0,
  invoice: '',
  lastStatus: '',
  nome: '',
  potencia: 0,
  proposal: null,
  receipt: '',
  status: '',
  type: '',
  updateAt: '',
  userId: 0,
  valorEntrada: '0,00',
  valorTotal: '',
  powerCost: 0,
};

export const defaultVendor: Vendor = {
  name: '',
  email: '',
  cpf: '',
  phone: '',
};

export const defaultOperator: Operator = {
  name: '',
  email: '',
  cpf: '',
  phone: '',
  password: '',
};

export const defaultFormField: FormField = {
  id: 0,
  name: '',
  codAttribute: '',
};
