import { ReactNode } from 'react';
import style from './baloon.module.scss';

interface Props {
  mode: string;
  children: ReactNode;
}

const Baloon = ({ children, mode }: Props) => {
  let className = '';

  if (mode === 'warning') {
    className = style.warning;
  } else {
    className = style.clean;
  }

  return <div className={className}>{children}</div>;
};

export { Baloon };
