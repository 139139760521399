import React, {
  ChangeEvent, useRef, useState,
} from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {
  TableCell,
  TableHead,
  Paper,
  TableContainer,
  TableRow,
  TableBody,
  Table,
  TablePagination,
  Typography,
  Box,
  TextField,
  InputAdornment,
} from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { parseCookies, setCookie } from 'nookies';
import { useHistory } from 'react-router-dom';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import style from '../../pages/MeusProjetos/meusprojetos.module.scss';
import { ProjetoStatus, ProjectCostumerData } from '../../pages/ListaEnvioCotacoes';
import { api } from '../../services/api';
import { DialogComponent } from '../DialogComponent';
import { applyCPFMask } from '../../utils/util';
import { projectStatusList } from '../../utils/Status';

interface QuotationsTableProps {
  projects: ProjectCostumerData[]
  projectsQt: ProjetoStatus
}
interface Column {
  id: 'code' | 'project' | 'cpf' | 'integrator' | 'status'| 'actions'
  label: string
  minWidth?: number
  align?: 'right' | 'center' | 'left'
  format?: (value: number) => string
}

interface FilterInput {
  input: string
}

const QuotationsTable = (props: QuotationsTableProps) => {
  const {
    projects,
    projectsQt,
  }: QuotationsTableProps = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const history = useHistory();
  const [inputType, setInputType] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const isActivateDisabled = (row: ProjectCostumerData) => row.projeto.status === 'Projeto contratado'
    || row.projeto.status === 'Financiamento recusado';
  const removeInativo = (value: string, index: number, arr: Array<any>) => {
    if (value === 'Inativo') {
      arr.splice(index, 1);
      return true;
    }
    return false;
  };
  const getActiveProjects = (arr: Array<string>) => {
    const arrayCopy = JSON.parse(JSON.stringify(arr));
    arrayCopy.filter(removeInativo);
    return arrayCopy;
  };
  const [filterText, setFilterText] = React.useState<any[]>(getActiveProjects(projectStatusList));
  const [filterInput, setFilterInput] = useState<Partial<FilterInput>>({});
  const projectFilter = () => {
    if (filterInput.input && inputType) {
      return projects.filter(
        (item) => item.cpf
          && filterInput.input && item.cpf.toLowerCase().includes(filterInput.input.toLowerCase()),
      );
    }

    if (filterInput.input && !inputType) {
      return projects.filter(
        (item) => item.nome
          && filterInput.input && item.nome.toLowerCase().includes(filterInput.input.toLowerCase()),
      );
    }
    return projects.filter(
      (item) => item.projeto.status
        && filterText?.includes(item.projeto.status),
    );
  };

  const filteredItems = projectFilter();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleIntegrator = (row: any) => {
    if (row.projeto.user === null) {
      return 'Sem usuário';
    }
    if (row.projeto.user.integradora === null) {
      return 'usuário sem integradora';
    }

    return row.projeto.user.integradora['name'];
  };

  const columns: readonly Column[] = [
    { id: 'code', label: 'Código', align: 'left' },
    { id: 'project', label: 'Projeto', align: 'left' },
    { id: 'cpf', label: 'CPF', align: 'left' },
    { id: 'integrator', label: 'Integradora', align: 'left' },
    { id: 'status', label: 'Status', align: 'left' },
    { id: 'actions', label: '', align: 'left' },
  ];

  const handleClickDetails = (row: ProjectCostumerData) => {
    setCookie(undefined, 'nextauth.p', String(row.projeto.id), {
      maxAge: 60 * 60 * 24 * 30, // 30 days,
      path: '/',
      sameSite: 'none',
      secure: true,
    });

    history.push({
      pathname: '/DetalheCotacao',
      state: { id: row.projeto.id },
    });
  };

  const ref = useRef<LoadingBarRef>(null);

  const activeProjectsNumber = () => {
    if (projects && projects.length > 0) {
      const arrayCopy = JSON.parse(JSON.stringify(projects));
      const numbers = arrayCopy.filter((item: ProjectCostumerData) => item.projeto.status !== 'Inativo');
      return numbers.length;
    }
    return 0;
  };
  const pagamentoStatusNumber = () => {
    let count = 0;
    if (projectsQt?.pagamentoNF) {
      count += projectsQt.pagamentoNF;
    }
    if (projectsQt?.pagamentoDocs) {
      count += projectsQt.pagamentoDocs;
    }
    if (projectsQt?.analiseDocs) {
      count += projectsQt.analiseDocs;
    }

    return count;
  };

  const getUserId = () => {
    const { 'nextauth.tag': userId } = parseCookies();
    return userId;
  };

  const handleClickActivate = async (projectId: number) => {
    const msg = 'Deseja alterar o status deste projeto?';

    let statusToCheck = '';
    let statusToSend = '';
    let logMessage: string;
    let value = 0;

    for (let i = 0; i < projects.length; i++) {
      if (projects[i].id.toString() === String(projectId)) {
        statusToCheck = projects[i].projeto.status;
        value = projects[i].id;
      }
    }

    if (statusToCheck.toLowerCase() === 'Inativo'.toLowerCase()) {
      logMessage = `O projeto ${projectId} foi ativado pelo operador ${getUserId()}`;
      setLoadingText('Ativando projeto...');
    } else {
      logMessage = `O projeto ${projectId} foi desativado pelo operador ${getUserId()}`;
      statusToSend = 'Inativo';
      setLoadingText('Desativando projeto...');
    }

    // eslint-disable-next-line no-alert
    if (window.confirm(msg)) {
      setIsOpen(true);
      const changeStatus = await api.post('/project/status/put', {
        data: {
          projectId,
          status: statusToSend,
          userId: getUserId(),
        },
      });

      if (changeStatus.data === 'STATUS_UPDATED') {
        toast.success(statusToSend.toLowerCase() === 'Inativo'.toLowerCase()
          ? 'Projeto Desativado' : 'Projeto Ativado');

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setIsOpen(false);
        toast.error('Houve um erro com sua solicitação, tente mais tarde!');
      }
    }
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      name,
      value,
    } = e.target;

    const firstInput = value.substring(0, 1);
    // Check if first user input is a number or not
    const isNumber = !Number.isNaN(Number(firstInput));
    setInputType(() => isNumber);

    let newValue: string;
    switch (isNumber) {
      case true:
        newValue = applyCPFMask(e);
        break;
      case false:
        newValue = value;
        break;
      default:
        newValue = value;
    }

    setFilterInput({
      ...filterInput,
      input: newValue,
    });
  };

  const filterCardStyle = {
    padding: '0.5rem 2rem 0.5rem 1rem',
    height: '4rem',
    backgroundColor: '#fff',
    color: '#000',
    cursor: 'pointer',
    transitionDuration: '0.1s',
    justifyContent: 'space-between',
  };

  const cardTextStyle = {
    fontSize: '12px',
    textAlign: 'left',
    fontWeight: '700',
    fontFamily: 'Poppins',
  };

  const counterStyle = {
    display: 'flex',
    height: '100%',
    gap: '1rem',
    alignItems: 'center',
  };
  const filterCard = (title: string, filter: string[], counter: Function | number) => {
    const clickedCardTest = (filterText[0] === filter[0] && filterText.length < 3 && title !== 'Ativos') || (filterText.length > 2 && title === 'Ativos');
    return (
      <Button
        sx={{
          ...filterCardStyle,
          boxShadow: clickedCardTest ? 'black 0.75px 0.75px 3px' : 'lightgray 0.25px 0.25px 3px',
        }}
        fullWidth
        type="button"
        onClick={() => {
          setFilterInput({
            ...filterInput,
            input: '',
          });
          setFilterText(filter);
        }}
      >
        <Typography sx={cardTextStyle}>{title}</Typography>
        <Box sx={counterStyle}>
          <div
            style={{ border: clickedCardTest ? 'solid #ff4343 1px' : 'solid lightgray 1px' }}
            className={style.filterButtonDivider}
          />
          <Typography sx={cardTextStyle}>{counter}</Typography>
        </Box>
      </Button>
    );
  };

  const projectStatusColor = (status: string) => {
    if (status === 'Financiamento recusado') {
      return 'red';
    } if (status === 'Aguardando escolha das condições') {
      return '#000';
    } if (status === 'Condições escolhidas') {
      return 'blue';
    } if (status === 'Financiamento aprovado') {
      return 'green';
    } if (status === 'Análise - Bancos') {
      return 'purple';
    } if (status === 'Análise - Soltech') {
      return 'orange';
    } if (status === 'Documentos recusados') {
      return 'red';
    }
    return '#000';
  };
  return (
    <div>
      <LoadingBar color="#f11946" ref={ref} />
      <Box
        sx={{
          backgroundColor: '#F2F5F9',
          padding: '1rem',
          borderRadius: '0.5rem',
          gap: '1rem',
        }}
      >
        <Grid
          container
          spacing={2}
          rowSpacing={2}
        >
          <Grid lg={2}>
            {filterCard('Ativos', getActiveProjects(projectStatusList), activeProjectsNumber())}
          </Grid>
          <Grid lg={2}>
            {filterCard('Cadastro', ['Cadastro'], projectsQt?.cadastro)}
          </Grid>
          <Grid lg={2}>
            {filterCard('Inativo', ['Inativo'], projectsQt?.inativo)}
          </Grid>
          <Grid lg={2}>
            {filterCard('Análise - Soltech', ['Análise - Soltech'], projectsQt?.analiseSoltech)}
          </Grid>
          <Grid lg={2}>
            {filterCard('Análise - Bancos', ['Análise - Bancos'], projectsQt?.analiseBancos)}
          </Grid>
          <Grid lg={2}>
            {filterCard('Financiamento recusado', ['Financiamento recusado'], projectsQt?.reprovados)}
          </Grid>
          <Grid lg={2}>
            {filterCard('Financiamento aprovado', ['Financiamento aprovado'], projectsQt?.aprovados)}
          </Grid>
          <Grid lg={2}>
            {filterCard('Aguardando escolha das condições', ['Aguardando escolha das condições'], projectsQt?.escolhaCondicoes)}
          </Grid>
          <Grid lg={2}>
            {filterCard('Processo de pagamento', ['Aguardando documentos', 'Aguardando NF', 'Análise - Documentos'], pagamentoStatusNumber())}
          </Grid>
          <Grid lg={2}>
            {filterCard('Condições escolhidas', ['Condições escolhidas'], projectsQt?.condicoesEscolhidas)}
          </Grid>
          <Grid lg={2}>
            {filterCard('Documentos recusados', ['Documentos recusados'], projectsQt?.docRecusados)}
          </Grid>
          <Grid lg={2}>
            {filterCard('Projeto contratado', ['Projeto contratado'], projectsQt?.contratados)}
          </Grid>
        </Grid>
      </Box>
      <TextField
        fullWidth
        label="Filtre pelo CPF ou pelo nome do projeto"
        variant="outlined"
        onChange={handleFilterChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchOutlinedIcon />
            </InputAdornment>
          ),
        }}
        sx={{ margin: '1rem 0 1rem 0' }}
      />
      <Paper sx={{
        width: '100%',
        overflow: 'hidden',
        boxSizing: 'border-box',
        boxShadow: 'none',
      }}
      >
        <TableContainer sx={{ maxHeight: 490 }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredItems && (
                filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: ProjectCostumerData) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell>
                        {row.projeto.id}
                      </TableCell>
                      <TableCell>
                        {row.nome}
                      </TableCell>
                      <TableCell>
                        {row.cpf}
                      </TableCell>
                      <TableCell>
                        {handleIntegrator(row)}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: projectStatusColor(row.projeto.status),
                        }}
                      >
                        {row.projeto.status}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '1rem',
                        }}
                      >
                        <Button
                          variant="outlined"
                          sx={{
                            borderRadius: '2rem',
                            borderColor: row.projeto.status.toLowerCase() === 'Inativo'.toLowerCase() ? '#EB5A12' : 'red',
                            color: row.projeto.status.toLowerCase() === 'Inativo'.toLowerCase() ? '#EB5A12' : 'red',
                            '&:hover': {
                              borderColor: row.projeto.status.toLowerCase() === 'Inativo'.toLowerCase() ? '#EB5A12' : 'red',
                              backgroundColor: 'rgba(255,127,80,0.2)',
                            },
                          }}
                          disabled={isActivateDisabled(row)}
                          onClick={() => handleClickActivate(row.projeto.id)}
                        >
                          {row.projeto.status.toLowerCase() === 'Inativo'.toLowerCase() ? 'Ativar' : 'Desativar'}
                        </Button>
                        <Typography
                          onClick={() => handleClickDetails(row)}
                          sx={{
                            fontSize: '12px',
                            fontFamily: 'Poppins',
                            color: '#EB5A12',
                            fontWeight: '700',
                            cursor: 'pointer',
                            userSelect: 'none',
                          }}
                        >
                          Avaliar
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
              )}
              {(!filteredItems || filteredItems.length === 0) && (
                <TableRow
                  hover
                  role="checkbox"
                >
                  <TableCell>
                    Nenhum projeto cadastrado!
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredItems.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Linhas por página"
        />
      </Paper>
      <DialogComponent isOpen={isOpen} labelText={loadingText} />
    </div>
  );
};
export default QuotationsTable;
