import {
  Typography,
  Card,
  Box,
  Button,
  Avatar,
  styled, TextField, useTheme, useMediaQuery,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import React, {
  ChangeEvent, SyntheticEvent, useEffect, useState,
} from 'react';
import { parseCookies } from 'nookies';
import { toast, ToastContainer } from 'react-toastify';
import style from './userprofile.module.scss';
import { api } from '../../services/api';
import { onlyLetters } from '../../utils/util';
import { DialogNewPasswordComponent } from '../../components/DialogEditPasswordComponent';
import { DialogComponent } from '../../components/DialogComponent';
import { ConfirmDialogComponent } from '../../components/ConfirmDialogComponent';
import { getFile } from '../../lib/download';

interface State {
  id: number;
  name: string;
  acronym: string;
}

interface City {
  id: number;
  name: string,
  state: State,
}
interface Address{
  zip: string,
  street1: string,
  street2: string,
  number: string,
  city: City,
}
interface Integrator{
  name: string,
  cnpj: string,
  phone: string,
  dataFundacao: string,
  address: Address,
}
interface User {
  id: number,
  name: string,
  email: string,
  cpf: string,
  phone: string,
  integradora: Integrator
  userAvatar: string,
}

const AvatarWrapper = styled(Card)(
  ({ theme }) => `
    overflow: visible;
    display: inline-block;
    border-radius: 1rem;
    text-align: center;
    .MuiAvatar-root {
      border-radius: 1rem;
      width: ${theme.spacing(16)};
      height: ${theme.spacing(16)};
    }
    &.MuiPaper-root {
      height: fit-content;
    }
`,
);
function UserProfile() {
  const [editFields, setEditFields] = useState(false);
  const [user, setUser] = useState<User>();
  const [integrator, setIntegrator] = useState<Integrator>();
  const [name, setName] = useState(user?.name || '');
  const [cpf, setCpf] = useState(user?.cpf || '');
  const [cnpj, setCnpj] = useState('');
  const [phone, setPhone] = useState(user?.phone || '');
  const [userAvatar, setUserAvatar] = useState<any>();
  const [avatar, setAvatar] = useState<any>();
  const [profile, setProfile] = useState<any>();
  const [imageUrl, setImageUrl] = useState<any>();

  const { 'nextauth.roles': cookieRoles } = parseCookies();
  const isIntegrator = cookieRoles === 'Integradora';

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDialogPassword, setIsOpenDialogPassword] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  const validateCpf = (m: string) => m.length >= 11;
  const validateName = (m: string) => m.length >= 5;
  const validatePhone = (m: string) => m.length > 10;
  const getUserId = () => {
    const { 'nextauth.tag': tag } = parseCookies();
    return parseInt(tag, 10);
  };

  useEffect(() => {
    if (profile) {
      setImageUrl(URL.createObjectURL(profile));
    }
    const getUser = async () => {
      const userId = getUserId();
      if (isIntegrator) {
        const resp = await api.post('/user/integrator/get', { userId });
        const { data } = resp;
        if (data !== 'COULD_NOT_FIND_USER' || data !== 'COULD_NOT_FIND_INTEGRATOR') {
          setUser(data.user);
          setName(data.user.name);
          setCnpj(data.integrator.cnpj);
          setPhone(data.integrator.phoneNumber);
          setIntegrator(data.integrator);
          if (data.user.userAvatar) {
            setAvatar(data.user.userAvatar);
            setUserAvatar(await getFile(data.user.userAvatar));
          }
        }
      } else {
        const resp = await api.post('/user/get', { userId });
        const { data } = resp;
        if (data !== 'COULD_NOT_FIND_USER') {
          setUser(data);
          setName(data.name);
          setCpf(data.cpf);
          setPhone(data.phone);
          if (data.userAvatar) {
            setUserAvatar(await getFile(data.userAvatar));
          }
        }
      }
    };
    getUser();
  }, [isIntegrator, profile]);

  const avatarChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    const file = fileList[0];
    setProfile(file);
    setImageUrl(URL.createObjectURL(file));
  };

  const handleNomeChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = onlyLetters(e);
    setName(e.target.value);
  };
  const handleCpfChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    for (let i = 0; i < e.target.value.length; ++i) {
      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    // apply the "___.___.___-__" mask
    let newValue = '';

    for (let i = 0; i < value.length; ++i) {
      newValue += value[i];
      if (i === 2 || i === 5) {
        newValue += '.';
      }
      if (i === 8) {
        newValue += '-';
      }
    }

    // forcefully limit characters count
    if (newValue.length > 14) {
      newValue = newValue.substring(0, 14);
    }

    e.target.value = newValue;
    setCpf(newValue);
  };
  const handleTelefoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    for (let i = 0; i < e.target.value.length; ++i) {
      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    // apply the "(__)_____-____" mask
    let newValue = '';

    for (let i = 0; i < value.length; ++i) {
      if (i === 0) {
        newValue += '(';
      }
      if (i === 2) {
        newValue += ') ';
      }
      if (i === 7) {
        newValue += '-';
      }

      newValue += value[i];
    }

    // forcefully limit characters count
    if (newValue.length > 15) {
      newValue = newValue.substring(0, 15);
    }

    e.target.value = newValue;
    setPhone(newValue);
  };
  const setDialogClose = async () => {
    setIsOpenDialogPassword(false);
  };
  const setDialogConfirmClose = async () => {
    setIsOpenConfirm(false);
  };
  const updateUser = async (oldUserPassword: string, newUserPassword: string) => {
    setLoadingText('Alterando senha...');
    setIsOpenDialogPassword(false);
    setIsOpen(true);
    toast.info('Alterando senha do usuário! Por favor, aguarde');
    const userId = getUserId();
    const request = {
      headers: { 'Content-Type': 'multipart/form-data' },
      data: {
        userId,
        oldPassword: oldUserPassword,
        newPassword: newUserPassword,
      },
    };
    const res = await api.post('/user/password/put', request);
    switch (res.data) {
      case 'PASSWORD_UPDATED':
        setIsOpen(false);
        toast.success('Senha atualizada!');
        break;
      case 'INVALID_USER_REF':
        setIsOpen(false);
        toast.error('Não foi possível alterar a senha!');
        break;
      case 'PASSWORD_NOT_MATCH':
        setIsOpen(false);
        toast.error('A senha antiga está incorreta!');
        break;
      default:
        setIsOpen(false);
        toast.error(`Falha ao atualizar senha! ${res.data}`);
    }
  };

  const validateForm = () => {
    if (!isIntegrator) {
      if (!validateName(name)) {
        toast.error('Verifique o preenchimento do campo de nome.');
        return false;
      }
      if (cpf === null || !validateCpf(cpf)) {
        toast.error('Verifique o preenchimento do campo de cpf.');
        return false;
      }
    }
    if (phone === null || !validatePhone(phone)) {
      toast.error('Verifique o preenchimento do campo de telefone.');
      return false;
    }
    return true;
  };
  const sendFile = async (label: string, file: any) => {
    const body = new FormData();
    body.append('filefield', file, label);

    const resp = await api.post('upload', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return resp.data;
  };
  const submitUserProfile = async (event: SyntheticEvent) => {
    // event.preventDefault();
    if (validateForm()) {
      setLoadingText('Salvando dados...');
      setIsOpenConfirm(false);
      setIsOpen(true);
      toast.info('Salvando informações do usuário! Por favor, aguarde');
      let fprofile = null;
      if (profile) {
        fprofile = await sendFile('profile', profile);
      }
      if (isIntegrator) {
        const integratorId = getUserId();
        const request = {
          headers: { 'Content-Type': 'multipart/form-data' },
          data: {
            integratorId,
            integratorPhone: phone,
            userAvatar: fprofile || avatar,
          },
        };

        const res = await api.post('/integrator/personal/put', request);

        switch (res.data) {
          case 'INTEGRATOR_UPDATED':
            toast.success('Integradora atualizada!');
            setIsOpen(false);
            window.location.reload();
            break;
          default:
            setIsOpen(false);
            toast.error(
              <div style={{ fontWeight: '8px' }}>
                <strong>Não foi possível alterar os dados!</strong>
                {' '}
                <br />
                {' '}
                Verifique se os campos foram preenchidos corretamente.
              </div>,
            );
        }
      } else {
        const userId = getUserId();
        const request = {
          headers: { 'Content-Type': 'multipart/form-data' },
          data: {
            userId,
            name,
            cpf,
            phone,
            userAvatar: fprofile || avatar,
          },
        };
        const res = await api.post('user/put', request);
        switch (res.data) {
          case 'USER_UPDATE':
            toast.success('Os dados foram atualizados!');
            window.location.reload();
            break;
          default:
            setIsOpen(false);
            toast.error(
              <div style={{ fontWeight: '8px' }}>
                <strong>Não foi possível alterar os dados!</strong>
                {' '}
                <br />
                {' '}
                Verifique se os campos foram preenchidos corretamente.
              </div>,
            );
        }
      }
    } else {
      setIsOpenConfirm(false);
      toast.error('Alguns campos não estão preenchidos corretamente.');
    }
  };

  const cardStyle = {
    backgroundColor: '#fff',
    boxShadow: '0px 2px 2px 0px #0000001A',
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: '1rem',
    minHeight: '82vh',
  };

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box sx={cardStyle}>
      <Grid container spacing={2} rowSpacing={2}>
        <Grid xs={12}>
          <Typography sx={{
            fontSize: { xs: '16px', md: '24px' },
            fontFamily: 'Poppins',
            color: '#EB5A12',
            fontWeight: '700',
          }}
          >
            Dados
            {' '}
            { isIntegrator ? 'da integradora' : 'do usuário' }
          </Typography>
          <hr className={style.divisor} />
        </Grid>
        <Grid xs={12} md={6}>
          <Typography sx={{
            fontSize: { xs: '12px', md: '16px' },
            fontFamily: 'Poppins',
            fontWeight: '400',
          }}
          >
            Faça o gerenciamento dos seus dados pessoais.
          </Typography>
        </Grid>
        <Grid
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            justifyContent: { xs: 'space-evenly', md: 'flex-end' },
            gap: { md: '2rem' },
          }}
        >
          <Button
            variant="text"
            endIcon={<DriveFileRenameOutlineOutlinedIcon />}
            sx={{
              textTransform: 'none',
              fontSize: { xs: '12px', md: '16px' },
              color: '#EB5A12',
              fontFamily: 'Poppins',
              fontWeight: '700',
            }}
            onClick={() => setEditFields(!editFields)}
          >
            Editar dados
          </Button>
          <Button
            variant="text"
            endIcon={<KeyOutlinedIcon />}
            sx={{
              textTransform: 'none',
              fontSize: { xs: '12px', md: '16px' },
              color: '#EB5A12',
              fontFamily: 'Poppins',
              fontWeight: '700',
            }}
            onClick={() => setIsOpenDialogPassword(true)}
          >
            Alterar senha
          </Button>
        </Grid>
        <Grid xs={12} md={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <AvatarWrapper>
            {imageUrl && profile ? (
              <Avatar
                variant="rounded"
                alt={user?.name}
                src={imageUrl}
                sx={{
                  bgcolor: '#DBDDE4 !important',
                  borderBottomLeftRadius: editFields ? '0rem !important' : '',
                  borderBottomRightRadius: editFields ? '0rem !important' : '',
                }}
              />
            ) : (
              <Avatar
                variant="rounded"
                alt={user?.name}
                src={userAvatar}
                sx={{
                  bgcolor: '#DBDDE4 !important',
                  borderBottomLeftRadius: editFields ? '0rem !important' : '',
                  borderBottomRightRadius: editFields ? '0rem !important' : '',
                }}
              >
                <PersonOutlineOutlinedIcon sx={{ fontSize: '8rem !important', color: '#B8BCC9 !important' }} />
              </Avatar>
            )}
            {editFields
              && (
                <Box>
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    multiple
                    type="file"
                    onChange={avatarChange}
                  />
                  <label htmlFor="raised-button-file">
                    <Button
                      variant="text"
                      component="span"
                      sx={{
                        textTransform: 'none',
                        fontSize: { xs: '12px', md: '16px' },
                        color: '#EB5A12',
                        fontFamily: 'Poppins',
                        fontWeight: '700',
                      }}
                    >
                      Editar
                    </Button>
                  </label>
                </Box>
              )}
          </AvatarWrapper>
        </Grid>
        <Grid md={10} sx={{ display: 'grid', alignItems: 'center' }}>
          <Box>
            <Grid container spacing={2} rowSpacing={2}>
              <Grid xs={12} md={6}>
                <TextField
                  disabled={!editFields || isIntegrator}
                  id="fullName"
                  label={isIntegrator ? 'Nome da integradora' : 'Nome Completo'}
                  fullWidth
                  value={name}
                  onChange={handleNomeChange}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  disabled
                  id="email"
                  label="E-mail"
                  fullWidth
                  value={user?.email}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              {isIntegrator && (
                <Grid xs={12} md={6}>
                  <TextField
                    disabled
                    id="cnpj"
                    label="CNPJ"
                    fullWidth
                    value={cnpj}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              )}
              {!isIntegrator && (
              <Grid xs={12} md={6}>
                <TextField
                  disabled={!editFields}
                  id="cpf"
                  label="CPF"
                  fullWidth
                  value={cpf}
                  onChange={handleCpfChange}
                />
              </Grid>
              )}
              <Grid xs={12} md={6}>
                <TextField
                  disabled={!editFields}
                  id="phone"
                  label="Telefone"
                  fullWidth
                  value={phone}
                  onChange={handleTelefoneChange}
                />
              </Grid>
              {isIntegrator && (
                <Grid xs={12} md={6}>
                  <TextField
                    disabled
                    id="cep"
                    label="CEP"
                    fullWidth
                    value={integrator?.address.zip}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              )}
              {isIntegrator && (
                <Grid xs={12} md={6}>
                  <TextField
                    disabled
                    id="city"
                    label="Cidade"
                    fullWidth
                    value={integrator?.address.city.name}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              )}
              {isIntegrator && (
                <Grid xs={12} md={6}>
                  <TextField
                    disabled
                    id="street"
                    label="Rua"
                    fullWidth
                    value={`${integrator?.address.street1} ${integrator?.address.street2}`}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              )}
              {isIntegrator && (
                <Grid xs={12} md={6}>
                  <TextField
                    disabled
                    id="state"
                    label="Estado"
                    fullWidth
                    value={integrator?.address.city.state.name}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
        {editFields && (
        <Grid
          xs={12}
          md={12}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            variant="contained"
            fullWidth={isMobileScreen}
            sx={{
              width: { md: '14rem' },
              borderRadius: '2rem',
              backgroundColor: '#EB5A12',
              marginBottom: '0.5rem',
              marginTop: '1rem',
              '&:hover': {
                backgroundColor: '#FF7F50',
              },
            }}
            onClick={() => setIsOpenConfirm(true)}
          >
            Salvar
          </Button>
        </Grid>
        )}
      </Grid>
      <ToastContainer />
      <DialogComponent isOpen={isOpen} labelText={loadingText} />
      <DialogNewPasswordComponent
        isOpen={isOpenDialogPassword}
        updateUser={updateUser}
        setDialogClose={setDialogClose}
      />
      <ConfirmDialogComponent
        isOpen={isOpenConfirm}
        confirmDialog={submitUserProfile}
        text="Gostaria de salvar os dados do usuário?"
        setDialogClose={setDialogConfirmClose}
      />
    </Box>
  );
}
export default UserProfile;
