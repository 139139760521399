import React, {
  ChangeEvent, SyntheticEvent, useEffect, useRef, useState,
} from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { parseCookies } from 'nookies';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { AxiosResponse } from 'axios';
import style from './cadastrocliente.module.scss';
import { applyCurrencyMask, onlyLetters } from '../../utils/util';
import { FormPage } from '../../components/FormPage';
import { RequiredFieldMark } from '../../components/RequiredFieldMark';
import { Anchor } from '../../components/Anchor';
import { StepperProject } from '../../components/StepperProject';
import { api } from '../../services/api';
import { LoadingIcon } from '../../components/LoadingIcon';

import uploadIcon from '../../assets/images/upload.png';
import { Baloon } from '../../components/Baloon';
import { getFile } from '../../lib/download';
import { DialogComponent } from '../../components/DialogComponent';
import { AssociateEditor } from '../../components/AssociateEditor';

interface Estado {
  id: number;
  name: string;
  acronym: string;
}

interface Cidade {
  id: number;
  name: string;
}
interface Associate{
  id?: number;
  name?: string;
  motherName?: string;
  phone?: string;
  cpf?: string;
}

interface EstadoCivil {
  name: string;
  key: number;
}
interface ViaCepErro {
  erro: boolean;
}

interface BudgetCity {
  city: string,
  ibge: string
}

export function CadastroCliente() {
  const dateMask = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;
  const dateMaskAlt = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
  const canvasRef = useRef(null);
  const nomeAssociateRef = useRef(null);
  const cpfAssociateRef = useRef(null);
  const nomeMaeAssociateRef = useRef(null);
  const telefoneAssociateRef = useRef(null);
  const getPotenciaInstalada = () => {
    const { potencia: potencia_instalada } = parseCookies();
    return parseInt(potencia_instalada, 10);
  };
  const pot_inst = getPotenciaInstalada();
  const text_declaracao = 'Declaro para os devidos fins que as informações do meu cliente inseridas por mim estão corretas.';
  const text_autorizacao = 'Meu cliente autoriza que seus dados sejam consultados pela Soltech, inclusive para fins de validação junto a órgãos de proteção de crédito, conforme consta em nosso ';
  const text_produtor = 'Meu cliente é produtor rural (requer documento comprobatório).';
  const text_concordo_1 = 'Eu e meu cliente concordamos com a';
  const text_concordo_2 = ' e com os ';
  const text_concordo_3 = ' da Soltech.';
  const ref = useRef<LoadingBarRef>(null);
  const [isCepLoading, setIsCepLoading] = useState<boolean>(false);
  const [isClientOwner, setIsClientOwner] = useState<Boolean>(true);

  const [estados, setEstados] = useState<Estado[]>();
  const [cidades, setCidades] = useState<Cidade[]>();
  const [errorMessage, setErrorMessage] = useState<string[]>([]);

  const [isOpen, setIsOpen] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  // cadastro
  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');
  const [rg, setRg] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [costumerStatus, setCostumerStatus] = useState('');

  const [associates, setAssociates] = useState<Associate[]>([]);
  const [nomeAssociate, setNomeAssociate] = useState('');
  const [cpfAssociate, setCpfAssociate] = useState('');
  const [nomeMaeAssociate, setNomeMaeAssociate] = useState('');
  const [telefoneAssociate, setTelefoneAssociate] = useState('');

  const [nomeMae, setNomeMae] = useState('');
  const [telefone, setTelefone] = useState('');
  const [renda, setRenda] = useState(0);
  const [email, setEmail] = useState('');
  const [mediaLuz, setMediaLuz] = useState(0);
  const [radioTypeFamilyValue, setRadioTypeFamilyValue] = useState(0);
  const [profissao, setProfissao] = useState('');
  const [estadoCivil, setEstadoCivil] = useState('');
  const [tempoProfissao, setTempoProfissao] = useState('');
  const [valorPatrimonio, setValorPatrimonio] = useState(0);

  const [razaoSocial, setRazaoSocial] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [dataFundacao, setDataFundacao] = useState('');
  const [emailEmpresa, setEmailEmpresa] = useState('');
  const [telefoneEmpresa, setTelefoneEmpresa] = useState('');
  const [capitalSocial, setCapitalSocial] = useState(0);
  const [rendaEmpresa, setRendaEmpresa] = useState(0);
  const estadosCivil: EstadoCivil[] = [
    {
      name: 'Não selecionado',
      key: 0,
    },
    {
      name: 'Casado',
      key: 1,
    },
    {
      name: 'Solteiro',
      key: 2,
    },
    {
      name: 'Viúvo',
      key: 3,
    },
    {
      name: 'União Estável',
      key: 4,
    },
    {
      name: 'Separado',
      key: 5,
    },
  ];

  const defaultTypes = [
    { id: 1, name: 'Comercial' },
    { id: 2, name: 'Rural' },
    { id: 3, name: 'Residencial' },
    { id: 4, name: 'Industrial' },
  ];

  const [checkBusinessOwn, setCheckBusinessOwn] = useState(false);
  const [checkDeclaro, setCheckDeclaro] = useState(false);
  const [checkAutorizo, setCheckAutorizo] = useState(false);
  const [checkTermos, setCheckTermos] = useState(false);

  // endereço
  const [rua, setRua] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [district, setDistrict] = useState('');
  const [cep, setCep] = useState('');
  const [cidade, setCidade] = useState(1);
  const [estado, setEstado] = useState(1);

  // outras informações
  const [potencia, setPotencia] = useState('');
  const [potenciaCusto, setPotenciaCusto] = useState('');
  const [type, setType] = useState('1');
  const [types, setTypes] = useState(defaultTypes);

  // documentos
  const [equipamento, setEquipamento] = useState<any>();
  const [proposta, setProposta] = useState<any>();
  const [contaLuz, setContaLuz] = useState<any>();
  const [identidade, setIdentidade] = useState<any>();
  const [iptu, setIptu] = useState<any>();
  const [comprovanteRenda, setComprovanteRenda] = useState<any>();

  const [loadedFiles, setLoadedFiles] = useState({
    // equipamento: null,
    // contaLuz: null,
    // identidade: null,
    // iptu: null,
    // comprovanteRenda: null,
    proposta: null,
  });

  // guardará as informações da cidade que foi feito o orçamento, caso necessário
  const [budgetCity, setBudgetCity] = useState<BudgetCity>({ city: '', ibge: '' });

  const getProjectId = () => {
    const { 'nextauth.p': cprojId } = parseCookies();
    return Number(cprojId);
  };

  const { 'nextauth.tag': tag } = parseCookies();

  // utilities
  const getCidades = async (state: number) => {
    const respons = await api.post('/address/city/get', { stateId: state });
    setCidades(respons.data);
  };

  const getToday = (m: string) => {
    const date = new Date();
    const day = date.getDate().toString();
    let month = ((date.getMonth() + 1).toString());
    if (month.length === 1) month = `0${month}`;
    const year = date.getFullYear().toString();

    // check if date is after today
    return !(m.slice(6, 10) > year
        || (m.slice(6, 10) >= year
          && m.slice(3, 5) > month)
      || (m.slice(0, 2) > day
        && m.slice(3, 5) >= month
        && m.slice(6, 10) >= year));
  };

  const applyCurrencyMaskLocal = (n: string) => {
    const value : string = n.toString().split('').reverse().join('');

    let newValue = '';
    for (let x = 0; x < value.length; x++) {
      newValue += value[x];
      if (x === 1) {
        if (x < value.length - 1) { newValue += ','; }
      }

      if (x === 4 || x === 7) {
        if (x < value.length - 1) { newValue += '.'; }
      }
    }

    newValue = newValue.split('').reverse().join('');
    newValue = `R$ ${newValue}`;
    return newValue;
  };

  // field change handlers
  const handleNomeChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = onlyLetters(e);
    setNome(e.target.value);
  };
  const handleNomeAssociateChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = onlyLetters(e);
    setNomeAssociate(e.target.value);
  };

  const handleRgChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRg(e.target.value);
  };

  const handlePotencia = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';
    for (let i = 0; i < e.target.value.length; ++i) {
      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }
    setPotencia(e.target.value);
  };

  const handleCustoPotencia = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    value = applyCurrencyMaskLocal(e.target.value);
    setPotenciaCusto(value);
  };

  const handleCpfChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    for (let i = 0; i < e.target.value.length; ++i) {
      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    // apply the "___.___.___-__" mask
    let newValue = '';

    for (let i = 0; i < value.length; ++i) {
      newValue += value[i];
      if (i === 2 || i === 5) {
        newValue += '.';
      }
      if (i === 8) {
        newValue += '-';
      }
    }

    // forcefully limit characters count
    if (newValue.length > 14) {
      newValue = newValue.substring(0, 14);
    }

    e.target.value = newValue;
    setCpf(newValue);
  };
  const handleCpfAssociateChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    for (let i = 0; i < e.target.value.length; ++i) {
      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    // apply the "___.___.___-__" mask
    let newValue = '';

    for (let i = 0; i < value.length; ++i) {
      newValue += value[i];
      if (i === 2 || i === 5) {
        newValue += '.';
      }
      if (i === 8) {
        newValue += '-';
      }
    }

    // forcefully limit characters count
    if (newValue.length > 14) {
      newValue = newValue.substring(0, 14);
    }

    e.target.value = newValue;
    setCpfAssociate(newValue);
  };

  const handleDataNascimentoChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';
    const date = new Date();

    // remove unwanted characters (including recently pressed ones)
    for (let i = 0; i < e.target.value.length; ++i) {
      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    // apply the "__/__/____" mask
    let newValue = '';

    for (let i = 0; i < value.length; ++i) {
      if (i === 2 || i === 4) {
        newValue += '/';
      }
      newValue += value[i];
    }

    if (newValue.length >= 10) {
      newValue = newValue.slice(0, 10);
    }

    // forcefully limit character value
    if (newValue.slice(0, 2) > '31') {
      newValue = '31';
    }

    if (newValue.slice(3, 5) > '12') {
      newValue = newValue.slice(0, 3);
      newValue += '12';
    }

    if (newValue.slice(6, 10) > date.getFullYear().toString()) {
      newValue = newValue.slice(0, 6);
      newValue += date.getFullYear().toString();
    }

    e.target.value = newValue;
    setDataNascimento(e.target.value);
  };

  const handleTelefoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    for (let i = 0; i < e.target.value.length; ++i) {
      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    // apply the "(__)_____-____" mask
    let newValue = '';

    for (let i = 0; i < value.length; ++i) {
      if (i === 0) {
        newValue += '(';
      }
      if (i === 2) {
        newValue += ') ';
      }
      if (i === 7) {
        newValue += '-';
      }

      newValue += value[i];
    }

    // forcefully limit characters count
    if (newValue.length > 15) {
      newValue = newValue.substring(0, 15);
    }

    e.target.value = newValue;
    setTelefone(newValue);
  };
  const handleTelefoneAssociateChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    for (let i = 0; i < e.target.value.length; ++i) {
      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    // apply the "(__)_____-____" mask
    let newValue = '';

    for (let i = 0; i < value.length; ++i) {
      if (i === 0) {
        newValue += '(';
      }
      if (i === 2) {
        newValue += ') ';
      }
      if (i === 7) {
        newValue += '-';
      }

      newValue += value[i];
    }

    // forcefully limit characters count
    if (newValue.length > 15) {
      newValue = newValue.substring(0, 15);
    }

    e.target.value = newValue;
    setTelefoneAssociate(newValue);
  };

  const removeNonNumeric = (n: string) => {
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    let value = '';
    for (let i = 0; i < n.length; ++i) {
      if (mask.includes(n[i])) {
        value += n[i];
      }
    }
    return (value.length === 0) ? '0' : parseInt(value, 10).toString();
  };

  const handleRendaChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = removeNonNumeric(e.target.value);
    setRenda(parseInt(value, 10));
    e.target.value = applyCurrencyMaskLocal(value);
  };
  const handleNomeMaeChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = onlyLetters(e);
    setNomeMae(e.target.value);
  };

  const handleProfissaoChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = onlyLetters(e);
    setProfissao(e.target.value);
  };
  const handleEstadoCivilChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const estadoName: string = e.target.value;
    setEstadoCivil(estadoName);
  };

  const handleTempoProfissaoChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    for (let i = 0; i < e.target.value.length; ++i) {
      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }
    if (Number(value) >= 50) {
      value = '50';
    }
    e.target.value = value;
    setTempoProfissao(e.target.value);
  };
  const handleValorPatrimonioChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = removeNonNumeric(e.target.value);
    setValorPatrimonio(parseInt(value, 10));
    e.target.value = applyCurrencyMaskLocal(value);
  };

  const handleNomeMaeAssociatedChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = onlyLetters(e);
    setNomeMaeAssociate(e.target.value);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleMediaLuzChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = removeNonNumeric(e.target.value);
    setMediaLuz(parseInt(value, 10));
    e.target.value = applyCurrencyMaskLocal(value);
  };

  const handleCnpjChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    for (let i = 0; i < e.target.value.length; ++i) {
      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    // apply the "__.___.___/____-__" mask
    let newValue = '';

    for (let i = 0; i < value.length; ++i) {
      newValue += value[i];
      if (i === 1 || i === 4) {
        newValue += '.';
      }
      if (i === 11) {
        newValue += '-';
      }
      if (i === 7) {
        newValue += '/';
      }
    }

    // forcefully limit characters count
    if (newValue.length > 18) {
      newValue = newValue.substring(0, 18);
    }

    e.target.value = newValue;
    setCnpj(e.target.value);
  };
  const handleDataFundacaoChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';
    const date = new Date();

    // remove unwanted characters (including recently pressed ones)
    for (let i = 0; i < e.target.value.length; ++i) {
      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    // apply the "__/__/____" mask
    let newValue = '';

    for (let i = 0; i < value.length; ++i) {
      if (i === 2 || i === 4) {
        newValue += '/';
      }
      newValue += value[i];
    }

    if (newValue.length >= 10) {
      newValue = newValue.slice(0, 10);
    }

    // forcefully limit character value
    if (newValue.slice(0, 2) > '31') {
      newValue = '31';
    }

    if (newValue.slice(3, 5) > '12') {
      newValue = newValue.slice(0, 3);
      newValue += '12';
    }

    if (newValue.slice(6, 10) > date.getFullYear().toString()) {
      newValue = newValue.slice(0, 6);
      newValue += date.getFullYear().toString();
    }
    e.target.value = newValue;
    setDataFundacao(e.target.value);
  };
  const handleRazaoChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRazaoSocial(e.target.value);
  };
  const handleNomeFantasiaChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNomeFantasia(e.target.value);
  };
  const handleEmailEmpresaChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailEmpresa(e.target.value);
  };
  const handleTelefoneEmpresaChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    for (let i = 0; i < e.target.value.length; ++i) {
      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    // apply the "(__)_____-____" mask
    let newValue = '';

    for (let i = 0; i < value.length; ++i) {
      if (i === 0) {
        newValue += '(';
      }
      if (i === 2) {
        newValue += ') ';
      }
      if (i === 7) {
        newValue += '-';
      }

      newValue += value[i];
    }

    // forcefully limit characters count
    if (newValue.length > 15) {
      newValue = newValue.substring(0, 15);
    }

    e.target.value = newValue;
    setTelefoneEmpresa(e.target.value);
  };
  const handleCapitalSocialChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = removeNonNumeric(e.target.value);
    setCapitalSocial(parseInt(value, 10));
    e.target.value = applyCurrencyMaskLocal(value);
  };
  const handleRendaEmpresaChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = removeNonNumeric(e.target.value);
    setRendaEmpresa(parseInt(value, 10));
    e.target.value = applyCurrencyMaskLocal(value);
  };

  const checkTermosChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCheckTermos(e.target.checked);
  };

  const checkDeclaroChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCheckDeclaro(e.target.checked);
  };

  const checkAutorizoChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCheckAutorizo(e.target.checked);
  };

  const setAttribute = (id: string, attrib: string, value: any) => {
    document.getElementById(id)?.setAttribute(attrib, value);
    document.getElementById(id)?.setAttribute('disabled', 'disabled');
  };

  const disableFields = (data : any) => {
    // 2020-02-20T00:00:00.000Z
    let dataString = '';
    if (data.dataNascimento) {
      dataString = data.dataNascimento
        .replace('Z', '')
        .split('T')[0]
        .replace(/-/, '/')
        .replace(/-/, '/')
        .split('/')
        .reverse()
        .join('/');
    }

    setAttribute('submit_btn', 'disabled', 'disabled');
    setAttribute('f_nome', 'value', data.nome || '');
    setAttribute('f_email', 'value', data.email || '');
    setAttribute('f_cpf', 'value', data.cpf || '');
    setAttribute('f_rg', 'value', data.rg || '');
    setAttribute('f_nomemae', 'value', data.nomeMae || '');
    setAttribute('f_profissao', 'value', data.profissao || '');
    setAttribute('f_estadoCivil', 'value', data.estadoCivil || '');
    setEstadoCivil(data.estadoCivil || '');
    setAttribute('f_tempoProfissao', 'value', data.tempoProfissao || '');
    let patrimonio = '';
    patrimonio = applyCurrencyMaskLocal(data.valorPatrimonio || '');
    setAttribute('f_patrimonio', 'value', patrimonio || '');
    setAttribute('f_telefone', 'value', data.telefone || '');
    setAttribute('f_nasc', 'value', dataString);
    let valueRenda = '';
    valueRenda = applyCurrencyMaskLocal(data.faturamentoMensal || '');
    setAttribute('f_renda', 'value', valueRenda || '');
    let valueFLuz = '';
    valueFLuz = applyCurrencyMaskLocal(data.mediaContaLuz || '');
    setAttribute('f_luz', 'value', valueFLuz || '');
    setAttribute('f_potencia', 'value', data.projectPotency || '');
    setAttribute('f_custo_potencia', 'value', (applyCurrencyMaskLocal(data.projectPotencyCost)) || '');

    const newType = [...types, { id: 5, name: 'Não informado' }];
    setTypes(newType);
    setType(data.projectType || '');
    setAttribute('f_type', 'value', data.projectType || '');

    setAttribute('f_cnpj', 'value', data.cnpj || '');
    setAttribute('f_fund', 'value', data.dataAbertura || '');
    setAttribute('f_razao', 'value', data.razaoSocial || '');
    setAttribute('f_fantasia', 'value', data.nomeFantasia || '');
    setAttribute('f_email_empresa', 'value', data.emailEmpresa || '');
    setAttribute('f_telefone_empresa', 'value', data.telefoneComercial || '');
    let valueFaturamentoMensalEmpresa = '';
    valueFaturamentoMensalEmpresa = applyCurrencyMaskLocal(data.faturamentoMensalEmpresa || '');
    setAttribute('f_renda_empresa', 'value', valueFaturamentoMensalEmpresa || '');
    let capital = '';
    capital = applyCurrencyMaskLocal(data.capitalSocial || '');
    setAttribute('f_capital_social', 'value', capital || '');

    // setAttribute('f_nome_associate', 'value', data.name);
    // setAttribute('f_email', 'value', data.email);
    // setAttribute('f_cpf_associate', 'value', data.f_cpf_associate);
    // setAttribute('f_nomemae_associate', 'value', data.motherName);

    setAttribute('f_cep', 'value', data.enderecoInstalacao.zip || '');
    setAttribute('ruaField', 'value', data.enderecoInstalacao.street1 || '');
    setRua(data.enderecoInstalacao.street1);
    setAttribute('districtField', 'value', data.enderecoInstalacao.district || '');
    setDistrict(data.enderecoInstalacao.district || '');
    setAttribute('f_numero', 'value', data.enderecoInstalacao.number || '');
    setNumero(data.enderecoInstalacao.number);
    setAttribute('f_complemento', 'value', data.enderecoInstalacao.street2 || '');
    setComplemento(data.enderecoInstalacao.street2 || '');
    setAttribute('f_telefone_associate', 'value', data.phone || '');

    setEstado(data.enderecoInstalacao.city.state.id || '');
    setAttribute('estadoField', 'value', data.enderecoInstalacao.city.state.name || '');

    const decoyStateId = 1;
    getCidades(data.enderecoInstalacao.city.state.id || decoyStateId); //!
    setCidade(data.enderecoInstalacao.city.id || '');
    setAttribute('cidadeField', 'value', data.enderecoInstalacao.city.name || '');

    setAttribute('upload_equipamento', 'disabled', 'disabled');
    setAttribute('upload_contaluz', 'disabled', 'disabled');
    setAttribute('upload_identidade', 'disabled', 'disabled');
    setAttribute('upload_comprovanteRenda', 'disabled', 'disabled');
    setAttribute('upload_iptu', 'disabled', 'disabled');
    if (data.status !== 'Cadastro') {
      setCheckDeclaro(true);
      setAttribute('checkDeclaro', 'disabled', 'disabled');
      setAttribute('checkBusinessOwn', 'disabled', 'disabled');
      setCheckTermos(true);
      setAttribute('checkTermos', 'disabled', 'disabled');
      setCheckAutorizo(true);
      setAttribute('checkAutorizo', 'disabled', 'disabled');
    }

    setLoadedFiles({
      // contaLuz: data.contaLuzRecente,
      // identidade: data.documentoIdentificacao,
      // equipamento: data.notaFiscalEquipamentos,
      // iptu: data.iptu,
      // comprovanteRenda: data.comprovanteRenda,
      proposta: data.proposta,
    });
  };

  useEffect(() => {
    // // @ts-ignore
    // nomeAssociateRef!.current!.value = '';
    // // @ts-ignore
    // cpfAssociateRef!.current!.value = '';
    // // @ts-ignore
    // nomeMaeAssociateRef!.current!.value = '';
    // // @ts-ignore
    // telefoneAssociateRef!.current!.value = '';

    const getStates = async () => {
      const respo = await api.post('/address/state/get', {}, {});
      setEstados(respo.data);
    };

    const getCostumer = async () => {
      const req = { projectId: getProjectId() };
      const costumer = await api.post('/project/costumer/get', req);
      const { data } = costumer;

      if (data !== 'COULD_NOT_FIND_COSTUMER') {
        setCostumerStatus(data.status);
        setCheckBusinessOwn(data.company_link);
        setAssociates(data.associates);
        disableFields(data);
      } else {
        const res: Promise<AxiosResponse<any, any>> = api.post(
          'project/single/get',
          { projectId: getProjectId() },
        );

        res.then((resolved) => {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const { data } = resolved;

          if (data.status === 'Inativo') {
            disableFields({
              enderecoInstalacao: {
                zip: null,
                street1: null,
                number: null,
                street2: null,
                city: {
                  id: null,
                  name: null,
                  state: {
                    id: null,
                  },
                },
              },
            });
          } else if (data !== 'COULD_NOT_LOCATE_PROJECT') {
            setCostumerStatus(data.status);
            setCheckBusinessOwn(data.company_link);
            setAssociates(data.associates);
            // whether filled already
            if (data.nome.length !== 0) {
              setAttribute('f_potencia', 'value', data.potencia);
              setPotencia(data.potencia);
              setAttribute('f_custo_potencia', 'value', applyCurrencyMaskLocal((data.valorTotal / data.potencia).toFixed(0)));
            }
          }
        });
      }

      return data;
    };

    getStates().then((r) => {
      getCidades(1).then(async (s) => {
        await getCostumer();
      });
    });

    // Busca o orçamento relacionado a este projeto e reserva as informações de cidade e ibge
    const getBudget = async (budgetId: number) => {
      const resp: Promise<AxiosResponse<any, any>> = api.post(
        'getBudgetHelte',
        { data: { budgetId, sellerId: parseInt(tag, 10) } },
      );

      resp.then((resolved) => {
        const { data } = resolved;
        console.log(data);
        setBudgetCity({
          ...budgetCity,
          city: data.city.name,
          ibge: data.city.id,
        });
      });
    };

    // If Use-effect is changed run without the disabled eslint to check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const TestFileType = (fileName: string, fileTypes : [string]) => {
    if (!fileName) return;

    const dots = fileName.split('.');
    // get the part AFTER the LAST period.
    const fileType = `.${dots[dots.length - 1]}`;

    // eslint-disable-next-line consistent-return
    if (fileTypes.join('.').indexOf(fileType) !== -1) {
      // eslint-disable-next-line consistent-return
      return true;
    }
    // eslint-disable-next-line consistent-return
    return false;
  };
  const handleEquipamentoChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      setEquipamento(fileList[0]);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça o upload apenas de arquivos com a extensão .pdf!');
    }
  };
  const handlePropostaChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      setProposta(fileList[0]);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça o upload apenas de arquivos com a extensão .pdf!');
    }
  };

  const handleComprovanteRendaChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      setComprovanteRenda(fileList[0]);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça o upload apenas de arquivos com a extensão .pdf!');
    }
  };

  const handleIptuChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      setIptu(fileList[0]);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça o upload apenas de arquivos com a extensão .pdf!');
    }
  };
  const handleContaLuzChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      setContaLuz(fileList[0]);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça apenas o upload de arquivos com a extensão .pdf!');
    }
  };
  const handleIdentidadeChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      setIdentidade(fileList[0]);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça apenas o upload de arquivos com a extensão .pdf!');
    }
  };

  const handleNumeroChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    for (let i = 0; i < e.target.value.length; ++i) {
      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    e.target.value = value;
    setNumero(e.target.value);
  };

  const handleComplementoChange = (e: ChangeEvent<HTMLInputElement>) => {
    setComplemento(e.target.value);
  };

  const handleDistrictChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDistrict(e.target.value);
  };

  const buscaCep = async (cepVal: string) => {
    // remove hyphenation
    const value = cepVal.replace('-', '');
    const request = {
      data: {
        ref: value,
      },
    };

    const respon = await api.post('/address/cep/get', request);

    const error = respon.data as ViaCepErro;
    if (!error.erro) {
      setRua(respon.data.rua);
      setDistrict(respon.data.bairro);
      setEstado(respon.data.estadoId);
      getCidades(respon.data.estadoId);
      setCidade(respon.data.cidadeId);
    } else {
      setRua('');
      setDistrict('');
      setNumero('');
      setComplemento('');
      toast.warning('CEP não encontrado!');
    }
    setIsCepLoading(false);
  };

  const projId = getProjectId();

  const sendFile = async (label: string, file: any) => {
    const body = new FormData();
    body.append('filefield', file, label);

    const resp = await api.post('upload', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return resp.data;
  };

  const handleCepChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    for (let i = 0; i < e.target.value.length; ++i) {
      // prevent field to go over 9 characters
      if (i === 9) break;

      if (i === 5) {
        value += '-';
      }

      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    e.target.value = value;
    setCep(value);

    if (value.length === 9) {
      setIsCepLoading(true);
      buscaCep(value);
    }
  };

  const validateNome = (m: string) => m.length >= 5;
  const validateCpf = (m: string) => m.length === 14;
  const validateRg = (m: string) => m.length > 5;
  const validateDataNascimento = (m: string) => {
    const leapDay = m.slice(0, 2);
    const leapMonth = m.slice(3, 5);
    const leapYear = m.slice(6, m.length);

    if (getToday(m) && dateMaskAlt.test(m)) {
      return true;
    } return (parseInt(leapYear, 10) % 4 === 0) && leapMonth === '02' && leapDay === '29';
  };
  const validateNomeMae = (m: string) => m.length >= 5;
  const validateProfissao = (m: string) => m.length >= 5;
  const validateEstadoCivil = (m: string) => m.length >= 5;
  const validateTempoProfissao = (m: string) => m.length > 0;
  const validateValorPatrimonio = (m: number) => m > 0;
  const validateTelefone = (m: string) => m.length > 10;
  const validateRenda = (m: number) => m > 0;
  const validateEmail = (m: string) => m.length > 5;
  const validateMediaLuz = (m: number) => m > 0;
  const validateDataFundacao = (m: string) => {
    const leapDay = m.slice(0, 2);
    const leapMonth = m.slice(3, 5);
    const leapYear = m.slice(6, m.length);

    if (getToday(m) && dateMaskAlt.test(m)) {
      return true;
    } return (parseInt(leapYear, 10) % 4 === 0) && leapMonth === '02' && leapDay === '29';
  };

  const validateRazaoSocial = (m: string) => m.length >= 5;
  const validateNomeFantasia = (m: string) => m.length > 5;
  const validateCnpj = (m: string) => m.length > 13;
  const validateEmailEmpresa = (m: string) => m.length > 5;
  const validateTelefoneEmpresa = (m: string) => m.length > 0;
  const validateCapitalSocial = (m: number) => m > 0;
  const validateRendaEmpresa = (m: number) => m > 0;

  const validateRua = (m: string) => m.length > 0;
  const validateNumero = (m: string) => m.length > 0;
  // const validateComplemento = (m: string) => true;
  const validateCep = (m: string) => m.length === 9;
  const validateCidade = (m: number) => true;
  // checa se a cidade no cadastro de cliente é o mesmo do orçamento, caso exista
  const validateCidadeBudget = (m: number) => {
    let check = true;
    if (budgetCity.city.length > 0) {
      const arr = cidades?.find((city) => city.id === m);

      if (arr) {
        check = arr.name === budgetCity.city;
      }
    }

    return check;
  };
  const validateEstado = (m: number) => true;

  const validatePotencia = (m: string) => m.length > 0;
  const validateType = (m: string) => m.length > 0;

  const validateContaLuz = (m: string) => contaLuz;
  const validateEquipamento = (m: string) => equipamento;
  const validateProposta = (m: string) => proposta;
  const validateIdentidade = (m: string) => identidade;
  const validateIptu = (m: string) => iptu;
  const validateRendas = (m: string) => comprovanteRenda;

  const validateForm = () => {
    const messages: any[] = [];
    // eslint-disable-next-line max-len
    // if (!equipamento) { messages.push('O campo "Nota Fiscal do Equipamento" não foi preenchido.'); }
    // if (!iptu) { messages.push('O campo "Nota Fiscal do Equipamento" não foi preenchido.'); }
    // eslint-disable-next-line max-len
    // if (!comprovanteRenda) { messages.push('O campo "Comprovante de Renda" não foi preenchido.'); }
    // if (!contaLuz) { messages.push('O campo "Conta de Luz Atual" não foi preenchido.'); }
    // if (!identidade) { messages.push('O campo "Documento de Identidade" não foi preenchido.'); }

    if (!(checkAutorizo)) {
      messages.push('Seu cliente precida autorizar a Soltech a consultar seus dados pessoais.');
    }
    if (!(checkDeclaro)) {
      messages.push('Você precisa declarar que os dados prestados são verdadeiros antes de continuar.');
    }
    if (!(checkTermos)) {
      messages.push('Você precisa concordar com os termos de uso e política de privacidade da Soltech antes de continuar.');
    }
    if (!isClientOwner && radioTypeFamilyValue === 0) {
      messages.push('Você precisa escolher o grau de parentesco do dono da conta de luz com o cliente.');
    }

    if (!validateNome(nome)) {
      messages.push('O campo "Nome" está formatado incorretamente');
    }
    if (!validateCpf(cpf)) {
      messages.push('O campo "Cpf" está formatado incorretamente');
    }
    if (!validateRg(rg)) {
      messages.push('O campo "RG" está formatado incorretamente');
    }
    if (!validateDataNascimento(dataNascimento)) {
      messages.push('O campo "Data de Nascimento" está formatado incorretamente');
    }

    if (!validateNomeMae(nomeMae)) {
      messages.push('O campo "Nome da Mãe" está formatado incorretamente');
    }
    if (!validateProfissao(profissao)) {
      messages.push('O campo "Profissão" está formatado incorretamente');
    }
    if (!validateEstadoCivil(estadoCivil)) {
      messages.push('Uma opção do "Estado Civil" precisa ser escolhida');
    }
    if (!validateTempoProfissao(tempoProfissao)) {
      messages.push('O campo "Tempo de Profissão" está formatado incorretamente');
    }
    if (!validateValorPatrimonio(valorPatrimonio)) {
      messages.push('O campo "Valor do Patrimonio" está formatado incorretamente');
    }
    if (!validateTelefone(telefone)) {
      messages.push('O campo "Telefone" está formatado incorretamente');
    }
    if (!validateRenda(renda)) {
      messages.push('O campo "Renda" está formatado incorretamente');
    }
    if (!validateEmail(email)) {
      messages.push('O campo "Email" está formatado incorretamente');
    }
    if (!validateMediaLuz(mediaLuz)) {
      messages.push('O campo "Média da Conta de Luz" está formatado incorretamente');
    }

    if (checkBusinessOwn) {
      if (!validateCnpj(cnpj)) {
        messages.push('O campo "CNPJ" é obrigatório para projetos que tenham empresas vinculadas');
      }
      if (!validateRazaoSocial(razaoSocial)) {
        messages.push('O campo "Razão Social" é obrigatório para projetos que tenham empresas vinculadas');
      }
      if (!validateNomeFantasia(nomeFantasia)) {
        messages.push('O campo "Nome Fantasia" é obrigatório para projetos que tenham empresas vinculadas');
      }
      if (!validateEmailEmpresa(emailEmpresa)) {
        messages.push('O campo "Email Empresa" é obrigatório para projetos que tenham empresas vinculadas');
      }
      if (!validateTelefoneEmpresa(telefoneEmpresa)) {
        messages.push('O campo "Telefone Empresa" é obrigatório para projetos que tenham empresas vinculadas');
      }
      if (!validateCapitalSocial(capitalSocial)) {
        messages.push('O campo "Capital Social" é obrigatório para projetos que tenham empresas vinculadas');
      }
      if (!validateRendaEmpresa(rendaEmpresa)) {
        messages.push('O campo "Renda Empresa" é obrigatório para projetos que tenham empresas vinculadas');
      }
    }
    if (!validateNumero(numero)) {
      messages.push('O campo "Número" está formatado incorretamente');
    }
    // if (!validateComplemento(complemento)) {
    //   messages.push('O campo "Complemento" está formatado incorretamente');
    // }
    if (!validateCep(cep)) {
      messages.push('O campo "CEP" está formatado incorretamente');
    }
    if (!validateCidade(cidade)) {
      messages.push('O campo "Cidade" está formatado incorretamente');
    }
    if (!validateCidadeBudget(cidade)) {
      messages.push('O campo "Cidade" deve ser a mesma do orçamento');
    }
    if (!validateEstado(estado)) {
      messages.push('O campo "Estado" está formatado incorretamente');
    }
    if (!validateRua(rua)) {
      messages.push('O campo "Rua" está formatado incorretamente');
    }
    // if (!validatePotencia(potencia)) {
    //   messages.push('O campo "Potência Instalada" está formatado incorretamente');
    // }
    if (!validateType(type)) {
      messages.push('O campo "Tipo do projeto" não foi escolhido.');
    }
    // if (!validateEquipamento(equipamento)) {
    //   messages.push('O campo "Nota Fiscal do Equipamento" está formatado incorretamente');
    // }
    // if (!validateContaLuz(contaLuz)) {
    //   messages.push('O campo "Conta de Luz Atual" está formatado incorretamente');
    // }
    // if (!validateIdentidade(identidade)) {
    //   messages.push('O campo "Documento de Identificação" está formatado incorretamente');
    // }
    // if (!validateIdentidade(comprovanteRenda)) {
    //   messages.push('O campo "Comprovante de Renda" está formatado incorretamente');
    // }
    // if (!validateIptu(iptu)) {
    //   messages.push('O campo "IPTU" está formatado incorretamente');
    // }

    setErrorMessage(messages);
    return messages.length === 0;
  };

  // const handleDownloadIptu = async () => {
  //   if (loadedFiles.iptu) {
  //     const file = await getFile(loadedFiles.iptu);
  //     window.open(file);
  //   }
  // };
  const handleDownloadProposta = async () => {
    if (loadedFiles.proposta) {
      const file = await getFile(loadedFiles.proposta);
      window.open(file);
    }
  };

  // const handleDownloadComprovanteRenda = async () => {
  //   if (loadedFiles.comprovanteRenda) {
  //     const file = await getFile(loadedFiles.comprovanteRenda);
  //     window.open(file);
  //   }
  // };
  //
  // const handleDownloadEquipamento = async () => {
  //   if (loadedFiles.equipamento) {
  //     const file = await getFile(loadedFiles.equipamento);
  //     window.open(file);
  //   }
  // };
  //
  // const handleDownloadIdentidade = async () => {
  //   if (loadedFiles.identidade) {
  //     const file = await getFile(loadedFiles.identidade);
  //     window.open(file);
  //   }
  // };
  //
  // const handleDownloadContaLuz = async () => {
  //   if (loadedFiles.contaLuz) {
  //     const file = await getFile(loadedFiles.contaLuz);
  //     window.open(file);
  //   }
  // };

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (validateForm()) {
      setLoadingText('Cadastrando Cliente...');
      setIsOpen(true);
      toast.info('Salvando informações do cliente! Por favor, aguarde');
      let fproposta = 'Sem documentos';
      // let fcontaluz = 'Sem documentos';
      // let fidentidade = 'Sem documentos';
      // let fcomprovanteRenda = 'Sem documentos';
      // let fiptu = 'Sem documentos';
      if (validateProposta(proposta)) {
        fproposta = await sendFile('proposta', proposta);
      }
      // if (validateContaLuz(contaLuz)) {
      //   fcontaluz = await sendFile('contaLuz', contaLuz);
      // }
      // if (validateIdentidade(identidade)) {
      //   fidentidade = await sendFile('identidade', identidade);
      // }
      // if (validateRendas(comprovanteRenda)) {
      //   fcomprovanteRenda = await sendFile('comprovanteRenda', comprovanteRenda);
      // }
      // if (validateIptu(iptu)) {
      //   fiptu = await sendFile('iptu', iptu);
      // }
      // ref!.current!.continuousStart(0, 200);
      const electricityBillOwnership = isClientOwner;
      const electricityBillFamiliarType = radioTypeFamilyValue;
      const projectId = getProjectId();
      const request = {
        headers: { 'Content-Type': 'multipart/form-data' },
        data: {
          projectId,

          nome,
          cpf,
          rg,
          dataNascimento,
          profissao,
          estadoCivil,
          tempoProfissao,
          valorPatrimonio,

          nomeMae,
          telefone,
          renda: renda.toString(),
          email,
          mediaLuz: mediaLuz.toString(),

          company_link: checkBusinessOwn,
          cnpj,
          dataAbertura: dataFundacao,
          razaoSocial,
          nomeFantasia,
          emailEmpresa,
          telefoneComercial: telefoneEmpresa,
          faturamentoMensal: rendaEmpresa.toString(),
          capitalSocial: capitalSocial.toString(),
          associates: associates || [],

          rua,
          district,
          numero,
          complemento,
          cep,
          cidade,

          potencia,
          type,

          //
          // electricityBillFamiliarType,
          // electricityBillOwnership,
          // fequipamento,
          // fcontaluz,
          // fidentidade,
          // fcomprovanteRenda,
          // fiptu,
          fproposta,
        },
      };

      const res = await api.post('project/costumer/put', request);

      switch (res.data) {
        case 'COSTUMER_UPDATED':
          // toast.success('Dados do
          // cliente cadastrados com sucesso. Aguardar a liberação
          // do painel de opções de crédito.');
          toast.success('Cadastro salvo!');
          ref?.current?.complete();
          window.location.assign('/AnaliseCredito');
          break;
        default:
          setIsOpen(false);
          toast.error(`Falha ao inserir cotação. ${res.data}`);
          // ref?.current?.complete();
      }
    } else {
      setIsOpen(false);
      toast.warn('Alguns campos não estão preenchidos corretamente.');
    }

    // ref?.current?.complete();
  };

  const handleStateChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const estadoId: number = parseInt(e.target.value, 10);
    setEstado(estadoId);
    getCidades(estadoId);
  };

  const handleCityChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setCidade(parseInt(e.target.value, 10));
  };

  const handleRuaChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRua(e.target.value);
  };
  const handleInputChangeRadioTypeFamily = (score: any) => {
    setRadioTypeFamilyValue(score);
  };
  const handleBusinessOwnership = (e: ChangeEvent<HTMLInputElement>) => {
    setCheckBusinessOwn(e.target.checked);
  };
  const handleAdicionarEntrada = async () => {
    const newAssociates : Associate[] = [];
    if (nomeAssociate === '') {
      toast.warning('Adicione um nome ao sócio.');
      return;
    }
    if (cpfAssociate === '') {
      toast.warning('Adicione o cpf do sócio.');
      return;
    }
    if (nomeMaeAssociate === '') {
      toast.warning('Adicione o nome da mãe do sócio.');
      return;
    }
    if (telefoneAssociate === '') {
      toast.warning('Adicione o telefone do sócio.');
      return;
    }
    if (associates) {
      for (let i = 0; i < associates.length; i++) { newAssociates.push(associates[i]); }
    }

    newAssociates.push({
      name: nomeAssociate,
      cpf: cpfAssociate,
      motherName: nomeMaeAssociate,
      phone: telefoneAssociate,
    });

    setNomeAssociate('');
    setAssociates(newAssociates);
    setCpfAssociate('');
    setNomeMaeAssociate('');
    setTelefoneAssociate('');
    // @ts-ignore
    nomeAssociateRef!.current.value = '';
    // @ts-ignore
    cpfAssociateRef!.current!.value = '';
    // @ts-ignore
    nomeMaeAssociateRef!.current!.value = '';
    // @ts-ignore
    telefoneAssociateRef!.current!.value = '';
  };
  const handleRemoveEntrada = (idx: number) => {
    if (associates.length > 0) {
      const nanalises : Associate[] = [];
      for (let i = 0; i < associates.length; i++) {
        if (i !== idx) nanalises.push(associates[i]);
      }
      setAssociates(nanalises);
    }
  };
  const handleTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setType(e.target.value);
  };
  return (
    <div>
      <LoadingBar color="#f11946" ref={ref} />
      <StepperProject activePage={2} projectId={projId} />
      <FormPage>
        <br />
        <form>
          <h1>Cadastro do Cliente</h1>
          {errorMessage.length > 0 && (
            <Baloon mode="warning">
              <ul>
                {errorMessage.map((m) => (
                  <li key={m}>{m}</li>
                ))}
              </ul>
            </Baloon>
          )}
          <div>
            <label>
              Nome Completo
              <RequiredFieldMark />
              <input
                type="text"
                id="f_nome"
                className={style.f_long_field}
                onChange={handleNomeChange}
                placeholder="Fulano Nome Completo"
              />
            </label>
            <br />
            <label>
              CPF
              <RequiredFieldMark />
              <input
                type="text"
                id="f_cpf"
                placeholder="000.000.000-00"
                maxLength={14}
                style={{ width: '50px' }}
                onChange={handleCpfChange}
              />
            </label>
            <label>
              RG
              <RequiredFieldMark />
              <input
                type="text"
                id="f_rg"
                style={{ width: '50px' }}
                placeholder="ABC1234ABC-98"
                onChange={handleRgChange}
              />
            </label>
            <label>
              Data de Nascimento
              <RequiredFieldMark />
              <input
                type="text"
                style={{ width: '50px' }}
                id="f_nasc"
                placeholder="00/00/0000"
                onChange={handleDataNascimentoChange}
              />
            </label>
            <br />
            <label>
              Nome da Mãe
              <RequiredFieldMark />
              <input
                type="text"
                id="f_nomemae"
                className={style.f_long_field}
                placeholder="Nome da Mãe"
                onChange={handleNomeMaeChange}
              />
            </label>
            <label>
              Telefone
              <RequiredFieldMark />
              <input
                type="text"
                id="f_telefone"
                style={{ width: '50px' }}
                placeholder="(00) 98765-4321"
                onChange={handleTelefoneChange}
              />
            </label>
            <br />
            <label>
              Renda/Faturamento Mensal (Líquida)
              <RequiredFieldMark />
              <input
                type="text"
                style={{ width: '50px' }}
                id="f_renda"
                placeholder="R$ 3.000,00"
                onChange={handleRendaChange}
              />
            </label>
            <br />
            <label>
              Estado civil
              <RequiredFieldMark />
              <select
                value={estadoCivil}
                onChange={handleEstadoCivilChange}
                id="f_estadoCivil"
              >
                {estadosCivil?.map((x) => (
                  <option key={x.key} value={x.name}>
                    {x.name}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Profissão
              <RequiredFieldMark />
              <input
                type="text"
                id="f_profissao"
                className={style.f_long_field}
                placeholder="Profissão"
                onChange={handleProfissaoChange}
              />
            </label>
            <label>
              Tempo de profissão (máx 50 anos)
              <RequiredFieldMark />
              <input
                type="text"
                onChange={handleTempoProfissaoChange}
                placeholder="Anos"
                id="f_tempoProfissao"
                style={{ width: '50px' }}
              />
            </label>
            <label>
              Valor do Patrimônio
              <RequiredFieldMark />
              <input
                type="text"
                id="f_patrimonio"
                onChange={handleValorPatrimonioChange}
                placeholder="R$ 123,55"
              />
            </label>
            <label>
              E-mail do Cliente
              <RequiredFieldMark />
              <input
                onChange={handleEmailChange}
                type="text"
                id="f_email"
                placeholder="cliente@domínio.com"
              />
            </label>
            <label>
              Média da Conta de Luz (últimos 3 meses)
              <RequiredFieldMark />
              <input
                type="text"
                id="f_luz"
                onChange={handleMediaLuzChange}
                placeholder="R$ 123,55"
              />
            </label>
            <label>
              Tipo do projeto
              <RequiredFieldMark />
              <select
                defaultValue={1}
                value={type}
                onChange={handleTypeChange}
                id="f_type"
              >
                {types?.map((x) => (
                  <option key={x.id} value={x.name}>
                    {x.name}
                  </option>
                ))}
              </select>
            </label>
            <br />
            {/* <label>
              Área de Atuação
              <RequiredFieldMark />
              <select id="f_area">
                <option value="1">-- selecione --</option>
                <option value="2">item 1</option>
                <option value="3">item 2</option>
              </select>
            </label>
            <label>
              Produção
              <RequiredFieldMark />
              <select id="f_producao">
                <option value="1">-- selecione --</option>
                <option value="2">item 1</option>
                <option value="3">item 2</option>
              </select>
            </label> */}
          </div>
          <br />
          <label>
            Projeto vinculado a empresa?
            <input
              type="checkbox"
              id="checkBusinessOwn"
              name="checkBusinessOwn"
              checked={checkBusinessOwn}
              onChange={handleBusinessOwnership}
            />
          </label>
          {checkBusinessOwn
            ? (
              <form>
                <h1>Dados da empresa</h1>
                <div>
                  <label>
                    CNPJ
                    <RequiredFieldMark />
                    <input
                      type="text"
                      id="f_cnpj"
                      placeholder="00.000.000/0000-00"
                      maxLength={18}
                      style={{ width: '50px' }}
                      onChange={handleCnpjChange}
                    />
                  </label>
                  <label>
                    Data de Fundação
                    <RequiredFieldMark />
                    <input
                      type="text"
                      style={{ width: '50px' }}
                      id="f_fund"
                      placeholder="00/00/0000"
                      onChange={handleDataFundacaoChange}
                    />
                  </label>
                  <label>
                    Razão Social
                    <RequiredFieldMark />
                    <input
                      type="text"
                      id="f_razao"
                      className={style.f_long_field}
                      onChange={handleRazaoChange}
                      placeholder="Razão Social"
                    />
                  </label>
                  <label>
                    Nome Fantasia
                    <RequiredFieldMark />
                    <input
                      type="text"
                      id="f_fantasia"
                      className={style.f_long_field}
                      onChange={handleNomeFantasiaChange}
                      placeholder="Nome Fantasia"
                    />
                  </label>
                  <label>
                    E-mail da empresa
                    <RequiredFieldMark />
                    <input
                      onChange={handleEmailEmpresaChange}
                      type="text"
                      id="f_email_empresa"
                      placeholder="empresa@domínio.com"
                    />
                  </label>
                  <br />
                  <label>
                    Telefone da Empresa
                    <RequiredFieldMark />
                    <input
                      type="text"
                      id="f_telefone_empresa"
                      style={{ width: '50px' }}
                      placeholder="(00) 98765-4321"
                      onChange={handleTelefoneEmpresaChange}
                    />
                  </label>
                  <br />
                  <br />
                  <label>
                    Faturamento Mensal da Empresa
                    <RequiredFieldMark />
                    <input
                      type="text"
                      style={{ width: '50px' }}
                      id="f_renda_empresa"
                      placeholder="R$ 3.000,00"
                      onChange={handleRendaEmpresaChange}
                    />
                  </label>
                  <label>
                    Capital Social
                    <RequiredFieldMark />
                    <input
                      type="text"
                      id="f_capital_social"
                      onChange={handleCapitalSocialChange}
                      placeholder="R$ 123,55"
                    />
                  </label>
                </div>
                {(costumerStatus === 'Cadastro')
                  && (
                  <div>
                    <br />
                    <br />
                    {/* Adição de sócios */}
                    <form>
                      <h1>Adicionar Sócios</h1>
                    </form>
                    <br />
                    <br />
                    <label>
                      Nome Completo
                      <RequiredFieldMark />
                      <input
                        type="text"
                        id="f_nome_associate"
                        ref={nomeAssociateRef}
                        className={style.f_long_field}
                        onChange={handleNomeAssociateChange}
                        placeholder="Sócio Nome Completo"
                      />
                    </label>
                    <label>
                      CPF
                      <RequiredFieldMark />
                      <input
                        type="text"
                        id="f_cpf_associate"
                        ref={cpfAssociateRef}
                        placeholder="000.000.000-00"
                        maxLength={14}
                        style={{ width: '50px' }}
                        onChange={handleCpfAssociateChange}
                      />
                    </label>
                    <label>
                      Nome da Mãe
                      <RequiredFieldMark />
                      <input
                        type="text"
                        id="f_nomemae_associate"
                        ref={nomeMaeAssociateRef}
                        className={style.f_long_field}
                        placeholder="Nome da Mãe"
                        onChange={handleNomeMaeAssociatedChange}
                      />
                    </label>
                    <br />
                    <label>
                      Telefone
                      <RequiredFieldMark />
                      <input
                        type="text"
                        id="f_telefone_associate"
                        ref={telefoneAssociateRef}
                        style={{ width: '50px' }}
                        placeholder="(00) 98765-4321"
                        onChange={handleTelefoneAssociateChange}
                      />
                    </label>
                    <label>
                    &nbsp;
                      <button
                        type="button"
                        style={{ display: 'block' }}
                        onClick={handleAdicionarEntrada}
                        disabled={(costumerStatus !== 'Cadastro')}
                      >
                        +
                      </button>
                    </label>
                  </div>
                  )}
                <br />
                <br />
                <form style={{ display: ((costumerStatus === 'Cadastro') ? 'block' : 'none') }}>
                  {((associates)
                    ? (associates.map((x) => (
                      <AssociateEditor
                        idx={associates.indexOf(x)}
                        key={associates.indexOf(x)}
                        data={x}
                        onClickParam={handleRemoveEntrada}
                        showRemove
                      />
                    )))
                    : (
                      <i>
                        Nenhum sócio adicionado. Preencha os campos abaixo e pressione
                        (+) para adicionar um novo sócio. Todos os campos poderão ser
                        revisados antes do envio.
                      </i>
                    ))}
                </form>
                <form style={{ display: ((costumerStatus !== 'Cadastro') ? 'block' : 'none') }}>
                  {((associates)
                    ? (associates.map((x) => (
                      <AssociateEditor
                        idx={associates.indexOf(x)}
                        key={associates.indexOf(x)}
                        data={x}
                        onClickParam={handleRemoveEntrada}
                        showRemove={false}
                      />
                    )))
                    : (
                      <i>
                        Nenhum sócio adicionado. Preencha os campos abaixo e pressione
                        (+) para adicionar um novo sócio. Todos os campos poderão ser
                        revisados antes do envio.
                      </i>
                    ))}
                </form>
              </form>
            )
            : ''}
          {/*  */}
          <h1>Endereço da Instalação</h1>
          <div>
            <label>
              CEP
              <RequiredFieldMark />
              <LoadingIcon active={isCepLoading} />
              <input
                style={{ width: '50px' }}
                type="text"
                onChange={handleCepChange}
                placeholder="00000-000"
                id="f_cep"
              />
            </label>
            <label>
              Logradouro
              <RequiredFieldMark />
              <input
                className={style.f_long_field}
                value={rua}
                type="text"
                onChange={handleRuaChange}
                placeholder="Rua de Exemplo"
                id="ruaField"
              />
            </label>
            <label>
              Bairro
              <input
                className={style.f_long_field}
                value={district}
                type="text"
                onChange={handleDistrictChange}
                placeholder="Bairro"
                id="districtField"
              />
            </label>
            <label>
              Número
              <RequiredFieldMark />
              <input
                value={numero}
                type="text"
                onChange={handleNumeroChange}
                placeholder="0000"
                id="f_numero"
                style={{ width: '50px' }}
              />
            </label>
            <label>
              Complemento
              <input
                value={complemento}
                type="text"
                id="f_complemento"
                onChange={handleComplementoChange}
                placeholder="Casa 0000"
                style={{ width: '50px' }}
              />
            </label>
            <br />
            <label>
              Estado
              <RequiredFieldMark />
              <select
                defaultValue={1}
                value={estado}
                onChange={handleStateChange}
                id="estadoField"
              >
                {estados?.map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.name}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Cidade
              <RequiredFieldMark />
              <select
                defaultValue={1}
                value={cidade}
                onChange={handleCityChange}
                id="cidadeField"
              >
                {cidades?.map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.name}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <h1>Outras Informações</h1>
          <div>
            <label>
              Potência a ser instalada
              <RequiredFieldMark />
              <input
                type="text"
                id="f_potencia"
                style={{ width: '50px' }}
                placeholder="12345 W"
                onChange={handlePotencia}
              />
            </label>
            <label>
              Custo do kWp
              <input
                type="text"
                id="f_custo_potencia"
                style={{ width: '50px' }}
              />
            </label>
          </div>
          <h1>Documentação</h1>
          <div>
            <table>
              <tbody>
                <tr>
                  <td>
                    <label htmlFor="upload_equipamento">
                      Proposta comercial feita para o cliente
                    </label>
                  </td>
                  <td>
                    <label>
                      <input
                        id="upload_proposta"
                        name="upload_proposta"
                        onChange={handlePropostaChange}
                        type="file"
                      />
                      {!loadedFiles.proposta && (
                        <img
                          src={uploadIcon}
                          alt="upload de proposta"
                          style={{ width: '30px' }}
                          className={style.uploadIcon}
                        />
                      )}
                      {
                        // eslint-disable-next-line no-nested-ternary
                        loadedFiles.proposta === 'Sem documentos'
                          ? 'Não enviado'
                          : !loadedFiles.proposta ? ''
                            : (
                              <button type="button" onClick={handleDownloadProposta}>
                                abrir arquivo
                              </button>
                            )
                      }
                    </label>
                  </td>
                  <td>{equipamento?.name}</td>
                </tr>
                {/* <tr> */}
                {/*   <td> */}
                {/*     <label htmlFor="upload_contaluz"> */}
                {/*       Última Conta de Luz */}
                {/*     </label> */}
                {/*   </td> */}
                {/*         <td> */}
                {/*           <label> */}
                {/*             <input */}
                {/*               id="upload_contaluz" */}
                {/*               name="upload_contaluz" */}
                {/*               onChange={handleContaLuzChange} */}
                {/*               type="file" */}
                {/*             /> */}
                {/*             {!loadedFiles.contaLuz && ( */}
                {/*               <img */}
                {/*                 src={uploadIcon} */}
                {/*                 alt="upload conta luz" */}
                {/*                 style={{ width: '15px' }} */}
                {/*                 className={style.uploadIcon} */}
                {/*               /> */}
                {/*             )} */}
                {/*             { */}
                {/*               // eslint-disable-next-line no-nested-ternary */}
                {/*               loadedFiles.contaLuz === 'Sem documentos' */}
                {/*                 ? 'Não enviado' */}
                {/*                 : !loadedFiles.contaLuz ? '' */}
                {/*                   : <button type="button" onClick={handleDownloadContaLuz}> */}
                {/* abrir arquivo</button> */}
                {/*             } */}
                {/*           </label> */}
                {/*         </td> */}
                {/*         <td>{contaLuz?.name}</td> */}
                {/*       </tr> */}
                {/*       {contaLuz && ( */}
                {/*         <tr> */}
                {/*           <tr> */}
                {/*             <td className={style.electricity_bill_ownership}> */}
                {/*               <label> */}
                {/*                 Conta de luz está no nome do cliente? */}
                {/*               </label> */}
                {/*             </td> */}
                {/*           </tr> */}
                {/*           <tr> */}
                {/*             <td className={style.electricity_bill_ownership}> */}
                {/*               <label> */}
                {/*                 <input */}
                {/*                   type="radio" */}
                {/*                   name="radioClientOwner" */}
                {/*                   checked={isClientOwner as boolean} */}
                {/*                   onChange={() => setIsClientOwner(true)} */}
                {/*                 /> */}
                {/*                 Sim */}
                {/*               </label> */}
                {/*               <label> */}
                {/*                 <input */}
                {/*                   type="radio" */}
                {/*                   checked={!isClientOwner as boolean} */}
                {/*                   onChange={() => setIsClientOwner(false)} */}
                {/*                   name="radioClientOwner" */}
                {/*                 /> */}
                {/*                 Não */}
                {/*               </label> */}
                {/*             </td> */}
                {/*           </tr> */}
                {/*         </tr> */}
                {/*       )} */}
                {/*       { !isClientOwner */}
                {/*         ? ( */}
                {/*           <tr> */}
                {/*             <td className={style.electricity_bill_ownership}> */}
                {/*               <label> */}
                {/*                 <input */}
                {/*                   value={1} */}
                {/*                   type="radio" */}
                {/*                   onChange={(e) => handleInputChangeRadioTypeFamily(1)} */}
                {/*                   name="radioTypeFamily" */}
                {/*                 /> */}
                {/*                 Mãe */}
                {/*               </label> */}
                {/*               <label> */}
                {/*                 <input */}
                {/*                   value={2} */}
                {/*                   type="radio" */}
                {/*                 // checked={!isClientOwner as boolean} */}
                {/*                   onChange={(e) => handleInputChangeRadioTypeFamily(2)} */}
                {/*                   name="radioTypeFamily" */}
                {/*                 /> */}
                {/*                 Pai */}
                {/*               </label> */}
                {/*               <label> */}
                {/*                 <input */}
                {/*                   value={3} */}
                {/*                   type="radio" */}
                {/*                 // checked={!isClientOwner as boolean} */}
                {/*                   onChange={(e) => handleInputChangeRadioTypeFamily(3)} */}
                {/*                   name="radioTypeFamily" */}
                {/*                 /> */}
                {/*                 Esposo(a) */}
                {/*               </label> */}
                {/*               <label> */}
                {/*                 <input */}
                {/*                   value={4} */}
                {/*                   type="radio" */}
                {/*                 // checked={!isClientOwner as boolean} */}
                {/*                   onChange={(e) => handleInputChangeRadioTypeFamily(4)} */}
                {/*                   name="radioTypeFamily" */}
                {/*                 /> */}
                {/*                 Filho(a) */}
                {/*               </label> */}
                {/*               <label> */}
                {/*                 <input */}
                {/*                   value={5} */}
                {/*                   type="radio" */}
                {/*                 // checked={!isClientOwner as boolean} */}
                {/*                   onChange={(e) => handleInputChangeRadioTypeFamily(5)} */}
                {/*                   name="radioTypeFamily" */}
                {/*                 /> */}
                {/*                 Irmão/Irmã */}
                {/*               </label> */}
                {/*             </td> */}
                {/*           </tr> */}
                {/*         ) */}
                {/*         : ''} */}
                {/*       <tr> */}
                {/*         <td> */}
                {/*           <label htmlFor="upload_identidade"> */}
                {/*             Documento de Identificação */}
                {/*           </label> */}
                {/*         </td> */}
                {/*         <td> */}
                {/*           <label> */}
                {/*             <input */}
                {/*               id="upload_identidade" */}
                {/*               name="upload_identidade" */}
                {/*               onChange={handleIdentidadeChange} */}
                {/*               type="file" */}
                {/*             /> */}
                {/*             {!loadedFiles.identidade && ( */}
                {/*               <img */}
                {/*                 src={uploadIcon} */}
                {/*                 alt="upload documento identificação" */}
                {/*                 style={{ width: '15px' }} */}
                {/*                 className={style.uploadIcon} */}
                {/*               /> */}
                {/*             )} */}
                {/*             { */}
                {/*               // eslint-disable-next-line no-nested-ternary */}
                {/*               loadedFiles.identidade === 'Sem documentos' */}
                {/*                 ? 'Não enviado' */}
                {/*                 : !loadedFiles.identidade ? '' */}
                {/*                   : ( */}
                {/* <button type="button" onClick={handleDownloadIdentidade}>
                abrir arquivo</button> */}
                {/* ) */}
                {/*             } */}
                {/*           </label> */}
                {/*         </td> */}
                {/*         <td>{identidade?.name}</td> */}
                {/*       </tr> */}
                {/*       <tr> */}
                {/*         <td> */}
                {/*           <label htmlFor="upload_comprovanteRenda"> */}
                {/*             Comprovante de Renda */}
                {/*           </label> */}
                {/*         </td> */}
                {/*         <td> */}
                {/*           <label> */}
                {/*             <input */}
                {/*               id="upload_comprovanteRenda" */}
                {/*               name="upload_comprovanteRenda" */}
                {/*               onChange={handleComprovanteRendaChange} */}
                {/*               type="file" */}
                {/*             /> */}
                {/*             {!loadedFiles.comprovanteRenda && ( */}
                {/*               <img */}
                {/*                 src={uploadIcon} */}
                {/*                 alt="upload comprovante renda" */}
                {/*                 style={{ width: '15px' }} */}
                {/*                 className={style.uploadIcon} */}
                {/*               /> */}
                {/*             )} */}
                {/*             { */}
                {/*               // eslint-disable-next-line no-nested-ternary */}
                {/*               loadedFiles.comprovanteRenda === 'Sem documentos' */}
                {/*                 ? 'Não enviado' */}
                {/*                 : !loadedFiles.comprovanteRenda ? '' */}
                {/*                   : ( */}
                {/* <button type="button" onClick={handleDownloadComprovanteRenda}> */}
                {/*   abrir arquivo */}
                {/* </button> */}
                {/* ) */}
                {/*             } */}
                {/*           </label> */}
                {/*         </td> */}
                {/*         <td>{comprovanteRenda?.name}</td> */}
                {/*       </tr> */}
                {/*       <tr> */}
                {/*         <td> */}
                {/*           <label htmlFor="upload_iptu"> */}
                {/*             IPTU (em nome do cliente) */}
                {/*           </label> */}
                {/*         </td> */}
                {/*         <td> */}
                {/*           <label> */}
                {/*             <input */}
                {/*               id="upload_iptu" */}
                {/*               name="upload_iptu" */}
                {/*               onChange={handleIptuChange} */}
                {/*               type="file" */}
                {/*             /> */}
                {/*             {!loadedFiles.iptu && ( */}
                {/*               <img */}
                {/*                 src={uploadIcon} */}
                {/*                 alt="upload comprovante iptu" */}
                {/*                 style={{ width: '15px' }} */}
                {/*                 className={style.uploadIcon} */}
                {/*               /> */}
                {/*             )} */}
                {/*             { */}
                {/*               // eslint-disable-next-line no-nested-ternary */}
                {/*               loadedFiles.iptu === 'Sem documentos' */}
                {/*                 ? 'Não enviado' */}
                {/*                 : !loadedFiles.iptu ? '' */}
                {/*                   : ( */}
                {/* <button type="button" onClick={handleDownloadIptu}>abrir arquivo</button> */}
                {/* ) */}
                {/*             } */}
                {/*           </label> */}
                {/*         </td> */}
                {/*         <td>{iptu?.name}</td> */}
                {/*       </tr> */}
              </tbody>
            </table>
          </div>
          <br />
          <br />
          <hr />
          {/* <label>
            <input type="checkbox" id="scales" name="scales" />
            {text_produtor}
          </label> */}
          <br />
          <label>
            <input
              type="checkbox"
              id="checkDeclaro"
              name="checkDeclaro"
              checked={checkDeclaro}
              onChange={checkDeclaroChange}
            />
            {text_declaracao}
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              id="checkAutorizo"
              name="checkAutorizo"
              checked={checkAutorizo}
              onChange={checkAutorizoChange}
            />
            {text_autorizacao}
            <Anchor to="/termos">Ficha de Autorização do Uso de Dados</Anchor>
            .
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              id="checkTermos"
              name="checkTermos"
              checked={checkTermos}
              onChange={checkTermosChange}
            />
            {text_concordo_1}
            {' '}
            <Anchor to="/termos">Política de Privacidade</Anchor>
            {text_concordo_2}
            {' '}
            <Anchor to="/termos">Termos de Uso</Anchor>
            {text_concordo_3}
          </label>
          <br />
          <br />
          <input type="submit" id="submit_btn" value="Continuar >" onClick={handleSubmit} />
        </form>
        <br />
      </FormPage>
      <ToastContainer />
      <DialogComponent isOpen={isOpen} labelText={loadingText} />
    </div>
  );
}
