/* eslint-disable import/extensions */
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { setCookie, parseCookies, destroyCookie } from 'nookies';
import { useHistory } from 'react-router-dom';
import { api } from '../services/api';

type User = {
  email: string;
  roles: string[];
};

type SignInCredentials = {
  email: string;
  password: string;
};

type AuthContextData = {
  signIn(credentials: SignInCredentials): Promise<any>;
  user?: User;
  isAuthenticated: boolean;
  isLoading: boolean;
};

type AuthProviderProps = {
  children: ReactNode;
};

export function signOut() {
  destroyCookie(undefined, 'nextauth.token');
  destroyCookie(undefined, 'nextauth.refreshToken');
  destroyCookie(undefined, 'nextauth.roles');
}

export const AuthContext = createContext({} as AuthContextData);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<User>();
  const [isLoading, setIsLoading] = useState(false);
  const isAuthenticated = !!user;

  useEffect(() => {
    const { 'nextauth.token': token } = parseCookies();

    async function loadUser() {
      api
        .get('/me')
        .then((response: any) => {
          const { email, roles } = response.data;
          setUser({ email, roles });
        })
        .catch((error: any) => {
          // An error not related to the refresh token happened
          console.log(error);
          signOut();
        })
        .finally(() => {
          // Always set loading to false
          setIsLoading(false);
        });
    }
    // when we first open the application, if there is a token, let's use it to
    // retrieve the user's info and update the state
    if (token) {
      loadUser();
    }
  }, []);

  async function signIn({ email, password }: SignInCredentials): Promise<any> {
    try {
      // Call the api to sign in the user
      const response = await api.post('login', {
        email,
        password,
      });

      // Extract the token, refresh token, roles and permissions from the response
      const {
        id, token, refreshToken, roles, corpStatus,
      } = response.data;
      // Save JWT token in cookie
      setCookie(undefined, 'nextauth.token', token, {
        maxAge: 60 * 60 * 24 * 30, // 30 days,
        path: '/',
        sameSite: 'none',
        secure: true,
      });

      // Save userId
      setCookie(undefined, 'nextauth.tag', id, {
        maxAge: 60 * 60 * 24 * 30, // 30 days,
        path: '/',
        sameSite: 'none',
        secure: true,
      });

      // Save user roles into a cookie
      setCookie(undefined, 'nextauth.roles', roles, {
        maxAge: 60 * 60 * 24 * 30, // 30 days,
        path: '/',
        sameSite: 'none',
        secure: true,
      });

      // Save refresh token in cookie
      setCookie(undefined, 'nextauth.refreshToken', refreshToken, {
        maxAge: 60 * 60 * 24 * 30, // 30 days,
        path: '/',
        sameSite: 'none',
        secure: true,
      });

      // Save refresh token in cookie
      setCookie(undefined, 'nextauth.corpStatus', corpStatus, {
        maxAge: 60 * 60 * 24 * 30, // 30 days,
        path: '/',
        sameSite: 'none',
        secure: true,
      });

      // Update the state with the user's info
      setUser({
        email,
        roles,
      });

      // Update the default token being used after we authenticate
      // because we just got a new token and refresh token
      // Obs: it is accusing an error on HeaderDefaults but it is not. Should be a bug on axios
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      // After authentication, send the user to the dashboard
      if (corpStatus === 'Sem documentos') {
        return 'Sem documentos';
      } if (corpStatus === 'Pendente') {
        return 'Pendente';
      } if (corpStatus === 'Recusado') {
        return 'Recusado';
      }
      return 'OK';
    } catch (error) {
      // TODO: handle sign in error
      console.log(error);
      return 'NOK';
    }
  }

  return (
    <AuthContext.Provider
      value={{
        signIn,
        isAuthenticated,
        user,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}
