import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { api } from '../../services/api';
import Card from '../../components/elements/Card';
import { SectionTitle } from '../../components/elements/SectionTitle';
import { Bank } from '../../contexts/@types';
import { sortArray } from '../../utils/util';
import BanksTable from '../../components/BanksTable';

export function ListaBancos() {
  const [banks, setBanks] = useState<Array<Bank>>([]);

  useEffect(() => {
    const getBanks = async () => {
      const response: Promise<AxiosResponse> = api.post('/bank/manager/get');
      response.then((resolved) => {
        const items: Array<Bank> = resolved.data;
        setBanks(items);
        return items;
      });
    };
    getBanks().then(() => {});
  }, []);

  const sortBanks = (attribute: keyof Bank) => {
    setBanks(sortArray(banks, attribute));
  };

  return (
    <Card>
      <SectionTitle title="Lista de bancos" />
      <BanksTable banks={banks} sortBanks={sortBanks} />
    </Card>
  );
}
