import { setCookie } from 'nookies';
import { NavLink, useHistory } from 'react-router-dom';
// import style from './notificationitem.module.scss';
import {
  alpha, Badge, Box, ListItem, Typography,
} from '@mui/material';
import { formatDistance, subDays } from 'date-fns';
import pt from 'date-fns/locale/pt';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import NotificationsIcon from '@mui/icons-material/Notifications';
import React from 'react';
import { styled } from '@mui/material/styles';
import { AxiosResponse } from 'axios';
import { api } from '../../services/api';

interface NotificationData {
  type: string,
  message: string,
  title: string,
  id: number;
  projectId: number;
  userId: number;
  clientId: number;
  read: boolean;
  toRole: string;
  readAt: Date;
  createdAt: Date;
}

interface Props {
  data: NotificationData;
}

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 30px; 
        height: 30px;
        padding: 0;
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`,
);

const NotificationsReadBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        // background-color: ${alpha(theme.palette.primary.main, 0.1)};
        color: ${theme.palette.primary.main};
        min-width: 30px; 
        height: 30px;
        padding: 0;
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            // box-shadow: 0 0 0 1px ${alpha(theme.palette.primary.main, 0.3)};
            content: "";
        }
    }
`,
);

const NotificationItem = (props: Props) => {
  const history = useHistory();
  const { data } = props;
  const {
    type, message, title, id, projectId, userId, clientId, read, toRole, readAt, createdAt,
  } = data;
  // let choosenStatus = '';
  // if (status === 'Aprovado') {
  //   choosenStatus = style.StatusVerde;
  // } else if (status === 'Reprovado') {
  //   choosenStatus = style.StatusVermelho;
  // } else {
  //   // Aguardando
  //   choosenStatus = style.StatusAmarelo;
  // }

  const notificationTarget = () => {
    const response: Promise<AxiosResponse<any, any>> = api.post(
      '/notifications/markAsRead',
      { id },
    );
    if (type === 'message') {
      history.push({
        pathname: '/DetalheCotacaoOld',
        // search: `?id=${data.id}`,
        state: { id: projectId },
      });
    } else if (type === 'notification') {
      history.push({
        pathname: '/DetalheCotacaoOld',
        // search: `?id=${data.id}`,
        state: { id: projectId },
      });
    }
  };

  return (
    <ListItem
      sx={{ p: 2, minWidth: 350, display: { xs: 'block', sm: 'flex' } }}
      onClick={notificationTarget}
    >
      <Box flex="1">
        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ fontWeight: 'bold' }}>
            {title}
          </Typography>
          <Typography variant="caption" sx={{ textTransform: 'none' }}>
            {formatDistance(new Date(createdAt), new Date(), {
              locale: pt,
              addSuffix: true,
            })}
          </Typography>
          {!read
            ? (
              <NotificationsBadge
                style={{ color: '#e85b13' }}
                badgeContent="Novo"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              />
            ) : (
              <NotificationsReadBadge
                style={{ color: '#1325e8' }}
                badgeContent="Lido"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              />
            )}
        </Box>
        <Typography
          component="span"
          variant="body2"
          color="text.secondary"
        >
          {' '}
          {message}
        </Typography>
      </Box>
    </ListItem>
  );
};

export { NotificationItem };
