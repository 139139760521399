import { useHistory } from 'react-router-dom';
import React, { ChangeEvent, useEffect, useState } from 'react';
import style from './analisecreditoeditor.module.scss';
import { percentageValue } from '../../utils/util';
import { DialogAnalysesComponent } from '../DialogEditAnalysesComponent';

interface Banco{
  id: number;
  name: string;
  email: string;
}
interface CreditOption{
  id?: number;
  parcelas: number;
  banco: string;
  bancoId: number;
  valorParcela: number;
  taxa: number
  choosed: boolean
  bancoDoc: any
}

interface Props {
  allowEdit: boolean,
  data: CreditOption;
  onClickParam: Function;
  idx: number;
  showRemove: boolean;
  updateAnaliseCredito: Function;
}

const AnaliseCreditoEditor = (props: Props) => {
  const {
    allowEdit, data, onClickParam, idx, showRemove, updateAnaliseCredito,
  } = props;

  const {
    id, parcelas, banco, bancoId, valorParcela, taxa, bancoDoc, choosed,
  } = data;
  const setAttribute = (idAtt: string, attrib: string, value: any) => {
    document.getElementById(idAtt)?.setAttribute(attrib, value);
  };
  const [isOpen, setIsOpen] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [bancoEdit, setBancoEdit] = useState<Banco | null>(null);
  const applyCurrencyMask = (n: string) => {
    const value : string = n.toString().split('').reverse().join('');

    let newValue = '';
    for (let x = 0; x < value.length; x++) {
      newValue += value[x];
      if (x === 1) {
        if (x < value.length - 1) { newValue += ','; }
      }

      if (x === 4 || x === 7) {
        if (x < value.length - 1) { newValue += '.'; }
      }
    }

    newValue = newValue.split('').reverse().join('');
    newValue = `R$ ${newValue}`;
    return newValue;
  };
  const history = useHistory();
  const [editField, setEditField] = useState(false);
  const [taxaEdit, setTaxaEdit] = useState<number>(taxa);
  // const [id, setID] = useState(id);
  const [parcelasEdit, setParcelasEdit] = useState(parcelas);
  // eslint-disable-next-line max-len
  const [valorParcelasEdit, setValorParcelasEdit] = useState<string>(applyCurrencyMask(valorParcela.toString()));
  const [analiseCredito, setAnaliseCredito] = useState<CreditOption>();
  useEffect(() => {
    setAttribute('valorParcela', 'value', valorParcelasEdit);
    setAttribute('taxa', 'value', taxaEdit);
    setAttribute('numeroParcela', 'value', parcelasEdit);
  });
  const removeNonNumeric = (n: string) => {
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    let value = '';
    for (let i = 0; i < n.length; ++i) {
      if (mask.includes(n[i])) {
        value += n[i];
      }
    }
    // return (value.length === 0) ? '0' : value;
    return (value.length === 0) ? '0' : parseInt(value, 10).toString();
  };
  const handleParcelasChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = removeNonNumeric(e.target.value);

    // if (parseInt(value, 10) === 0) value = '1';

    setParcelasEdit(parseInt(value, 10));
    // e.target.value = applyCurrencyMask(value);
    e.target.value = value;
  };
  const handleValorParcelaChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = removeNonNumeric(e.target.value);
    setValorParcelasEdit(value);
    e.target.value = applyCurrencyMask(value);
  };
  const updateAnaliseCreditoProp = async () => {
    // eslint-disable-next-line no-restricted-globals,no-alert
    const option = confirm('Deseja confirmar as informações do crédito?.');
    if (option) {
      const analiseNew = {
        id,
        parcelas: parcelasEdit,
        banco,
        bancoId,
        valorParcela: Number(removeNonNumeric(valorParcelasEdit)),
        taxa: taxaEdit,
        bancoDoc,
        choosed,
      };
      setEditField(false);
      setAnaliseCredito(analiseNew);
      updateAnaliseCredito(analiseNew, idx);
    }
    setEditField(false);
  };
  const setDialogClose = async () => {
    setIsOpen(false);
  };
  const handleTaxaChange = (e: ChangeEvent<HTMLInputElement>) => {
    const mask = '0123456789.';

    // remove unwanted characters (including recently pressed ones)
    let value = '';
    for (let i = 0; i < e.target.value.length; ++i) {
      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    setTaxaEdit(parseFloat(value));
    e.target.value = percentageValue(value);
  };
  return (
    <div className={style.listaCadastroItemClass}>
      <table>
        <tbody>
          <tr>
            <td style={{ width: '35%' }}>
              <span>Banco</span>
              <br />
              #
              {banco}
            </td>
            <td style={{ width: '25%' }}>
              <span>Número de Parcelas</span>
              <br />
              {parcelas}
            </td>
            <td className={style.cnpjCelula}>
              <span>Valor da Parcela</span>
              <br />
              {applyCurrencyMask(valorParcela.toString())}
            </td>
            <td className={style.telefoneCelula} style={{ width: '15%' }}>
              <span>Taxa</span>
              <br />
              {taxa}
              %
            </td>
            <td style={{ width: '70px', display: (showRemove ? 'block' : 'none') }}>
              <button type="button" style={{ width: '70px' }} onClick={() => onClickParam(idx)}>Remover</button>
            </td>
            <td style={{ width: '70px', display: (allowEdit ? 'block' : 'none') }}>
              <button type="button" style={{ width: '70px' }} onClick={() => setIsOpen(true)}>Editar</button>
            </td>
          </tr>
        </tbody>
      </table>
      <DialogAnalysesComponent
        isOpen={isOpen}
        labelText={loadingText}
        updateAnaliseCredito={updateAnaliseCredito}
        idx={idx}
        data={data}
        setDialogClose={setDialogClose}
      />
    </div>
  );
};

export { AnaliseCreditoEditor };
