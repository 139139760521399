const labelFormatter = (value: string, words: number) => {
  const completeName = value.split(' ');
  if (completeName.length > words) {
    return [
      completeName.slice(0, words).join(' '),
      completeName.slice(words, completeName.length).join(' '),
    ];
  }

  return [completeName.join(' ')];
};

export const responsiveChart = [{
  breakpoint: 900,
  options: {
    xaxis: {
      labels: {
        formatter(value: string) {
          return value;
        },
        style: {
          fontSize: '10px',
        },
      },
    },
  },
}, {
  breakpoint: 2000,
  options: {
    xaxis: {
      labels: {
        formatter(value: string) {
          return labelFormatter(value, 2);
        },
        style: {
          fontSize: '9px',
        },
      },
    },
  },
}];
