import * as React from 'react';
import {
  Button as MuiButton, ButtonProps as MuiButtonProps, createTheme, ThemeProvider,
} from '@mui/material';

const buttonThemes = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            borderRadius: '2rem',
            backgroundColor: '#EB5A12',
            '&:hover': {
              backgroundColor: '#FF7F50',
            },
            fontFamily: 'Poppins',
            textTransform: 'none',
            fontWeight: 700,
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            color: '#EB5A12',
            border: '1px #EB5A12 solid',
            borderRadius: '2rem',
            backgroundColor: '#fff',
            '&:hover': {
              borderColor: '#EB5A12',
              backgroundColor: 'rgba(255,127,80,0.2)',
            },
            fontFamily: 'Poppins',
            textTransform: 'none',
            fontWeight: 700,
          },
        },
      ],
    },
  },
});

interface ButtonProps extends MuiButtonProps {
  children: React.ReactNode,
  variant: 'contained' | 'outlined',
}
export default function Button(props: ButtonProps) {
  const {
    children,
    variant,
    ...other
  }: ButtonProps = props;
  return (
    <ThemeProvider theme={buttonThemes}>
      <MuiButton variant={variant} {...other}>{children}</MuiButton>
    </ThemeProvider>
  );
}
