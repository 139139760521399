import { Box, Button, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import React, { useState } from 'react';
import style from '../../../pages/DetalheCotacao/detalhecotacao.module.scss';
import pdfIcon from '../../../assets/images/downloadPDF.png';
import PDFViewer from '../../PDFViewer';
import { getFile } from '../../../lib/download';

interface Props {
  title: string,
  file: string | File | null,
  sx?: SxProps,
}
const DocumentDisplay = (props: Props) => {
  const { title, file, sx } = props;

  const [isPDFOpen, setIsPDFOpen] = useState(false);
  const [pdfLink, setPdfLink] = useState('');

  const openPdfDialog = async (url: string) => {
    if (url && url.length > 0) {
      const link = await getFile(url);
      setPdfLink(link);
      setIsPDFOpen(true);
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexGrow={1}
        sx={sx}
      >
        <Typography sx={{
          fontSize: { xs: '12px', lg: '16px' },
          fontFamily: 'Poppins',
          color: '#19171C',
          fontWeight: '700',
        }}
        >
          {title}
        </Typography>
        {(file && typeof file === 'string' && file !== 'Sem documentos') && (
          <Button
            onClick={() => openPdfDialog(file as string)}
          >
            <img
              className={style.detalheCadastroIcon}
              src={pdfIcon}
              alt={`Abrir documento: ${title}`}
            />
          </Button>
        )}
        {((typeof file === 'string' && file === 'Sem documentos') || !file) && (
          <Typography sx={{
            ml: 2,
            fontSize: { xs: '12px', lg: '16px' },
            fontFamily: 'Poppins',
            fontWeight: '400',
          }}
          >
            Não enviado
          </Typography>
        )}
      </Box>
      <PDFViewer
        isOpen={isPDFOpen}
        url={pdfLink}
        close={() => setIsPDFOpen(false)}
      />
    </>
  );
};

export { DocumentDisplay };
