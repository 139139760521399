import {
  alpha,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { styled } from '@mui/material/styles';
import { AxiosResponse } from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { usePusher } from '../../hooks/PusherContext';
import { NotificationItem } from '../NotificationItem';
import { api } from '../../services/api';

interface NotificationData {
  type: string,
  message: string,
  title: string,
  id: number;
  projectId: number;
  userId: number;
  clientId: number;
  read: boolean;
  toRole: string;
  readAt: Date;
  createdAt: Date;
}

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`,
);

function NotificationButton() {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [notifications, setNotifications] = useState<NotificationData[]>();

  const pusher = usePusher();

  const loadNotifications = async () => {
    const response: Promise<AxiosResponse<any, any>> = api.post(
      '/notifications/getAllSoltech',
      { id: 1 },
    );
    response.then(async (resolved) => {
      const { data } = resolved;
      setNotifications(data);
      if (data) {
        // @ts-ignore
        const filteredItems = data.filter(
          // @ts-ignore
          (item) => !item.read,
        );
        setUnreadNotifications(filteredItems.length);
      }
    });
  };

  useEffect(() => {
    const channelNotification = pusher.subscribe('soltech');

    channelNotification.bind('soltech_general', (data: any) => {
      loadNotifications();
    });
    return (() => {
      pusher.unsubscribe('soltech');
    });

    // If Use-effect is changed run without the disabled eslint to check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadNotifications();
  }, []);
  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip arrow title="Notifications">
        <IconButton ref={ref} onClick={handleOpen}>
          <NotificationsBadge
            style={{ color: '#e85b13' }}
            badgeContent={unreadNotifications}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            { unreadNotifications
              ? <NotificationsActiveTwoToneIcon />
              : <NotificationsIcon />}
          </NotificationsBadge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ maxHeight: 450, overflow: 'auto' }}
      >
        <Box
          sx={{ p: 2 }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          style={{ backgroundColor: '#ff8541', color: 'white' }}
        >
          <Typography variant="subtitle1">Notificações</Typography>
        </Box>
        <Divider />
        <List sx={{ p: 4 }}>
          { notifications?.length === 0 || !notifications ? 'Sem novas notificações!'
            : notifications?.map((x: NotificationData) => (
              <NotificationItem data={x} />
            ))}
        </List>
        <ToastContainer />
      </Popover>
    </>
  );
}

export default NotificationButton;
