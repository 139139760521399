import {
  Box,
  Typography, useMediaQuery, useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import { parseCookies } from 'nookies';
import { AxiosResponse } from 'axios';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { api } from '../../../services/api';

interface ProjectsByUserMonth {
  name: string;
  userProjects: number;
  userDoneProjects: number;
}

interface LastProjects {
  doneProjects: number;

}

interface ProjectsComparation {
  monthYear: string;
  newProjects: number;
  doneProjects: number;
  projectsPass: any;

}
interface BarChartsProps {
  setIsOpen: Function
}
function BarCharts(props: BarChartsProps) {
  const {
    setIsOpen,
  }: BarChartsProps = props;
  const theme = useTheme();
  const [projectsByUserMonth, setProjectsByUserMonth] = useState<ProjectsByUserMonth[]>([]);
  const [lastProjects, setLastProjects] = useState<LastProjects[]>([]);
  const [projectsComparation, setProjectsComparation] = useState<ProjectsComparation[]>([]);
  const { 'nextauth.tag': tag } = parseCookies();
  const { 'nextauth.roles': cookieRoles } = parseCookies();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));
  const statusProjectsChartWidth = () => {
    if (isXs || isSm) return 1000;

    return '100%';
  };
  const statusProjectsChartContainerWidth = () => {
    if (isXs) return '80vw';
    if (isSm) return '85vw';

    return '100%';
  };

  const statusProjectsChartHeight = () => {
    if (isLg && cookieRoles === 'Vendedor') return '12.5rem';
    if (isLg && cookieRoles === 'Integradora') return '12.5rem';
    if (isXl && cookieRoles === 'Vendedor') return '18rem';
    if (isXl && cookieRoles === 'Integradora') return '14rem';

    return '12rem';
  };

  useEffect(() => {
    const getProjectData = async () => {
      setIsOpen(true);
      let postRoute;
      if (cookieRoles === 'Integradora') {
        postRoute = 'integrator';
      } else if (cookieRoles === 'Vendedor') {
        postRoute = 'vendor';
      }
      const response: Promise<AxiosResponse> = api.post(
        `user/${postRoute}/data/get`,
        { userId: tag },
      );
      response.then((resolved) => {
        setIsOpen(false);
        const { data } = resolved;
        if (cookieRoles === 'Integradora') {
          setProjectsByUserMonth(data.projectsByUserMonth);
        }
        setLastProjects(data.lastProjects || '');
        setProjectsComparation(data.projectsComparation.projectsPass);
      });
    };
    setIsOpen(true);
    getProjectData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const state = {
    sellerSeries: [{
      name: 'Projetos Criados',
      data: [projectsByUserMonth[0]?.userProjects,
        projectsByUserMonth[1]?.userProjects],
      color: '#3c3c3b',
    }, {
      name: 'Projetos Contratados',
      data: [projectsByUserMonth[0]?.userDoneProjects,
        projectsByUserMonth[1]?.userDoneProjects],
      color: '#ea5b13',
    }],
    projectsSeriesChart: [{
      name: 'Mês anterior',
      data: [lastProjects[0]?.doneProjects],
      color: '#3c3c3b',
    }, {
      name: 'Mês atual',
      data: [lastProjects[1]?.doneProjects],
      color: '#ea5b13',
    }],
    statusSeriesProjects: [{
      name: 'Novos Projetos',
      data: [
        projectsComparation[0]?.newProjects, projectsComparation[1]?.newProjects,
        projectsComparation[2]?.newProjects, projectsComparation[3]?.newProjects,
        projectsComparation[4]?.newProjects, projectsComparation[5]?.newProjects,
        projectsComparation[6]?.newProjects, projectsComparation[7]?.newProjects,
        projectsComparation[8]?.newProjects, projectsComparation[9]?.newProjects,
        projectsComparation[10]?.newProjects,
        projectsComparation[11]?.newProjects,
      ],
      color: '#3c3c3b',
    }, {
      name: 'Projetos Contratados',
      data: [
        projectsComparation[0]?.doneProjects, projectsComparation[1]?.doneProjects,
        projectsComparation[2]?.doneProjects, projectsComparation[3]?.doneProjects,
        projectsComparation[4]?.doneProjects, projectsComparation[5]?.doneProjects,
        projectsComparation[6]?.doneProjects, projectsComparation[7]?.doneProjects,
        projectsComparation[8]?.doneProjects, projectsComparation[9]?.doneProjects,
        projectsComparation[10]?.doneProjects,
        projectsComparation[11]?.doneProjects,
      ],
      color: '#ea5b13',
    }],
  };

  const projectsOptionsChart: ApexOptions = {
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: ['Projetos efetivados'],
      labels: {
        rotate: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Projetos',
      },
    },
    fill: {
      colors: ['#3c3c3b', '#ea5b13'],
      opacity: 1,
    },
    legend: {
      labels: {
        useSeriesColors: true,
      },
      markers: {
        fillColors: ['#3c3c3b', '#ea5b13'],
      },
    },
  };

  const sellersOptionsChart: ApexOptions = {
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: [projectsByUserMonth[0]?.name, projectsByUserMonth[1]?.name],
      labels: {
        rotate: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Projetos',
      },
    },
    fill: {
      colors: ['#3c3c3b', '#ea5b13'],
      opacity: 1,
    },
    legend: {
      labels: {
        useSeriesColors: true,
      },
      markers: {
        fillColors: ['#3c3c3b', '#ea5b13'],
      },
    },
  };

  const statusProjectsOptions: ApexOptions = {
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    legend: {
      labels: {
        useSeriesColors: true,
      },
      markers: {
        fillColors: ['#3c3c3b', '#ea5b13'],
      },
    },
    xaxis: {
      categories: [
        projectsComparation[0]?.monthYear, projectsComparation[1]?.monthYear,
        projectsComparation[2]?.monthYear, projectsComparation[3]?.monthYear,
        projectsComparation[4]?.monthYear, projectsComparation[5]?.monthYear,
        projectsComparation[6]?.monthYear, projectsComparation[7]?.monthYear,
        projectsComparation[8]?.monthYear, projectsComparation[9]?.monthYear,
        projectsComparation[10]?.monthYear,
        projectsComparation[11]?.monthYear,
      ],
    },
    yaxis: {
      title: {
        text: 'Projetos',
      },
    },
    fill: {
      colors: ['#3c3c3b', '#ea5b13'],
      opacity: 1,
    },
  };

  const cardStyle = {
    backgroundColor: '#fff',
    boxShadow: '0px 2px 2px 0px #0000001A',
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: '0.5rem',
  };
  const dynamicWidth = 10 * 100;
  const chartWidth = dynamicWidth < window.innerWidth ? '100%' : dynamicWidth;
  return (
    <Grid
      container
      spacing={4}
      rowSpacing={2}
      sx={{
        display: 'flex',
        height: '100%',
        justifyContent: 'space-between',
      }}
    >
      {cookieRoles === 'Integradora' && (
      <Grid
        container
        xs={12}
      >
        <Grid xs={12} md={6}>
          <Box sx={{ ...cardStyle, minWidth: 0 }}>
            <Typography sx={{
              fontFamily: 'Poppins',
              fontSize: { xs: '12px', md: '16px' },
              textAlign: 'center',
              fontWeight: '700',
            }}
            >
              Status mensal de projetos
            </Typography>
            <ReactApexChart
                // @ts-ignore
              options={projectsOptionsChart}
              series={state.projectsSeriesChart}
              type="bar"
            />
          </Box>
        </Grid>
        <Grid xs={12} md={6}>
          <Box sx={cardStyle}>
            <Typography sx={{
              fontFamily: 'Poppins',
              fontSize: { xs: '12px', md: '16px' },
              textAlign: 'center',
              fontWeight: '700',
            }}
            >
              Vendedores que mais simulam
            </Typography>
            <ReactApexChart
                // @ts-ignore
              options={sellersOptionsChart}
              series={state.sellerSeries}
              type="bar"
            />
          </Box>
        </Grid>
      </Grid>
      )}
      <Grid xs={12}>
        <Box sx={{ ...cardStyle }}>
          <Typography sx={{
            fontFamily: 'Poppins',
            fontSize: {
              xs: '12px',
              md: '16px',
            },
            textAlign: 'center',
            fontWeight: '700',
            height: 'fit-content',
          }}
          >
            Status de projetos simulados e efetivados
          </Typography>

          <Box
            sx={{
              minHeight: statusProjectsChartHeight(),
              maxWidth: statusProjectsChartContainerWidth(),
              overflowY: 'hidden',
              overflowX: { xs: 'auto', md: 'hidden' },
              margin: '0 auto',
              scrollbarColor: '#EA5B13 lightgrey',
            }}
          >
            <ReactApexChart
              options={statusProjectsOptions}
              series={state.statusSeriesProjects}
              type="bar"
              width={statusProjectsChartWidth()}
              height="100%"
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
export default BarCharts;
