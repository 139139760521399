import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState, ChangeEvent } from 'react';
import { percentageValue } from '../../utils/util';

interface Banco{
  id: number;
  name: string;
  email: string;
}
interface CreditOption{
  id?: number;
  parcelas: number;
  banco: string;
  bancoId: number;
  valorParcela: number;
  taxa: number
  choosed: boolean
  bancoDoc: any
}
interface Props {
  // eslint-disable-next-line react/no-unused-prop-types
  isOpen: boolean;
  data: CreditOption;
  // eslint-disable-next-line react/no-unused-prop-types
  labelText: string;
  idx: number;
  updateAnaliseCredito: Function;
  setDialogClose: Function;
}

const DialogAnalysesComponent = (props: Props) => {
  // const open = data.isOpen;
  const {
    isOpen, data, idx, updateAnaliseCredito, setDialogClose,
  } = props;
    // labelText,
  const {
    id, parcelas, banco, bancoId, valorParcela, taxa, bancoDoc, choosed,
  } = data;
  const setAttribute = (idAtt: string, attrib: string, value: any) => {
    document.getElementById(idAtt)?.setAttribute(attrib, value);
  };
  const [bancoEdit, setBancoEdit] = useState<Banco | null>(null);
  const applyCurrencyMask = (n: string) => {
    const value : string = n.toString().split('').reverse().join('');

    let newValue = '';
    for (let x = 0; x < value.length; x++) {
      newValue += value[x];
      if (x === 1) {
        if (x < value.length - 1) { newValue += ','; }
      }

      if (x === 4 || x === 7) {
        if (x < value.length - 1) { newValue += '.'; }
      }
    }

    newValue = newValue.split('').reverse().join('');
    newValue = `R$ ${newValue}`;
    return newValue;
  };
  const history = useHistory();
  const [editField, setEditField] = useState(false);
  const [taxaEdit, setTaxaEdit] = useState<number>(taxa);
  // const [id, setID] = useState(id);
  const [parcelasEdit, setParcelasEdit] = useState(parcelas);
  // eslint-disable-next-line max-len
  const [valorParcelasEdit, setValorParcelasEdit] = useState<string>(applyCurrencyMask(valorParcela.toString()));
  const [analiseCredito, setAnaliseCredito] = useState<CreditOption>();
  useEffect(() => {
    setAttribute('valorParcela', 'value', valorParcelasEdit);
    setAttribute('taxa', 'value', taxaEdit);
    setAttribute('numeroParcela', 'value', parcelasEdit);

    // If Use-effect is changed run without the disabled eslint to check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const removeNonNumeric = (n: string) => {
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    let value = '';
    for (let i = 0; i < n.length; ++i) {
      if (mask.includes(n[i])) {
        value += n[i];
      }
    }
    // return (value.length === 0) ? '0' : value;
    return (value.length === 0) ? '0' : parseInt(value, 10).toString();
  };
  const handleParcelasChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = removeNonNumeric(e.target.value);

    // if (parseInt(value, 10) === 0) value = '1';

    setParcelasEdit(parseInt(value, 10));
    // e.target.value = applyCurrencyMask(value);
    e.target.value = value;
  };
  const handleValorParcelaChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = removeNonNumeric(e.target.value);
    setValorParcelasEdit(value);
    e.target.value = applyCurrencyMask(value);
  };
  const updateAnaliseCreditoProp = async () => {
    // eslint-disable-next-line no-restricted-globals,no-alert
    const option = confirm('Deseja confirmar as informações do crédito?.');
    if (option) {
      const analiseNew = {
        id,
        parcelas: parcelasEdit,
        banco,
        bancoId,
        valorParcela: Number(removeNonNumeric(valorParcelasEdit)),
        taxa: taxaEdit,
        bancoDoc,
        choosed,
      };
      setAnaliseCredito(analiseNew);
      updateAnaliseCredito(analiseNew, idx);
      setDialogClose();
    }
  };
  const handleTaxaChange = (e: ChangeEvent<HTMLInputElement>) => {
    const mask = '0123456789.';

    // remove unwanted characters (including recently pressed ones)
    let value = '';
    for (let i = 0; i < e.target.value.length; ++i) {
      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    setTaxaEdit(parseFloat(value));
    e.target.value = percentageValue(value);
  };
  const handleClose = () => {
    setDialogClose();
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>Editar condição de crédito</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Altere os dados financeiros da condição de crédito selecionada.
          </DialogContentText>
          <br />
          <td style={{ width: '35%' }}>
            <span style={{ fontWeight: 'bold' }}>Banco</span>
            <br />
            <span>{banco}</span>
          </td>
          <br />
          <TextField
            autoFocus
            margin="dense"
            id="numeroParcela"
            defaultValue={parcelasEdit}
            label="Número de parcelas"
            type="text"
            onChange={handleParcelasChange}
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            id="valorParcela"
            defaultValue={valorParcelasEdit}
            label="Valor da parcela"
            type="text"
            onChange={handleValorParcelaChange}
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            id="taxa"
            defaultValue={taxaEdit}
            label="Taxa %"
            onChange={handleTaxaChange}
            type="text"
            variant="standard"
            onFocus={(e) => {
              if (e.target.value === '0') e.target.value = '';
            }}
          />
          {/* <input */}
          {/*   style={{ width: '15px' }} */}
          {/*   type="text" */}
          {/*   onChange={handleTaxaChange} */}
          {/*   onFocus={(e) => { */}
          {/*     if (e.target.value === '0') e.target.value = ''; */}
          {/*   }} */}
          {/*   id="taxa" */}
          {/*   min={0} */}
          {/* /> */}
        </DialogContent>
        <DialogActions>
          <Button style={{ color: 'red' }} onClick={handleClose}>Cancelar</Button>
          <Button style={{ color: 'green' }} onClick={updateAnaliseCreditoProp}>Salvar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export { DialogAnalysesComponent };
